let amdCpuArray = [
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7950X3D",
    "name": "AMD Ryzen 9 7950X3D",
    "condition": "Used",
    "id": 3359,
    "master_id": 3348
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7950X3D",
    "name": "AMD Ryzen 9 7950X3D",
    "condition": "Used With Box",
    "id": 3360,
    "master_id": 3348
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7950X3D",
    "name": "AMD Ryzen 9 7950X3D",
    "condition": "Brand New Sealed",
    "id": 3361,
    "master_id": 3348
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7950X",
    "name": "AMD Ryzen 9 7950X",
    "condition": "Used",
    "id": 3362,
    "master_id": 3349
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7950X",
    "name": "AMD Ryzen 9 7950X",
    "condition": "Used With Box",
    "id": 3363,
    "master_id": 3349
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7950X",
    "name": "AMD Ryzen 9 7950X",
    "condition": "Brand New Sealed",
    "id": 3364,
    "master_id": 3349
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7900X3D",
    "name": "AMD Ryzen 9 7900X3D",
    "condition": "Used",
    "id": 3366,
    "master_id": 3350
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7900X3D",
    "name": "AMD Ryzen 9 7900X3D",
    "condition": "Used With Box",
    "id": 3367,
    "master_id": 3350
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7900X3D",
    "name": "AMD Ryzen 9 7900X3D",
    "condition": "Brand New Sealed",
    "id": 3365,
    "master_id": 3350
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7900X",
    "name": "AMD Ryzen 9 7900X",
    "condition": "Used",
    "id": 3368,
    "master_id": 3351
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7900X",
    "name": "AMD Ryzen 9 7900X",
    "condition": "Used With Box",
    "id": 3369,
    "master_id": 3351
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "7900X",
    "name": "AMD Ryzen 9 7900X",
    "condition": "Brand New Sealed",
    "id": 3370,
    "master_id": 3351
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": 7900,
    "name": "AMD Ryzen 9 7900",
    "condition": "Used",
    "id": 3371,
    "master_id": 3352
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": 7900,
    "name": "AMD Ryzen 9 7900",
    "condition": "Used With Box",
    "id": 3372,
    "master_id": 3352
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": 7900,
    "name": "AMD Ryzen 9 7900",
    "condition": "Brand New Sealed",
    "id": 3373,
    "master_id": 3352
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "5950X",
    "name": "AMD Ryzen 9 5950X",
    "condition": "Used",
    "id": 1180,
    "master_id": 1098
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "5950X",
    "name": "AMD Ryzen 9 5950X",
    "condition": "Used With Box",
    "id": 1181,
    "master_id": 1098
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "5950X",
    "name": "AMD Ryzen 9 5950X",
    "condition": "Brand New Sealed",
    "id": 1182,
    "master_id": 1098
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "5900X",
    "name": "AMD Ryzen 9 5900X",
    "condition": "Used",
    "id": 1183,
    "master_id": 1099
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "5900X",
    "name": "AMD Ryzen 9 5900X",
    "condition": "Used With Box",
    "id": 1184,
    "master_id": 1099
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "5900X",
    "name": "AMD Ryzen 9 5900X",
    "condition": "Brand New Sealed",
    "id": 1185,
    "master_id": 1099
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3950X",
    "name": "AMD Ryzen 9 3950X",
    "condition": "Used",
    "id": 1186,
    "master_id": 1100
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3950X",
    "name": "AMD Ryzen 9 3950X",
    "condition": "Used With Box",
    "id": 1187,
    "master_id": 1100
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3950X",
    "name": "AMD Ryzen 9 3950X",
    "condition": "Brand New Sealed",
    "id": 1188,
    "master_id": 1100
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900XT",
    "name": "AMD Ryzen 9 3900XT",
    "condition": "Used",
    "id": 1189,
    "master_id": 1101
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900XT",
    "name": "AMD Ryzen 9 3900XT",
    "condition": "Used With Box",
    "id": 1190,
    "master_id": 1101
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900XT",
    "name": "AMD Ryzen 9 3900XT",
    "condition": "Brand New Sealed",
    "id": 1191,
    "master_id": 1101
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900X",
    "name": "AMD Ryzen 9 3900X",
    "condition": "Used",
    "id": 1192,
    "master_id": 1102
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900X",
    "name": "AMD Ryzen 9 3900X",
    "condition": "Used With Box",
    "id": 1193,
    "master_id": 1102
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900X",
    "name": "AMD Ryzen 9 3900X",
    "condition": "Brand New Sealed",
    "id": 1194,
    "master_id": 1102
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900 Pro",
    "name": "AMD Ryzen 9 3900 Pro",
    "condition": "Used",
    "id": 1195,
    "master_id": 1103
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900 Pro",
    "name": "AMD Ryzen 9 3900 Pro",
    "condition": "Used With Box",
    "id": 1196,
    "master_id": 1103
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": "3900 Pro",
    "name": "AMD Ryzen 9 3900 Pro",
    "condition": "Brand New Sealed",
    "id": 1197,
    "master_id": 1103
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": 3900,
    "name": "AMD Ryzen 9 3900",
    "condition": "Used",
    "id": 1198,
    "master_id": 1104
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": 3900,
    "name": "AMD Ryzen 9 3900",
    "condition": "Used With Box",
    "id": 1199,
    "master_id": 1104
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 9",
    "model": 3900,
    "name": "AMD Ryzen 9 3900",
    "condition": "Brand New Sealed",
    "id": 1200,
    "master_id": 1104
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "8700G",
    "name": "AMD Ryzen 7 8700G",
    "condition": "Used",
    "id": 7354,
    "master_id": 7344
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "8700G",
    "name": "AMD Ryzen 7 8700G",
    "condition": "Used With Box",
    "id": 7355,
    "master_id": 7344
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "8700G",
    "name": "AMD Ryzen 7 8700G",
    "condition": "Brand New Sealed",
    "id": 7356,
    "master_id": 7344
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "7800X3D",
    "name": "AMD Ryzen 7 7800X3D",
    "condition": "Used",
    "id": 3374,
    "master_id": 3353
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "7800X3D",
    "name": "AMD Ryzen 7 7800X3D",
    "condition": "Used With Box",
    "id": 3375,
    "master_id": 3353
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "7800X3D",
    "name": "AMD Ryzen 7 7800X3D",
    "condition": "Brand New Sealed",
    "id": 3376,
    "master_id": 3353
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "7700X",
    "name": "AMD Ryzen 7 7700X",
    "condition": "Used",
    "id": 3378,
    "master_id": 3354
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "7700X",
    "name": "AMD Ryzen 7 7700X",
    "condition": "Used With Box",
    "id": 3379,
    "master_id": 3354
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "7700X",
    "name": "AMD Ryzen 7 7700X",
    "condition": "Brand New Sealed",
    "id": 3377,
    "master_id": 3354
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 7700,
    "name": "AMD Ryzen 7 7700",
    "condition": "Used",
    "id": 3382,
    "master_id": 3355
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 7700,
    "name": "AMD Ryzen 7 7700",
    "condition": "Used With Box",
    "id": 3380,
    "master_id": 3355
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 7700,
    "name": "AMD Ryzen 7 7700",
    "condition": "Brand New Sealed",
    "id": 3381,
    "master_id": 3355
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5800X",
    "name": "AMD Ryzen 7 5800X",
    "condition": "Used",
    "id": 1201,
    "master_id": 1105
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5800X",
    "name": "AMD Ryzen 7 5800X",
    "condition": "Used With Box",
    "id": 1202,
    "master_id": 1105
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5800X",
    "name": "AMD Ryzen 7 5800X",
    "condition": "Brand New Sealed",
    "id": 1203,
    "master_id": 1105
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5700G",
    "name": "AMD Ryzen 7 5700G",
    "condition": "Used",
    "id": 1204,
    "master_id": 1106
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5700G",
    "name": "AMD Ryzen 7 5700G",
    "condition": "Used With Box",
    "id": 1205,
    "master_id": 1106
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5700G",
    "name": "AMD Ryzen 7 5700G",
    "condition": "Brand New Sealed",
    "id": 1206,
    "master_id": 1106
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5700X",
    "name": "AMD Ryzen 7 5700X",
    "condition": "Used",
    "id": 1207,
    "master_id": 1107
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5700X",
    "name": "AMD Ryzen 7 5700X",
    "condition": "Used With Box",
    "id": 1208,
    "master_id": 1107
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "5700X",
    "name": "AMD Ryzen 7 5700X",
    "condition": "Brand New Sealed",
    "id": 1209,
    "master_id": 1107
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "4700G",
    "name": "AMD Ryzen 7 4700G",
    "condition": "Used",
    "id": 1210,
    "master_id": 1108
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "4700G",
    "name": "AMD Ryzen 7 4700G",
    "condition": "Used With Box",
    "id": 1211,
    "master_id": 1108
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "4700G",
    "name": "AMD Ryzen 7 4700G",
    "condition": "Brand New Sealed",
    "id": 1212,
    "master_id": 1108
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3800XT",
    "name": "AMD Ryzen 7 3800XT",
    "condition": "Used",
    "id": 1213,
    "master_id": 1109
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3800XT",
    "name": "AMD Ryzen 7 3800XT",
    "condition": "Used With Box",
    "id": 1214,
    "master_id": 1109
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3800XT",
    "name": "AMD Ryzen 7 3800XT",
    "condition": "Brand New Sealed",
    "id": 1215,
    "master_id": 1109
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3800X",
    "name": "AMD Ryzen 7 3800X",
    "condition": "Used",
    "id": 1216,
    "master_id": 1110
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3800X",
    "name": "AMD Ryzen 7 3800X",
    "condition": "Used With Box",
    "id": 1217,
    "master_id": 1110
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3800X",
    "name": "AMD Ryzen 7 3800X",
    "condition": "Brand New Sealed",
    "id": 1218,
    "master_id": 1110
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3700X",
    "name": "AMD Ryzen 7 3700X",
    "condition": "Used",
    "id": 1219,
    "master_id": 1111
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3700X",
    "name": "AMD Ryzen 7 3700X",
    "condition": "Used With Box",
    "id": 1220,
    "master_id": 1111
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3700X",
    "name": "AMD Ryzen 7 3700X",
    "condition": "Brand New Sealed",
    "id": 1221,
    "master_id": 1111
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3700 Pro",
    "name": "AMD Ryzen 7 3700 Pro",
    "condition": "Used",
    "id": 1222,
    "master_id": 1112
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3700 Pro",
    "name": "AMD Ryzen 7 3700 Pro",
    "condition": "Used With Box",
    "id": 1223,
    "master_id": 1112
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "3700 Pro",
    "name": "AMD Ryzen 7 3700 Pro",
    "condition": "Brand New Sealed",
    "id": 1224,
    "master_id": 1112
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700X Pro",
    "name": "AMD Ryzen 7 2700X Pro",
    "condition": "Used",
    "id": 1225,
    "master_id": 1113
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700X Pro",
    "name": "AMD Ryzen 7 2700X Pro",
    "condition": "Used With Box",
    "id": 1226,
    "master_id": 1113
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700X Pro",
    "name": "AMD Ryzen 7 2700X Pro",
    "condition": "Brand New Sealed",
    "id": 1227,
    "master_id": 1113
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700X",
    "name": "AMD Ryzen 7 2700X",
    "condition": "Used",
    "id": 1228,
    "master_id": 1114
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700X",
    "name": "AMD Ryzen 7 2700X",
    "condition": "Used With Box",
    "id": 1229,
    "master_id": 1114
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700X",
    "name": "AMD Ryzen 7 2700X",
    "condition": "Brand New Sealed",
    "id": 1230,
    "master_id": 1114
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700E",
    "name": "AMD Ryzen 7 2700E",
    "condition": "Used",
    "id": 1231,
    "master_id": 1115
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700E",
    "name": "AMD Ryzen 7 2700E",
    "condition": "Used With Box",
    "id": 1232,
    "master_id": 1115
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700E",
    "name": "AMD Ryzen 7 2700E",
    "condition": "Brand New Sealed",
    "id": 1233,
    "master_id": 1115
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700 Pro",
    "name": "AMD Ryzen 7 2700 Pro",
    "condition": "Used",
    "id": 1234,
    "master_id": 1116
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700 Pro",
    "name": "AMD Ryzen 7 2700 Pro",
    "condition": "Used With Box",
    "id": 1235,
    "master_id": 1116
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "2700 Pro",
    "name": "AMD Ryzen 7 2700 Pro",
    "condition": "Brand New Sealed",
    "id": 1236,
    "master_id": 1116
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 2700,
    "name": "AMD Ryzen 7 2700",
    "condition": "Used",
    "id": 1237,
    "master_id": 1117
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 2700,
    "name": "AMD Ryzen 7 2700",
    "condition": "Used With Box",
    "id": 1238,
    "master_id": 1117
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 2700,
    "name": "AMD Ryzen 7 2700",
    "condition": "Brand New Sealed",
    "id": 1239,
    "master_id": 1117
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1800X",
    "name": "AMD Ryzen 7 1800X",
    "condition": "Used",
    "id": 1240,
    "master_id": 1118
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1800X",
    "name": "AMD Ryzen 7 1800X",
    "condition": "Used With Box",
    "id": 1241,
    "master_id": 1118
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1800X",
    "name": "AMD Ryzen 7 1800X",
    "condition": "Brand New Sealed",
    "id": 1242,
    "master_id": 1118
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1700X",
    "name": "AMD Ryzen 7 1700X",
    "condition": "Used",
    "id": 1243,
    "master_id": 1119
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1700X",
    "name": "AMD Ryzen 7 1700X",
    "condition": "Used With Box",
    "id": 1244,
    "master_id": 1119
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1700X",
    "name": "AMD Ryzen 7 1700X",
    "condition": "Brand New Sealed",
    "id": 1245,
    "master_id": 1119
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1700 Pro",
    "name": "AMD Ryzen 7 1700 Pro",
    "condition": "Used",
    "id": 1246,
    "master_id": 1120
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1700 Pro",
    "name": "AMD Ryzen 7 1700 Pro",
    "condition": "Used With Box",
    "id": 1247,
    "master_id": 1120
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": "1700 Pro",
    "name": "AMD Ryzen 7 1700 Pro",
    "condition": "Brand New Sealed",
    "id": 1248,
    "master_id": 1120
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 1700,
    "name": "AMD Ryzen 7 1700",
    "condition": "Used",
    "id": 1249,
    "master_id": 1121
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 1700,
    "name": "AMD Ryzen 7 1700",
    "condition": "Used With Box",
    "id": 1250,
    "master_id": 1121
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 7",
    "model": 1700,
    "name": "AMD Ryzen 7 1700",
    "condition": "Brand New Sealed",
    "id": 1251,
    "master_id": 1121
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "8600G",
    "name": "AMD Ryzen 5 8600G",
    "condition": "Used",
    "id": 7351,
    "master_id": 7343
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "8600G",
    "name": "AMD Ryzen 5 8600G",
    "condition": "Used With Box",
    "id": 7352,
    "master_id": 7343
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "8600G",
    "name": "AMD Ryzen 5 8600G",
    "condition": "Brand New Sealed",
    "id": 7353,
    "master_id": 7343
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "8500G",
    "name": "AMD Ryzen 5 8500G",
    "condition": "Used",
    "id": 7348,
    "master_id": 7342
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "8500G",
    "name": "AMD Ryzen 5 8500G",
    "condition": "Used With Box",
    "id": 7349,
    "master_id": 7342
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "8500G",
    "name": "AMD Ryzen 5 8500G",
    "condition": "Brand New Sealed",
    "id": 7350,
    "master_id": 7342
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "7600X",
    "name": "AMD Ryzen 5 7600X",
    "condition": "Used",
    "id": 3383,
    "master_id": 3356
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "7600X",
    "name": "AMD Ryzen 5 7600X",
    "condition": "Used With Box",
    "id": 3384,
    "master_id": 3356
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "7600X",
    "name": "AMD Ryzen 5 7600X",
    "condition": "Brand New Sealed",
    "id": 3385,
    "master_id": 3356
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 7600,
    "name": "AMD Ryzen 5 7600",
    "condition": "Used",
    "id": 3386,
    "master_id": 3357
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 7600,
    "name": "AMD Ryzen 5 7600",
    "condition": "Used With Box",
    "id": 3387,
    "master_id": 3357
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 7600,
    "name": "AMD Ryzen 5 7600",
    "condition": "Brand New Sealed",
    "id": 3388,
    "master_id": 3357
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 5600,
    "name": "AMD Ryzen 5 5600",
    "condition": "Used",
    "id": 3389,
    "master_id": 3358
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 5600,
    "name": "AMD Ryzen 5 5600",
    "condition": "Used With Box",
    "id": 3390,
    "master_id": 3358
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 5600,
    "name": "AMD Ryzen 5 5600",
    "condition": "Brand New Sealed",
    "id": 3391,
    "master_id": 3358
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600G",
    "name": "AMD Ryzen 5 5600G",
    "condition": "Used",
    "id": 1252,
    "master_id": 1122
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600G",
    "name": "AMD Ryzen 5 5600G",
    "condition": "Used With Box",
    "id": 1253,
    "master_id": 1122
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600G",
    "name": "AMD Ryzen 5 5600G",
    "condition": "Brand New Sealed",
    "id": 1254,
    "master_id": 1122
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600X",
    "name": "AMD Ryzen 5 5600X",
    "condition": "Used",
    "id": 1255,
    "master_id": 1123
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600X",
    "name": "AMD Ryzen 5 5600X",
    "condition": "Used With Box",
    "id": 1256,
    "master_id": 1123
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600X",
    "name": "AMD Ryzen 5 5600X",
    "condition": "Brand New Sealed",
    "id": 1257,
    "master_id": 1123
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600X3D",
    "name": "AMD Ryzen 5 5600X3D",
    "condition": "Used",
    "id": 3655,
    "master_id": 3654
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600X3D",
    "name": "AMD Ryzen 5 5600X3D",
    "condition": "Used With Box",
    "id": 3656,
    "master_id": 3654
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "5600X3D",
    "name": "AMD Ryzen 5 5600X3D",
    "condition": "Brand New Sealed",
    "id": 3657,
    "master_id": 3654
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "4600G",
    "name": "AMD Ryzen 5 4600G",
    "condition": "Used",
    "id": 1258,
    "master_id": 1124
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "4600G",
    "name": "AMD Ryzen 5 4600G",
    "condition": "Used With Box",
    "id": 1259,
    "master_id": 1124
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "4600G",
    "name": "AMD Ryzen 5 4600G",
    "condition": "Brand New Sealed",
    "id": 1260,
    "master_id": 1124
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600XT",
    "name": "AMD Ryzen 5 3600XT",
    "condition": "Used",
    "id": 1261,
    "master_id": 1125
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600XT",
    "name": "AMD Ryzen 5 3600XT",
    "condition": "Used With Box",
    "id": 1262,
    "master_id": 1125
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600XT",
    "name": "AMD Ryzen 5 3600XT",
    "condition": "Brand New Sealed",
    "id": 1263,
    "master_id": 1125
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600X",
    "name": "AMD Ryzen 5 3600X",
    "condition": "Used",
    "id": 1264,
    "master_id": 1126
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600X",
    "name": "AMD Ryzen 5 3600X",
    "condition": "Used With Box",
    "id": 1265,
    "master_id": 1126
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600X",
    "name": "AMD Ryzen 5 3600X",
    "condition": "Brand New Sealed",
    "id": 1266,
    "master_id": 1126
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600 Pro",
    "name": "AMD Ryzen 5 3600 Pro",
    "condition": "Used",
    "id": 1267,
    "master_id": 1127
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600 Pro",
    "name": "AMD Ryzen 5 3600 Pro",
    "condition": "Used With Box",
    "id": 1268,
    "master_id": 1127
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3600 Pro",
    "name": "AMD Ryzen 5 3600 Pro",
    "condition": "Brand New Sealed",
    "id": 1269,
    "master_id": 1127
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 3600,
    "name": "AMD Ryzen 5 3600",
    "condition": "Used",
    "id": 1270,
    "master_id": 1128
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 3600,
    "name": "AMD Ryzen 5 3600",
    "condition": "Used With Box",
    "id": 1271,
    "master_id": 1128
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 3600,
    "name": "AMD Ryzen 5 3600",
    "condition": "Brand New Sealed",
    "id": 1272,
    "master_id": 1128
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3500X",
    "name": "AMD Ryzen 5 3500X",
    "condition": "Used",
    "id": 1273,
    "master_id": 1129
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3500X",
    "name": "AMD Ryzen 5 3500X",
    "condition": "Used With Box",
    "id": 1274,
    "master_id": 1129
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3500X",
    "name": "AMD Ryzen 5 3500X",
    "condition": "Brand New Sealed",
    "id": 1275,
    "master_id": 1129
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 3500,
    "name": "AMD Ryzen 5 3500",
    "condition": "Used",
    "id": 1276,
    "master_id": 1130
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 3500,
    "name": "AMD Ryzen 5 3500",
    "condition": "Used With Box",
    "id": 1277,
    "master_id": 1130
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 3500,
    "name": "AMD Ryzen 5 3500",
    "condition": "Brand New Sealed",
    "id": 1278,
    "master_id": 1130
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400GE",
    "name": "AMD Ryzen 5 3400GE",
    "condition": "Used",
    "id": 1279,
    "master_id": 1131
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400GE",
    "name": "AMD Ryzen 5 3400GE",
    "condition": "Used With Box",
    "id": 1280,
    "master_id": 1131
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400GE",
    "name": "AMD Ryzen 5 3400GE",
    "condition": "Brand New Sealed",
    "id": 1281,
    "master_id": 1131
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400G Pro",
    "name": "AMD Ryzen 5 3400G Pro",
    "condition": "Used",
    "id": 1282,
    "master_id": 1132
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400G Pro",
    "name": "AMD Ryzen 5 3400G Pro",
    "condition": "Used With Box",
    "id": 1283,
    "master_id": 1132
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400G Pro",
    "name": "AMD Ryzen 5 3400G Pro",
    "condition": "Brand New Sealed",
    "id": 1284,
    "master_id": 1132
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400G",
    "name": "AMD Ryzen 5 3400G",
    "condition": "Used",
    "id": 1285,
    "master_id": 1133
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400G",
    "name": "AMD Ryzen 5 3400G",
    "condition": "Used With Box",
    "id": 1286,
    "master_id": 1133
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "3400G",
    "name": "AMD Ryzen 5 3400G",
    "condition": "Brand New Sealed",
    "id": 1287,
    "master_id": 1133
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2600X",
    "name": "AMD Ryzen 5 2600X",
    "condition": "Used",
    "id": 1288,
    "master_id": 1134
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2600X",
    "name": "AMD Ryzen 5 2600X",
    "condition": "Used With Box",
    "id": 1289,
    "master_id": 1134
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2600X",
    "name": "AMD Ryzen 5 2600X",
    "condition": "Brand New Sealed",
    "id": 1290,
    "master_id": 1134
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2600E",
    "name": "AMD Ryzen 5 2600E",
    "condition": "Used",
    "id": 1291,
    "master_id": 1135
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2600E",
    "name": "AMD Ryzen 5 2600E",
    "condition": "Used With Box",
    "id": 1292,
    "master_id": 1135
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2600E",
    "name": "AMD Ryzen 5 2600E",
    "condition": "Brand New Sealed",
    "id": 1293,
    "master_id": 1135
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 2600,
    "name": "AMD Ryzen 5 2600",
    "condition": "Used",
    "id": 1294,
    "master_id": 1136
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 2600,
    "name": "AMD Ryzen 5 2600",
    "condition": "Used With Box",
    "id": 1295,
    "master_id": 1136
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 2600,
    "name": "AMD Ryzen 5 2600",
    "condition": "Brand New Sealed",
    "id": 1296,
    "master_id": 1136
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2500X",
    "name": "AMD Ryzen 5 2500X",
    "condition": "Used",
    "id": 1297,
    "master_id": 1137
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2500X",
    "name": "AMD Ryzen 5 2500X",
    "condition": "Used With Box",
    "id": 1298,
    "master_id": 1137
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2500X",
    "name": "AMD Ryzen 5 2500X",
    "condition": "Brand New Sealed",
    "id": 1299,
    "master_id": 1137
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400GE Pro",
    "name": "AMD Ryzen 5 2400GE Pro",
    "condition": "Used",
    "id": 1300,
    "master_id": 1138
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400GE Pro",
    "name": "AMD Ryzen 5 2400GE Pro",
    "condition": "Used With Box",
    "id": 1301,
    "master_id": 1138
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400GE Pro",
    "name": "AMD Ryzen 5 2400GE Pro",
    "condition": "Brand New Sealed",
    "id": 1302,
    "master_id": 1138
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400GE",
    "name": "AMD Ryzen 5 2400GE",
    "condition": "Used",
    "id": 1303,
    "master_id": 1139
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400GE",
    "name": "AMD Ryzen 5 2400GE",
    "condition": "Used With Box",
    "id": 1304,
    "master_id": 1139
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400GE",
    "name": "AMD Ryzen 5 2400GE",
    "condition": "Brand New Sealed",
    "id": 1305,
    "master_id": 1139
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400G Pro",
    "name": "AMD Ryzen 5 2400G Pro",
    "condition": "Used",
    "id": 1306,
    "master_id": 1140
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400G Pro",
    "name": "AMD Ryzen 5 2400G Pro",
    "condition": "Used With Box",
    "id": 1307,
    "master_id": 1140
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400G Pro",
    "name": "AMD Ryzen 5 2400G Pro",
    "condition": "Brand New Sealed",
    "id": 1308,
    "master_id": 1140
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400G",
    "name": "AMD Ryzen 5 2400G",
    "condition": "Used",
    "id": 1309,
    "master_id": 1141
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400G",
    "name": "AMD Ryzen 5 2400G",
    "condition": "Used With Box",
    "id": 1310,
    "master_id": 1141
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "2400G",
    "name": "AMD Ryzen 5 2400G",
    "condition": "Brand New Sealed",
    "id": 1311,
    "master_id": 1141
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600X",
    "name": "AMD Ryzen 5 1600X",
    "condition": "Used",
    "id": 1312,
    "master_id": 1142
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600X",
    "name": "AMD Ryzen 5 1600X",
    "condition": "Used With Box",
    "id": 1313,
    "master_id": 1142
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600X",
    "name": "AMD Ryzen 5 1600X",
    "condition": "Brand New Sealed",
    "id": 1314,
    "master_id": 1142
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600AF",
    "name": "AMD Ryzen 5 1600AF",
    "condition": "Used",
    "id": 1315,
    "master_id": 1143
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600AF",
    "name": "AMD Ryzen 5 1600AF",
    "condition": "Used With Box",
    "id": 1316,
    "master_id": 1143
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600AF",
    "name": "AMD Ryzen 5 1600AF",
    "condition": "Brand New Sealed",
    "id": 1317,
    "master_id": 1143
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600 Pro",
    "name": "AMD Ryzen 5 1600 Pro",
    "condition": "Used",
    "id": 1318,
    "master_id": 1144
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600 Pro",
    "name": "AMD Ryzen 5 1600 Pro",
    "condition": "Used With Box",
    "id": 1319,
    "master_id": 1144
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1600 Pro",
    "name": "AMD Ryzen 5 1600 Pro",
    "condition": "Brand New Sealed",
    "id": 1320,
    "master_id": 1144
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 1600,
    "name": "AMD Ryzen 5 1600",
    "condition": "Used",
    "id": 1321,
    "master_id": 1145
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 1600,
    "name": "AMD Ryzen 5 1600",
    "condition": "Used With Box",
    "id": 1322,
    "master_id": 1145
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 1600,
    "name": "AMD Ryzen 5 1600",
    "condition": "Brand New Sealed",
    "id": 1323,
    "master_id": 1145
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1500X",
    "name": "AMD Ryzen 5 1500X",
    "condition": "Used",
    "id": 1324,
    "master_id": 1146
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1500X",
    "name": "AMD Ryzen 5 1500X",
    "condition": "Used With Box",
    "id": 1325,
    "master_id": 1146
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1500X",
    "name": "AMD Ryzen 5 1500X",
    "condition": "Brand New Sealed",
    "id": 1326,
    "master_id": 1146
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1500 Pro",
    "name": "AMD Ryzen 5 1500 Pro",
    "condition": "Used",
    "id": 1327,
    "master_id": 1147
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1500 Pro",
    "name": "AMD Ryzen 5 1500 Pro",
    "condition": "Used With Box",
    "id": 1328,
    "master_id": 1147
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": "1500 Pro",
    "name": "AMD Ryzen 5 1500 Pro",
    "condition": "Brand New Sealed",
    "id": 1329,
    "master_id": 1147
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 1400,
    "name": "AMD Ryzen 5 1400",
    "condition": "Used",
    "id": 1330,
    "master_id": 1148
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 1400,
    "name": "AMD Ryzen 5 1400",
    "condition": "Used With Box",
    "id": 1331,
    "master_id": 1148
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 5",
    "model": 1400,
    "name": "AMD Ryzen 5 1400",
    "condition": "Brand New Sealed",
    "id": 1332,
    "master_id": 1148
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "8300G",
    "name": "AMD Ryzen 3 8300G",
    "condition": "Used",
    "id": 7345,
    "master_id": 7341
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "8300G",
    "name": "AMD Ryzen 3 8300G",
    "condition": "Used With Box",
    "id": 7346,
    "master_id": 7341
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "8300G",
    "name": "AMD Ryzen 3 8300G",
    "condition": "Brand New Sealed",
    "id": 7347,
    "master_id": 7341
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3300X",
    "name": "AMD Ryzen 3 3300X",
    "condition": "Used",
    "id": 1333,
    "master_id": 1149
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3300X",
    "name": "AMD Ryzen 3 3300X",
    "condition": "Used With Box",
    "id": 1334,
    "master_id": 1149
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3300X",
    "name": "AMD Ryzen 3 3300X",
    "condition": "Brand New Sealed",
    "id": 1335,
    "master_id": 1149
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200GE Pro",
    "name": "AMD Ryzen 3 3200GE Pro",
    "condition": "Used",
    "id": 1336,
    "master_id": 1150
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200GE Pro",
    "name": "AMD Ryzen 3 3200GE Pro",
    "condition": "Used With Box",
    "id": 1337,
    "master_id": 1150
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200GE Pro",
    "name": "AMD Ryzen 3 3200GE Pro",
    "condition": "Brand New Sealed",
    "id": 1338,
    "master_id": 1150
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200GE",
    "name": "AMD Ryzen 3 3200GE",
    "condition": "Used",
    "id": 1339,
    "master_id": 1151
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200GE",
    "name": "AMD Ryzen 3 3200GE",
    "condition": "Used With Box",
    "id": 1340,
    "master_id": 1151
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200GE",
    "name": "AMD Ryzen 3 3200GE",
    "condition": "Brand New Sealed",
    "id": 1341,
    "master_id": 1151
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200G Pro",
    "name": "AMD Ryzen 3 3200G Pro",
    "condition": "Used",
    "id": 1342,
    "master_id": 1152
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200G Pro",
    "name": "AMD Ryzen 3 3200G Pro",
    "condition": "Used With Box",
    "id": 1343,
    "master_id": 1152
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200G Pro",
    "name": "AMD Ryzen 3 3200G Pro",
    "condition": "Brand New Sealed",
    "id": 1344,
    "master_id": 1152
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200G",
    "name": "AMD Ryzen 3 3200G",
    "condition": "Used",
    "id": 1345,
    "master_id": 1153
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200G",
    "name": "AMD Ryzen 3 3200G",
    "condition": "Used With Box",
    "id": 1346,
    "master_id": 1153
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "3200G",
    "name": "AMD Ryzen 3 3200G",
    "condition": "Brand New Sealed",
    "id": 1347,
    "master_id": 1153
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": 3100,
    "name": "AMD Ryzen 3 3100",
    "condition": "Used",
    "id": 1348,
    "master_id": 1154
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": 3100,
    "name": "AMD Ryzen 3 3100",
    "condition": "Used With Box",
    "id": 1349,
    "master_id": 1154
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": 3100,
    "name": "AMD Ryzen 3 3100",
    "condition": "Brand New Sealed",
    "id": 1350,
    "master_id": 1154
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2300X",
    "name": "AMD Ryzen 3 2300X",
    "condition": "Used",
    "id": 1351,
    "master_id": 1155
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2300X",
    "name": "AMD Ryzen 3 2300X",
    "condition": "Used With Box",
    "id": 1352,
    "master_id": 1155
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2300X",
    "name": "AMD Ryzen 3 2300X",
    "condition": "Brand New Sealed",
    "id": 1353,
    "master_id": 1155
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200GE Pro",
    "name": "AMD Ryzen 3 2200GE Pro",
    "condition": "Used",
    "id": 1354,
    "master_id": 1156
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200GE Pro",
    "name": "AMD Ryzen 3 2200GE Pro",
    "condition": "Used With Box",
    "id": 1355,
    "master_id": 1156
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200GE Pro",
    "name": "AMD Ryzen 3 2200GE Pro",
    "condition": "Brand New Sealed",
    "id": 1356,
    "master_id": 1156
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200GE",
    "name": "AMD Ryzen 3 2200GE",
    "condition": "Used",
    "id": 1357,
    "master_id": 1157
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200GE",
    "name": "AMD Ryzen 3 2200GE",
    "condition": "Used With Box",
    "id": 1358,
    "master_id": 1157
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200GE",
    "name": "AMD Ryzen 3 2200GE",
    "condition": "Brand New Sealed",
    "id": 1359,
    "master_id": 1157
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200G Pro",
    "name": "AMD Ryzen 3 2200G Pro",
    "condition": "Used",
    "id": 1360,
    "master_id": 1158
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200G Pro",
    "name": "AMD Ryzen 3 2200G Pro",
    "condition": "Used With Box",
    "id": 1361,
    "master_id": 1158
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200G Pro",
    "name": "AMD Ryzen 3 2200G Pro",
    "condition": "Brand New Sealed",
    "id": 1362,
    "master_id": 1158
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200G",
    "name": "AMD Ryzen 3 2200G",
    "condition": "Used",
    "id": 1363,
    "master_id": 1159
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200G",
    "name": "AMD Ryzen 3 2200G",
    "condition": "Used With Box",
    "id": 1364,
    "master_id": 1159
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "2200G",
    "name": "AMD Ryzen 3 2200G",
    "condition": "Brand New Sealed",
    "id": 1365,
    "master_id": 1159
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "1300X",
    "name": "AMD Ryzen 3 1300X",
    "condition": "Used",
    "id": 1366,
    "master_id": 1160
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "1300X",
    "name": "AMD Ryzen 3 1300X",
    "condition": "Used With Box",
    "id": 1367,
    "master_id": 1160
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "Ryzen 3",
    "model": "1300X",
    "name": "AMD Ryzen 3 1300X",
    "condition": "Brand New Sealed",
    "id": 1368,
    "master_id": 1160
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5995WX",
    "name": "AMD Threadripper Pro 5995WX",
    "condition": "Used",
    "id": 1369,
    "master_id": 1161
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5995WX",
    "name": "AMD Threadripper Pro 5995WX",
    "condition": "Used With Box",
    "id": 1370,
    "master_id": 1161
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5995WX",
    "name": "AMD Threadripper Pro 5995WX",
    "condition": "Brand New Sealed",
    "id": 1371,
    "master_id": 1161
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5975WX",
    "name": "AMD Threadripper Pro 5975WX",
    "condition": "Used",
    "id": 1372,
    "master_id": 1162
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5975WX",
    "name": "AMD Threadripper Pro 5975WX",
    "condition": "Used With Box",
    "id": 1373,
    "master_id": 1162
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5975WX",
    "name": "AMD Threadripper Pro 5975WX",
    "condition": "Brand New Sealed",
    "id": 1374,
    "master_id": 1162
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5965WX",
    "name": "AMD Threadripper Pro 5965WX",
    "condition": "Used",
    "id": 1375,
    "master_id": 1163
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5965WX",
    "name": "AMD Threadripper Pro 5965WX",
    "condition": "Used With Box",
    "id": 1376,
    "master_id": 1163
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5965WX",
    "name": "AMD Threadripper Pro 5965WX",
    "condition": "Brand New Sealed",
    "id": 1377,
    "master_id": 1163
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5955WX",
    "name": "AMD Threadripper Pro 5955WX",
    "condition": "Used",
    "id": 1380,
    "master_id": 1164
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5955WX",
    "name": "AMD Threadripper Pro 5955WX",
    "condition": "Used With Box",
    "id": 1378,
    "master_id": 1164
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5955WX",
    "name": "AMD Threadripper Pro 5955WX",
    "condition": "Brand New Sealed",
    "id": 1379,
    "master_id": 1164
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5945WX",
    "name": "AMD Threadripper Pro 5945WX",
    "condition": "Used",
    "id": 1381,
    "master_id": 1165
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5945WX",
    "name": "AMD Threadripper Pro 5945WX",
    "condition": "Used With Box",
    "id": 1382,
    "master_id": 1165
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 5945WX",
    "name": "AMD Threadripper Pro 5945WX",
    "condition": "Brand New Sealed",
    "id": 1383,
    "master_id": 1165
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3995WX",
    "name": "AMD Threadripper Pro 3995WX",
    "condition": "Used",
    "id": 1384,
    "master_id": 1166
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3995WX",
    "name": "AMD Threadripper Pro 3995WX",
    "condition": "Used With Box",
    "id": 1385,
    "master_id": 1166
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3995WX",
    "name": "AMD Threadripper Pro 3995WX",
    "condition": "Brand New Sealed",
    "id": 1386,
    "master_id": 1166
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3975WX",
    "name": "AMD Threadripper Pro 3975WX",
    "condition": "Used",
    "id": 1388,
    "master_id": 1167
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3975WX",
    "name": "AMD Threadripper Pro 3975WX",
    "condition": "Used With Box",
    "id": 1389,
    "master_id": 1167
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3975WX",
    "name": "AMD Threadripper Pro 3975WX",
    "condition": "Brand New Sealed",
    "id": 1387,
    "master_id": 1167
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3955WX",
    "name": "AMD Threadripper Pro 3955WX",
    "condition": "Used",
    "id": 1390,
    "master_id": 1168
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3955WX",
    "name": "AMD Threadripper Pro 3955WX",
    "condition": "Used With Box",
    "id": 1391,
    "master_id": 1168
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3955WX",
    "name": "AMD Threadripper Pro 3955WX",
    "condition": "Brand New Sealed",
    "id": 1392,
    "master_id": 1168
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3945WX",
    "name": "AMD Threadripper Pro 3945WX",
    "condition": "Used",
    "id": 1393,
    "master_id": 1169
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3945WX",
    "name": "AMD Threadripper Pro 3945WX",
    "condition": "Used With Box",
    "id": 1394,
    "master_id": 1169
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "Pro 3945WX",
    "name": "AMD Threadripper Pro 3945WX",
    "condition": "Brand New Sealed",
    "id": 1395,
    "master_id": 1169
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3990X",
    "name": "AMD Threadripper 3990X",
    "condition": "Used",
    "id": 1398,
    "master_id": 1170
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3990X",
    "name": "AMD Threadripper 3990X",
    "condition": "Used With Box",
    "id": 1397,
    "master_id": 1170
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3990X",
    "name": "AMD Threadripper 3990X",
    "condition": "Brand New Sealed",
    "id": 1396,
    "master_id": 1170
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3970X",
    "name": "AMD Threadripper 3970X",
    "condition": "Used",
    "id": 1399,
    "master_id": 1171
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3970X",
    "name": "AMD Threadripper 3970X",
    "condition": "Used With Box",
    "id": 1400,
    "master_id": 1171
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3970X",
    "name": "AMD Threadripper 3970X",
    "condition": "Brand New Sealed",
    "id": 1401,
    "master_id": 1171
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3960X",
    "name": "AMD Threadripper 3960X",
    "condition": "Used",
    "id": 1402,
    "master_id": 1172
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3960X",
    "name": "AMD Threadripper 3960X",
    "condition": "Used With Box",
    "id": 1403,
    "master_id": 1172
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "3960X",
    "name": "AMD Threadripper 3960X",
    "condition": "Brand New Sealed",
    "id": 1404,
    "master_id": 1172
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2990WX",
    "name": "AMD Threadripper 2990WX",
    "condition": "Used",
    "id": 1405,
    "master_id": 1173
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2990WX",
    "name": "AMD Threadripper 2990WX",
    "condition": "Used With Box",
    "id": 1406,
    "master_id": 1173
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2990WX",
    "name": "AMD Threadripper 2990WX",
    "condition": "Brand New Sealed",
    "id": 1407,
    "master_id": 1173
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2970WX",
    "name": "AMD Threadripper 2970WX",
    "condition": "Used",
    "id": 1408,
    "master_id": 1174
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2970WX",
    "name": "AMD Threadripper 2970WX",
    "condition": "Used With Box",
    "id": 1409,
    "master_id": 1174
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2970WX",
    "name": "AMD Threadripper 2970WX",
    "condition": "Brand New Sealed",
    "id": 1410,
    "master_id": 1174
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2950X",
    "name": "AMD Threadripper 2950X",
    "condition": "Used",
    "id": 1413,
    "master_id": 1175
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2950X",
    "name": "AMD Threadripper 2950X",
    "condition": "Used With Box",
    "id": 1411,
    "master_id": 1175
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2950X",
    "name": "AMD Threadripper 2950X",
    "condition": "Brand New Sealed",
    "id": 1412,
    "master_id": 1175
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2920X",
    "name": "AMD Threadripper 2920X",
    "condition": "Used",
    "id": 1414,
    "master_id": 1176
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2920X",
    "name": "AMD Threadripper 2920X",
    "condition": "Used With Box",
    "id": 1415,
    "master_id": 1176
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "2920X",
    "name": "AMD Threadripper 2920X",
    "condition": "Brand New Sealed",
    "id": 1416,
    "master_id": 1176
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1950X",
    "name": "AMD Threadripper 1950X",
    "condition": "Used",
    "id": 1417,
    "master_id": 1177
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1950X",
    "name": "AMD Threadripper 1950X",
    "condition": "Used With Box",
    "id": 1418,
    "master_id": 1177
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1950X",
    "name": "AMD Threadripper 1950X",
    "condition": "Brand New Sealed",
    "id": 1419,
    "master_id": 1177
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1920X",
    "name": "AMD Threadripper 1920X",
    "condition": "Used",
    "id": 1421,
    "master_id": 1178
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1920X",
    "name": "AMD Threadripper 1920X",
    "condition": "Used With Box",
    "id": 1422,
    "master_id": 1178
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1920X",
    "name": "AMD Threadripper 1920X",
    "condition": "Brand New Sealed",
    "id": 1420,
    "master_id": 1178
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1900X",
    "name": "AMD Threadripper 1900X",
    "condition": "Used",
    "id": 1423,
    "master_id": 1179
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1900X",
    "name": "AMD Threadripper 1900X",
    "condition": "Used With Box",
    "id": 1424,
    "master_id": 1179
  },
  {
    "type": "CPU",
    "brand": "AMD",
    "series": "threadripper",
    "model": "1900X",
    "name": "AMD Threadripper 1900X",
    "condition": "Brand New Sealed",
    "id": 1425,
    "master_id": 1179
  }
];

let intelCPUArray = [
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3900",
    "name": "Intel Celeron G3900",
    "condition": "Used",
    "id": 2053,
    "master_id": 1821,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3900",
    "name": "Intel Celeron G3900",
    "condition": "Used With Box",
    "id": 2054,
    "master_id": 1821,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3900",
    "name": "Intel Celeron G3900",
    "condition": "Brand New Sealed",
    "id": 2055,
    "master_id": 1821,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3900T",
    "name": "Intel Celeron G3900T",
    "condition": "Used",
    "id": 2056,
    "master_id": 1822,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3900T",
    "name": "Intel Celeron G3900T",
    "condition": "Used With Box",
    "id": 2057,
    "master_id": 1822,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3900T",
    "name": "Intel Celeron G3900T",
    "condition": "Brand New Sealed",
    "id": 2058,
    "master_id": 1822,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3920",
    "name": "Intel Celeron G3920",
    "condition": "Used",
    "id": 2059,
    "master_id": 1823,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3920",
    "name": "Intel Celeron G3920",
    "condition": "Used With Box",
    "id": 2060,
    "master_id": 1823,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3920",
    "name": "Intel Celeron G3920",
    "condition": "Brand New Sealed",
    "id": 2061,
    "master_id": 1823,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3930",
    "name": "Intel Celeron G3930",
    "condition": "Used",
    "id": 2062,
    "master_id": 1824,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3930",
    "name": "Intel Celeron G3930",
    "condition": "Used With Box",
    "id": 2063,
    "master_id": 1824,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3930",
    "name": "Intel Celeron G3930",
    "condition": "Brand New Sealed",
    "id": 2064,
    "master_id": 1824,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3930T",
    "name": "Intel Celeron G3930T",
    "condition": "Used",
    "id": 2065,
    "master_id": 1825,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3930T",
    "name": "Intel Celeron G3930T",
    "condition": "Used With Box",
    "id": 2066,
    "master_id": 1825,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3930T",
    "name": "Intel Celeron G3930T",
    "condition": "Brand New Sealed",
    "id": 2067,
    "master_id": 1825,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3950",
    "name": "Intel Celeron G3950",
    "condition": "Used",
    "id": 2068,
    "master_id": 1826,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3950",
    "name": "Intel Celeron G3950",
    "condition": "Used With Box",
    "id": 2069,
    "master_id": 1826,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Celeron",
    "model": "G3950",
    "name": "Intel Celeron G3950",
    "condition": "Brand New Sealed",
    "id": 2070,
    "master_id": 1826,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4400",
    "name": "Intel Pentium G4400",
    "condition": "Used",
    "id": 2071,
    "master_id": 1827,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4400",
    "name": "Intel Pentium G4400",
    "condition": "Used With Box",
    "id": 2072,
    "master_id": 1827,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4400",
    "name": "Intel Pentium G4400",
    "condition": "Brand New Sealed",
    "id": 2073,
    "master_id": 1827,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4400T",
    "name": "Intel Pentium G4400T",
    "condition": "Used",
    "id": 2074,
    "master_id": 1828,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4400T",
    "name": "Intel Pentium G4400T",
    "condition": "Used With Box",
    "id": 2075,
    "master_id": 1828,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4400T",
    "name": "Intel Pentium G4400T",
    "condition": "Brand New Sealed",
    "id": 2076,
    "master_id": 1828,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4500",
    "name": "Intel Pentium G4500",
    "condition": "Used",
    "id": 2077,
    "master_id": 1829,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4500",
    "name": "Intel Pentium G4500",
    "condition": "Used With Box",
    "id": 2078,
    "master_id": 1829,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4500",
    "name": "Intel Pentium G4500",
    "condition": "Brand New Sealed",
    "id": 2079,
    "master_id": 1829,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4500T",
    "name": "Intel Pentium G4500T",
    "condition": "Used",
    "id": 2080,
    "master_id": 1830,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4500T",
    "name": "Intel Pentium G4500T",
    "condition": "Used With Box",
    "id": 2081,
    "master_id": 1830,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4500T",
    "name": "Intel Pentium G4500T",
    "condition": "Brand New Sealed",
    "id": 2082,
    "master_id": 1830,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4520",
    "name": "Intel Pentium G4520",
    "condition": "Used",
    "id": 2083,
    "master_id": 1831,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4520",
    "name": "Intel Pentium G4520",
    "condition": "Used With Box",
    "id": 2084,
    "master_id": 1831,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4520",
    "name": "Intel Pentium G4520",
    "condition": "Brand New Sealed",
    "id": 2085,
    "master_id": 1831,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4560",
    "name": "Intel Pentium G4560",
    "condition": "Used",
    "id": 2086,
    "master_id": 1832,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4560",
    "name": "Intel Pentium G4560",
    "condition": "Used With Box",
    "id": 2087,
    "master_id": 1832,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4560",
    "name": "Intel Pentium G4560",
    "condition": "Brand New Sealed",
    "id": 2088,
    "master_id": 1832,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4560T",
    "name": "Intel Pentium G4560T",
    "condition": "Used",
    "id": 2089,
    "master_id": 1833,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4560T",
    "name": "Intel Pentium G4560T",
    "condition": "Used With Box",
    "id": 2090,
    "master_id": 1833,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4560T",
    "name": "Intel Pentium G4560T",
    "condition": "Brand New Sealed",
    "id": 2091,
    "master_id": 1833,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4600",
    "name": "Intel Pentium G4600",
    "condition": "Used",
    "id": 2092,
    "master_id": 1834,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4600",
    "name": "Intel Pentium G4600",
    "condition": "Used With Box",
    "id": 2093,
    "master_id": 1834,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4600",
    "name": "Intel Pentium G4600",
    "condition": "Brand New Sealed",
    "id": 2094,
    "master_id": 1834,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4600T",
    "name": "Intel Pentium G4600T",
    "condition": "Used",
    "id": 2095,
    "master_id": 1835,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4600T",
    "name": "Intel Pentium G4600T",
    "condition": "Used With Box",
    "id": 2096,
    "master_id": 1835,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4600T",
    "name": "Intel Pentium G4600T",
    "condition": "Brand New Sealed",
    "id": 2097,
    "master_id": 1835,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4620",
    "name": "Intel Pentium G4620",
    "condition": "Used",
    "id": 2098,
    "master_id": 1836,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4620",
    "name": "Intel Pentium G4620",
    "condition": "Used With Box",
    "id": 2099,
    "master_id": 1836,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G4620",
    "name": "Intel Pentium G4620",
    "condition": "Brand New Sealed",
    "id": 2100,
    "master_id": 1836,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G5400",
    "name": "Intel Pentium G5400",
    "condition": "Used",
    "id": 2101,
    "master_id": 1837,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G5400",
    "name": "Intel Pentium G5400",
    "condition": "Used With Box",
    "id": 2102,
    "master_id": 1837,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G5400",
    "name": "Intel Pentium G5400",
    "condition": "Brand New Sealed",
    "id": 2103,
    "master_id": 1837,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6400",
    "name": "Intel Pentium G6400",
    "condition": "Used",
    "id": 2104,
    "master_id": 1838,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6400",
    "name": "Intel Pentium G6400",
    "condition": "Used With Box",
    "id": 2105,
    "master_id": 1838,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6400",
    "name": "Intel Pentium G6400",
    "condition": "Brand New Sealed",
    "id": 2106,
    "master_id": 1838,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6500",
    "name": "Intel Pentium G6500",
    "condition": "Used",
    "id": 2107,
    "master_id": 1839,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6500",
    "name": "Intel Pentium G6500",
    "condition": "Used With Box",
    "id": 2108,
    "master_id": 1839,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6500",
    "name": "Intel Pentium G6500",
    "condition": "Brand New Sealed",
    "id": 2109,
    "master_id": 1839,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6600",
    "name": "Intel Pentium G6600",
    "condition": "Used",
    "id": 2110,
    "master_id": 1840,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6600",
    "name": "Intel Pentium G6600",
    "condition": "Used With Box",
    "id": 2111,
    "master_id": 1840,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Pentium",
    "model": "G6600",
    "name": "Intel Pentium G6600",
    "condition": "Brand New Sealed",
    "id": 2112,
    "master_id": 1840,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-4370",
    "name": "Intel Core i3-4370",
    "condition": "Used",
    "id": 2113,
    "master_id": 1841,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-4370",
    "name": "Intel Core i3-4370",
    "condition": "Used With Box",
    "id": 2114,
    "master_id": 1841,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-4370",
    "name": "Intel Core i3-4370",
    "condition": "Brand New Sealed",
    "id": 2115,
    "master_id": 1841,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-4370T",
    "name": "Intel Core i3-4370T",
    "condition": "Used",
    "id": 2116,
    "master_id": 1842,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-4370T",
    "name": "Intel Core i3-4370T",
    "condition": "Used With Box",
    "id": 2117,
    "master_id": 1842,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-4370T",
    "name": "Intel Core i3-4370T",
    "condition": "Brand New Sealed",
    "id": 2118,
    "master_id": 1842,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6100",
    "name": "Intel Core i3-6100",
    "condition": "Used",
    "id": 2119,
    "master_id": 1843,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6100",
    "name": "Intel Core i3-6100",
    "condition": "Used With Box",
    "id": 2120,
    "master_id": 1843,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6100",
    "name": "Intel Core i3-6100",
    "condition": "Brand New Sealed",
    "id": 2121,
    "master_id": 1843,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6100T",
    "name": "Intel Core i3-6100T",
    "condition": "Used",
    "id": 2122,
    "master_id": 1844,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6100T",
    "name": "Intel Core i3-6100T",
    "condition": "Used With Box",
    "id": 2123,
    "master_id": 1844,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6100T",
    "name": "Intel Core i3-6100T",
    "condition": "Brand New Sealed",
    "id": 2124,
    "master_id": 1844,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6300",
    "name": "Intel Core i3-6300",
    "condition": "Used",
    "id": 2125,
    "master_id": 1845,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6300",
    "name": "Intel Core i3-6300",
    "condition": "Used With Box",
    "id": 2126,
    "master_id": 1845,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6300",
    "name": "Intel Core i3-6300",
    "condition": "Brand New Sealed",
    "id": 2127,
    "master_id": 1845,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6300T",
    "name": "Intel Core i3-6300T",
    "condition": "Used",
    "id": 2128,
    "master_id": 1846,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6300T",
    "name": "Intel Core i3-6300T",
    "condition": "Used With Box",
    "id": 2129,
    "master_id": 1846,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6300T",
    "name": "Intel Core i3-6300T",
    "condition": "Brand New Sealed",
    "id": 2130,
    "master_id": 1846,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6320",
    "name": "Intel Core i3-6320",
    "condition": "Used",
    "id": 2131,
    "master_id": 1847,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6320",
    "name": "Intel Core i3-6320",
    "condition": "Used With Box",
    "id": 2132,
    "master_id": 1847,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-6320",
    "name": "Intel Core i3-6320",
    "condition": "Brand New Sealed",
    "id": 2133,
    "master_id": 1847,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7100",
    "name": "Intel Core i3-7100",
    "condition": "Used",
    "id": 2134,
    "master_id": 1848,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7100",
    "name": "Intel Core i3-7100",
    "condition": "Used With Box",
    "id": 2135,
    "master_id": 1848,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7100",
    "name": "Intel Core i3-7100",
    "condition": "Brand New Sealed",
    "id": 2136,
    "master_id": 1848,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7100T",
    "name": "Intel Core i3-7100T",
    "condition": "Used",
    "id": 2137,
    "master_id": 1849,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7100T",
    "name": "Intel Core i3-7100T",
    "condition": "Used With Box",
    "id": 2138,
    "master_id": 1849,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7100T",
    "name": "Intel Core i3-7100T",
    "condition": "Brand New Sealed",
    "id": 2139,
    "master_id": 1849,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7300",
    "name": "Intel Core i3-7300",
    "condition": "Used",
    "id": 2140,
    "master_id": 1850,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7300",
    "name": "Intel Core i3-7300",
    "condition": "Used With Box",
    "id": 2141,
    "master_id": 1850,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7300",
    "name": "Intel Core i3-7300",
    "condition": "Brand New Sealed",
    "id": 2142,
    "master_id": 1850,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7300T",
    "name": "Intel Core i3-7300T",
    "condition": "Used",
    "id": 2143,
    "master_id": 1851,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7300T",
    "name": "Intel Core i3-7300T",
    "condition": "Used With Box",
    "id": 2144,
    "master_id": 1851,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7300T",
    "name": "Intel Core i3-7300T",
    "condition": "Brand New Sealed",
    "id": 2145,
    "master_id": 1851,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7320",
    "name": "Intel Core i3-7320",
    "condition": "Used",
    "id": 2146,
    "master_id": 1852,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7320",
    "name": "Intel Core i3-7320",
    "condition": "Used With Box",
    "id": 2147,
    "master_id": 1852,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7320",
    "name": "Intel Core i3-7320",
    "condition": "Brand New Sealed",
    "id": 2148,
    "master_id": 1852,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7350K",
    "name": "Intel Core i3-7350K",
    "condition": "Used",
    "id": 2149,
    "master_id": 1853,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7350K",
    "name": "Intel Core i3-7350K",
    "condition": "Used With Box",
    "id": 2150,
    "master_id": 1853,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-7350K",
    "name": "Intel Core i3-7350K",
    "condition": "Brand New Sealed",
    "id": 2151,
    "master_id": 1853,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8100T",
    "name": "Intel Core i3-8100T",
    "condition": "Used",
    "id": 2152,
    "master_id": 1854,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8100T",
    "name": "Intel Core i3-8100T",
    "condition": "Used With Box",
    "id": 2153,
    "master_id": 1854,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8100T",
    "name": "Intel Core i3-8100T",
    "condition": "Brand New Sealed",
    "id": 2154,
    "master_id": 1854,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8100",
    "name": "Intel Core i3-8100",
    "condition": "Used",
    "id": 2155,
    "master_id": 1855,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8100",
    "name": "Intel Core i3-8100",
    "condition": "Used With Box",
    "id": 2156,
    "master_id": 1855,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8100",
    "name": "Intel Core i3-8100",
    "condition": "Brand New Sealed",
    "id": 2157,
    "master_id": 1855,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8350K",
    "name": "Intel Core i3-8350K",
    "condition": "Used",
    "id": 2158,
    "master_id": 1856,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8350K",
    "name": "Intel Core i3-8350K",
    "condition": "Used With Box",
    "id": 2159,
    "master_id": 1856,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-8350K",
    "name": "Intel Core i3-8350K",
    "condition": "Brand New Sealed",
    "id": 2160,
    "master_id": 1856,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100T",
    "name": "Intel Core i3-9100T",
    "condition": "Used",
    "id": 2161,
    "master_id": 1857,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100T",
    "name": "Intel Core i3-9100T",
    "condition": "Used With Box",
    "id": 2162,
    "master_id": 1857,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100T",
    "name": "Intel Core i3-9100T",
    "condition": "Brand New Sealed",
    "id": 2163,
    "master_id": 1857,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100F",
    "name": "Intel Core i3-9100F",
    "condition": "Used",
    "id": 2164,
    "master_id": 1858,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100F",
    "name": "Intel Core i3-9100F",
    "condition": "Used With Box",
    "id": 2165,
    "master_id": 1858,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100F",
    "name": "Intel Core i3-9100F",
    "condition": "Brand New Sealed",
    "id": 2166,
    "master_id": 1858,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100",
    "name": "Intel Core i3-9100",
    "condition": "Used",
    "id": 2167,
    "master_id": 1859,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100",
    "name": "Intel Core i3-9100",
    "condition": "Used With Box",
    "id": 2168,
    "master_id": 1859,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9100",
    "name": "Intel Core i3-9100",
    "condition": "Brand New Sealed",
    "id": 2169,
    "master_id": 1859,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9300",
    "name": "Intel Core i3-9300",
    "condition": "Used",
    "id": 2170,
    "master_id": 1860,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9300",
    "name": "Intel Core i3-9300",
    "condition": "Used With Box",
    "id": 2171,
    "master_id": 1860,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-9300",
    "name": "Intel Core i3-9300",
    "condition": "Brand New Sealed",
    "id": 2172,
    "master_id": 1860,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10100F",
    "name": "Intel Core i3-10100F",
    "condition": "Used",
    "id": 2173,
    "master_id": 1861,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10100F",
    "name": "Intel Core i3-10100F",
    "condition": "Used With Box",
    "id": 2174,
    "master_id": 1861,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10100F",
    "name": "Intel Core i3-10100F",
    "condition": "Brand New Sealed",
    "id": 2175,
    "master_id": 1861,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10100",
    "name": "Intel Core i3-10100",
    "condition": "Used",
    "id": 2176,
    "master_id": 1862,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10100",
    "name": "Intel Core i3-10100",
    "condition": "Used With Box",
    "id": 2177,
    "master_id": 1862,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10100",
    "name": "Intel Core i3-10100",
    "condition": "Brand New Sealed",
    "id": 2178,
    "master_id": 1862,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105",
    "name": "Intel Core i3-10105",
    "condition": "Used",
    "id": 6188,
    "master_id": 6175,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105",
    "name": "Intel Core i3-10105",
    "condition": "Used With Box",
    "id": 6189,
    "master_id": 6175,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105",
    "name": "Intel Core i3-10105",
    "condition": "Brand New Sealed",
    "id": 6190,
    "master_id": 6175,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105F",
    "name": "Intel Core i3-10105F",
    "condition": "Used",
    "id": 7052,
    "master_id": 7050,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105F",
    "name": "Intel Core i3-10105F",
    "condition": "Used With Box",
    "id": 7053,
    "master_id": 7050,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105F",
    "name": "Intel Core i3-10105F",
    "condition": "Brand New Sealed",
    "id": 7054,
    "master_id": 7050,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105T",
    "name": "Intel Core i3-10105T",
    "condition": "Used",
    "id": 7055,
    "master_id": 7051,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105T",
    "name": "Intel Core i3-10105T",
    "condition": "Used With Box",
    "id": 7056,
    "master_id": 7051,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10105T",
    "name": "Intel Core i3-10105T",
    "condition": "Brand New Sealed",
    "id": 7057,
    "master_id": 7051,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10300",
    "name": "Intel Core i3-10300",
    "condition": "Used",
    "id": 2179,
    "master_id": 1863,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10300",
    "name": "Intel Core i3-10300",
    "condition": "Used With Box",
    "id": 2180,
    "master_id": 1863,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10300",
    "name": "Intel Core i3-10300",
    "condition": "Brand New Sealed",
    "id": 2181,
    "master_id": 1863,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10320",
    "name": "Intel Core i3-10320",
    "condition": "Used",
    "id": 2182,
    "master_id": 1864,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10320",
    "name": "Intel Core i3-10320",
    "condition": "Used With Box",
    "id": 2183,
    "master_id": 1864,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-10320",
    "name": "Intel Core i3-10320",
    "condition": "Brand New Sealed",
    "id": 2184,
    "master_id": 1864,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-12100",
    "name": "Intel Core i3-12100",
    "condition": "Used",
    "id": 2185,
    "master_id": 1865,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-12100",
    "name": "Intel Core i3-12100",
    "condition": "Used With Box",
    "id": 2186,
    "master_id": 1865,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-12100",
    "name": "Intel Core i3-12100",
    "condition": "Brand New Sealed",
    "id": 2187,
    "master_id": 1865,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-12100F",
    "name": "Intel Core i3-12100F",
    "condition": "Used",
    "id": 2188,
    "master_id": 1866,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-12100F",
    "name": "Intel Core i3-12100F",
    "condition": "Used With Box",
    "id": 2189,
    "master_id": 1866,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-12100F",
    "name": "Intel Core i3-12100F",
    "condition": "Brand New Sealed",
    "id": 2190,
    "master_id": 1866,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100",
    "name": "Intel Core i3-13100",
    "condition": "Used",
    "id": 2191,
    "master_id": 1867,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100",
    "name": "Intel Core i3-13100",
    "condition": "Used With Box",
    "id": 2192,
    "master_id": 1867,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100",
    "name": "Intel Core i3-13100",
    "condition": "Brand New Sealed",
    "id": 2193,
    "master_id": 1867,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100F",
    "name": "Intel Core i3-13100F",
    "condition": "Used",
    "id": 2194,
    "master_id": 1868,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100F",
    "name": "Intel Core i3-13100F",
    "condition": "Used With Box",
    "id": 2195,
    "master_id": 1868,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100F",
    "name": "Intel Core i3-13100F",
    "condition": "Brand New Sealed",
    "id": 2196,
    "master_id": 1868,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100T",
    "name": "Intel Core i3-13100T",
    "condition": "Used",
    "id": 2197,
    "master_id": 1869,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100T",
    "name": "Intel Core i3-13100T",
    "condition": "Used With Box",
    "id": 2198,
    "master_id": 1869,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-13100T",
    "name": "Intel Core i3-13100T",
    "condition": "Brand New Sealed",
    "id": 2199,
    "master_id": 1869,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100",
    "name": "Intel Core i3-14100",
    "condition": "Used",
    "id": 6244,
    "master_id": 6235,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100",
    "name": "Intel Core i3-14100",
    "condition": "Used With Box",
    "id": 6245,
    "master_id": 6235,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100",
    "name": "Intel Core i3-14100",
    "condition": "Brand New Sealed",
    "id": 6246,
    "master_id": 6235,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100F",
    "name": "Intel Core i3-14100F",
    "condition": "Used",
    "id": 6241,
    "master_id": 6234,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100F",
    "name": "Intel Core i3-14100F",
    "condition": "Used With Box",
    "id": 6242,
    "master_id": 6234,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100F",
    "name": "Intel Core i3-14100F",
    "condition": "Brand New Sealed",
    "id": 6243,
    "master_id": 6234,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100T",
    "name": "Intel Core i3-14100T",
    "condition": "Used",
    "id": 6238,
    "master_id": 6233,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100T",
    "name": "Intel Core i3-14100T",
    "condition": "Used With Box",
    "id": 6239,
    "master_id": 6233,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i3",
    "model": "i3-14100T",
    "name": "Intel Core i3-14100T",
    "condition": "Brand New Sealed",
    "id": 6240,
    "master_id": 6233,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570K",
    "name": "Intel Core i5-3570K",
    "condition": "Used",
    "id": 2200,
    "master_id": 1870,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570K",
    "name": "Intel Core i5-3570K",
    "condition": "Used With Box",
    "id": 2201,
    "master_id": 1870,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570K",
    "name": "Intel Core i5-3570K",
    "condition": "Brand New Sealed",
    "id": 2202,
    "master_id": 1870,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570",
    "name": "Intel Core i5-3570",
    "condition": "Used",
    "id": 2203,
    "master_id": 1871,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570",
    "name": "Intel Core i5-3570",
    "condition": "Used With Box",
    "id": 2204,
    "master_id": 1871,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570",
    "name": "Intel Core i5-3570",
    "condition": "Brand New Sealed",
    "id": 2205,
    "master_id": 1871,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570S",
    "name": "Intel Core i5-3570S",
    "condition": "Used",
    "id": 2206,
    "master_id": 1872,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570S",
    "name": "Intel Core i5-3570S",
    "condition": "Used With Box",
    "id": 2207,
    "master_id": 1872,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-3570S",
    "name": "Intel Core i5-3570S",
    "condition": "Brand New Sealed",
    "id": 2208,
    "master_id": 1872,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4430",
    "name": "Intel Core i5-4430",
    "condition": "Used",
    "id": 2209,
    "master_id": 1873,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4430",
    "name": "Intel Core i5-4430",
    "condition": "Used With Box",
    "id": 2210,
    "master_id": 1873,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4430",
    "name": "Intel Core i5-4430",
    "condition": "Brand New Sealed",
    "id": 2211,
    "master_id": 1873,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4430S",
    "name": "Intel Core i5-4430S",
    "condition": "Used",
    "id": 2212,
    "master_id": 1874,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4430S",
    "name": "Intel Core i5-4430S",
    "condition": "Used With Box",
    "id": 2213,
    "master_id": 1874,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4430S",
    "name": "Intel Core i5-4430S",
    "condition": "Brand New Sealed",
    "id": 2214,
    "master_id": 1874,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4440",
    "name": "Intel Core i5-4440",
    "condition": "Used",
    "id": 2215,
    "master_id": 1875,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4440",
    "name": "Intel Core i5-4440",
    "condition": "Used With Box",
    "id": 2216,
    "master_id": 1875,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4440",
    "name": "Intel Core i5-4440",
    "condition": "Brand New Sealed",
    "id": 2217,
    "master_id": 1875,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4440S",
    "name": "Intel Core i5-4440S",
    "condition": "Used",
    "id": 2218,
    "master_id": 1876,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4440S",
    "name": "Intel Core i5-4440S",
    "condition": "Used With Box",
    "id": 2219,
    "master_id": 1876,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4440S",
    "name": "Intel Core i5-4440S",
    "condition": "Brand New Sealed",
    "id": 2220,
    "master_id": 1876,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4460",
    "name": "Intel Core i5-4460",
    "condition": "Used",
    "id": 2221,
    "master_id": 1877,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4460",
    "name": "Intel Core i5-4460",
    "condition": "Used With Box",
    "id": 2222,
    "master_id": 1877,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4460",
    "name": "Intel Core i5-4460",
    "condition": "Brand New Sealed",
    "id": 2223,
    "master_id": 1877,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4460S",
    "name": "Intel Core i5-4460S",
    "condition": "Used",
    "id": 2224,
    "master_id": 1878,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4460S",
    "name": "Intel Core i5-4460S",
    "condition": "Used With Box",
    "id": 2225,
    "master_id": 1878,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4460S",
    "name": "Intel Core i5-4460S",
    "condition": "Brand New Sealed",
    "id": 2226,
    "master_id": 1878,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570",
    "name": "Intel Core i5-4570",
    "condition": "Used",
    "id": 2227,
    "master_id": 1879,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570",
    "name": "Intel Core i5-4570",
    "condition": "Used With Box",
    "id": 2228,
    "master_id": 1879,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570",
    "name": "Intel Core i5-4570",
    "condition": "Brand New Sealed",
    "id": 2229,
    "master_id": 1879,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570S",
    "name": "Intel Core i5-4570S",
    "condition": "Used",
    "id": 2230,
    "master_id": 1880,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570S",
    "name": "Intel Core i5-4570S",
    "condition": "Used With Box",
    "id": 2231,
    "master_id": 1880,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570S",
    "name": "Intel Core i5-4570S",
    "condition": "Brand New Sealed",
    "id": 2232,
    "master_id": 1880,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590",
    "name": "Intel Core i5-4590",
    "condition": "Used",
    "id": 2233,
    "master_id": 1881,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590",
    "name": "Intel Core i5-4590",
    "condition": "Used With Box",
    "id": 2234,
    "master_id": 1881,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590",
    "name": "Intel Core i5-4590",
    "condition": "Brand New Sealed",
    "id": 2235,
    "master_id": 1881,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590S",
    "name": "Intel Core i5-4590S",
    "condition": "Used",
    "id": 2236,
    "master_id": 1882,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590S",
    "name": "Intel Core i5-4590S",
    "condition": "Used With Box",
    "id": 2237,
    "master_id": 1882,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590S",
    "name": "Intel Core i5-4590S",
    "condition": "Brand New Sealed",
    "id": 2238,
    "master_id": 1882,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670",
    "name": "Intel Core i5-4670",
    "condition": "Used",
    "id": 2239,
    "master_id": 1883,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670",
    "name": "Intel Core i5-4670",
    "condition": "Used With Box",
    "id": 2240,
    "master_id": 1883,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670",
    "name": "Intel Core i5-4670",
    "condition": "Brand New Sealed",
    "id": 2241,
    "master_id": 1883,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570TE",
    "name": "Intel Core i5-4570TE",
    "condition": "Used",
    "id": 2242,
    "master_id": 1884,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570TE",
    "name": "Intel Core i5-4570TE",
    "condition": "Used With Box",
    "id": 2243,
    "master_id": 1884,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4570TE",
    "name": "Intel Core i5-4570TE",
    "condition": "Brand New Sealed",
    "id": 2244,
    "master_id": 1884,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590T",
    "name": "Intel Core i5-4590T",
    "condition": "Used",
    "id": 2245,
    "master_id": 1885,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590T",
    "name": "Intel Core i5-4590T",
    "condition": "Used With Box",
    "id": 2246,
    "master_id": 1885,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4590T",
    "name": "Intel Core i5-4590T",
    "condition": "Brand New Sealed",
    "id": 2247,
    "master_id": 1885,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670K",
    "name": "Intel Core i5-4670K",
    "condition": "Used",
    "id": 2248,
    "master_id": 1886,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670K",
    "name": "Intel Core i5-4670K",
    "condition": "Used With Box",
    "id": 2249,
    "master_id": 1886,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670K",
    "name": "Intel Core i5-4670K",
    "condition": "Brand New Sealed",
    "id": 2250,
    "master_id": 1886,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670S",
    "name": "Intel Core i5-4670S",
    "condition": "Used",
    "id": 2251,
    "master_id": 1887,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670S",
    "name": "Intel Core i5-4670S",
    "condition": "Used With Box",
    "id": 2252,
    "master_id": 1887,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4670S",
    "name": "Intel Core i5-4670S",
    "condition": "Brand New Sealed",
    "id": 2253,
    "master_id": 1887,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690",
    "name": "Intel Core i5-4690",
    "condition": "Used",
    "id": 2254,
    "master_id": 1888,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690",
    "name": "Intel Core i5-4690",
    "condition": "Used With Box",
    "id": 2255,
    "master_id": 1888,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690",
    "name": "Intel Core i5-4690",
    "condition": "Brand New Sealed",
    "id": 2256,
    "master_id": 1888,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690K",
    "name": "Intel Core i5-4690K",
    "condition": "Used",
    "id": 2257,
    "master_id": 1889,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690K",
    "name": "Intel Core i5-4690K",
    "condition": "Used With Box",
    "id": 2258,
    "master_id": 1889,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690K",
    "name": "Intel Core i5-4690K",
    "condition": "Brand New Sealed",
    "id": 2259,
    "master_id": 1889,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690S",
    "name": "Intel Core i5-4690S",
    "condition": "Used",
    "id": 2260,
    "master_id": 1890,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690S",
    "name": "Intel Core i5-4690S",
    "condition": "Used With Box",
    "id": 2261,
    "master_id": 1890,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-4690S",
    "name": "Intel Core i5-4690S",
    "condition": "Brand New Sealed",
    "id": 2262,
    "master_id": 1890,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-5675C",
    "name": "Intel Core i5-5675C",
    "condition": "Used",
    "id": 2263,
    "master_id": 1891,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-5675C",
    "name": "Intel Core i5-5675C",
    "condition": "Used With Box",
    "id": 2264,
    "master_id": 1891,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-5675C",
    "name": "Intel Core i5-5675C",
    "condition": "Brand New Sealed",
    "id": 2265,
    "master_id": 1891,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6400",
    "name": "Intel Core i5-6400",
    "condition": "Used",
    "id": 2266,
    "master_id": 1892,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6400",
    "name": "Intel Core i5-6400",
    "condition": "Used With Box",
    "id": 2267,
    "master_id": 1892,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6400",
    "name": "Intel Core i5-6400",
    "condition": "Brand New Sealed",
    "id": 2268,
    "master_id": 1892,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6400T",
    "name": "Intel Core i5-6400T",
    "condition": "Used",
    "id": 2269,
    "master_id": 1893,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6400T",
    "name": "Intel Core i5-6400T",
    "condition": "Used With Box",
    "id": 2270,
    "master_id": 1893,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6400T",
    "name": "Intel Core i5-6400T",
    "condition": "Brand New Sealed",
    "id": 2271,
    "master_id": 1893,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6402P",
    "name": "Intel Core i5-6402P",
    "condition": "Used",
    "id": 2272,
    "master_id": 1894,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6402P",
    "name": "Intel Core i5-6402P",
    "condition": "Used With Box",
    "id": 2273,
    "master_id": 1894,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6402P",
    "name": "Intel Core i5-6402P",
    "condition": "Brand New Sealed",
    "id": 2274,
    "master_id": 1894,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6500",
    "name": "Intel Core i5-6500",
    "condition": "Used",
    "id": 2275,
    "master_id": 1895,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6500",
    "name": "Intel Core i5-6500",
    "condition": "Used With Box",
    "id": 2276,
    "master_id": 1895,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6500",
    "name": "Intel Core i5-6500",
    "condition": "Brand New Sealed",
    "id": 2277,
    "master_id": 1895,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6500T",
    "name": "Intel Core i5-6500T",
    "condition": "Used",
    "id": 2278,
    "master_id": 1896,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6500T",
    "name": "Intel Core i5-6500T",
    "condition": "Used With Box",
    "id": 2279,
    "master_id": 1896,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6500T",
    "name": "Intel Core i5-6500T",
    "condition": "Brand New Sealed",
    "id": 2280,
    "master_id": 1896,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6600",
    "name": "Intel Core i5-6600",
    "condition": "Used",
    "id": 2281,
    "master_id": 1897,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6600",
    "name": "Intel Core i5-6600",
    "condition": "Used With Box",
    "id": 2282,
    "master_id": 1897,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6600",
    "name": "Intel Core i5-6600",
    "condition": "Brand New Sealed",
    "id": 2283,
    "master_id": 1897,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6600K",
    "name": "Intel Core i5-6600K",
    "condition": "Used",
    "id": 2284,
    "master_id": 1898,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6600K",
    "name": "Intel Core i5-6600K",
    "condition": "Used With Box",
    "id": 2285,
    "master_id": 1898,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-6600K",
    "name": "Intel Core i5-6600K",
    "condition": "Brand New Sealed",
    "id": 2286,
    "master_id": 1898,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7400",
    "name": "Intel Core i5-7400",
    "condition": "Used",
    "id": 2287,
    "master_id": 1899,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7400",
    "name": "Intel Core i5-7400",
    "condition": "Used With Box",
    "id": 2288,
    "master_id": 1899,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7400",
    "name": "Intel Core i5-7400",
    "condition": "Brand New Sealed",
    "id": 2289,
    "master_id": 1899,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7400T",
    "name": "Intel Core i5-7400T",
    "condition": "Used",
    "id": 2290,
    "master_id": 1900,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7400T",
    "name": "Intel Core i5-7400T",
    "condition": "Used With Box",
    "id": 2291,
    "master_id": 1900,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7400T",
    "name": "Intel Core i5-7400T",
    "condition": "Brand New Sealed",
    "id": 2292,
    "master_id": 1900,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7500",
    "name": "Intel Core i5-7500",
    "condition": "Used",
    "id": 2293,
    "master_id": 1901,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7500",
    "name": "Intel Core i5-7500",
    "condition": "Used With Box",
    "id": 2294,
    "master_id": 1901,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7500",
    "name": "Intel Core i5-7500",
    "condition": "Brand New Sealed",
    "id": 2295,
    "master_id": 1901,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7500T",
    "name": "Intel Core i5-7500T",
    "condition": "Used",
    "id": 2296,
    "master_id": 1902,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7500T",
    "name": "Intel Core i5-7500T",
    "condition": "Used With Box",
    "id": 2297,
    "master_id": 1902,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7500T",
    "name": "Intel Core i5-7500T",
    "condition": "Brand New Sealed",
    "id": 2298,
    "master_id": 1902,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600",
    "name": "Intel Core i5-7600",
    "condition": "Used",
    "id": 2299,
    "master_id": 1903,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600",
    "name": "Intel Core i5-7600",
    "condition": "Used With Box",
    "id": 2300,
    "master_id": 1903,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600",
    "name": "Intel Core i5-7600",
    "condition": "Brand New Sealed",
    "id": 2301,
    "master_id": 1903,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600K",
    "name": "Intel Core i5-7600K",
    "condition": "Used",
    "id": 2302,
    "master_id": 1904,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600K",
    "name": "Intel Core i5-7600K",
    "condition": "Used With Box",
    "id": 2303,
    "master_id": 1904,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600K",
    "name": "Intel Core i5-7600K",
    "condition": "Brand New Sealed",
    "id": 2304,
    "master_id": 1904,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600T",
    "name": "Intel Core i5-7600T",
    "condition": "Used",
    "id": 2305,
    "master_id": 1905,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600T",
    "name": "Intel Core i5-7600T",
    "condition": "Used With Box",
    "id": 2306,
    "master_id": 1905,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-7600T",
    "name": "Intel Core i5-7600T",
    "condition": "Brand New Sealed",
    "id": 2307,
    "master_id": 1905,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8400T",
    "name": "Intel Core i5-8400T",
    "condition": "Used",
    "id": 2308,
    "master_id": 1906,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8400T",
    "name": "Intel Core i5-8400T",
    "condition": "Used With Box",
    "id": 2309,
    "master_id": 1906,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8400T",
    "name": "Intel Core i5-8400T",
    "condition": "Brand New Sealed",
    "id": 2310,
    "master_id": 1906,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8400",
    "name": "Intel Core i5-8400",
    "condition": "Used",
    "id": 2311,
    "master_id": 1907,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8400",
    "name": "Intel Core i5-8400",
    "condition": "Used With Box",
    "id": 2312,
    "master_id": 1907,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8400",
    "name": "Intel Core i5-8400",
    "condition": "Brand New Sealed",
    "id": 2313,
    "master_id": 1907,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8500T",
    "name": "Intel Core i5-8500T",
    "condition": "Used",
    "id": 2314,
    "master_id": 1908,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8500T",
    "name": "Intel Core i5-8500T",
    "condition": "Used With Box",
    "id": 2315,
    "master_id": 1908,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8500T",
    "name": "Intel Core i5-8500T",
    "condition": "Brand New Sealed",
    "id": 2316,
    "master_id": 1908,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8500",
    "name": "Intel Core i5-8500",
    "condition": "Used",
    "id": 2317,
    "master_id": 1909,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8500",
    "name": "Intel Core i5-8500",
    "condition": "Used With Box",
    "id": 2318,
    "master_id": 1909,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8500",
    "name": "Intel Core i5-8500",
    "condition": "Brand New Sealed",
    "id": 2319,
    "master_id": 1909,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8600K",
    "name": "Intel Core i5-8600K",
    "condition": "Used",
    "id": 2320,
    "master_id": 1910,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8600K",
    "name": "Intel Core i5-8600K",
    "condition": "Used With Box",
    "id": 2321,
    "master_id": 1910,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-8600K",
    "name": "Intel Core i5-8600K",
    "condition": "Brand New Sealed",
    "id": 2322,
    "master_id": 1910,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400",
    "name": "Intel Core i5-9400",
    "condition": "Used",
    "id": 2323,
    "master_id": 1911,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400",
    "name": "Intel Core i5-9400",
    "condition": "Used With Box",
    "id": 2324,
    "master_id": 1911,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400",
    "name": "Intel Core i5-9400",
    "condition": "Brand New Sealed",
    "id": 2325,
    "master_id": 1911,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400F",
    "name": "Intel Core i5-9400F",
    "condition": "Used",
    "id": 2326,
    "master_id": 1912,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400F",
    "name": "Intel Core i5-9400F",
    "condition": "Used With Box",
    "id": 2327,
    "master_id": 1912,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400F",
    "name": "Intel Core i5-9400F",
    "condition": "Brand New Sealed",
    "id": 2328,
    "master_id": 1912,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400T",
    "name": "Intel Core i5-9400T",
    "condition": "Used",
    "id": 6197,
    "master_id": 6178,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400T",
    "name": "Intel Core i5-9400T",
    "condition": "Used With Box",
    "id": 6198,
    "master_id": 6178,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9400T",
    "name": "Intel Core i5-9400T",
    "condition": "Brand New Sealed",
    "id": 6199,
    "master_id": 6178,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9500",
    "name": "Intel Core i5-9500",
    "condition": "Used",
    "id": 2329,
    "master_id": 1913,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9500",
    "name": "Intel Core i5-9500",
    "condition": "Used With Box",
    "id": 2330,
    "master_id": 1913,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9500",
    "name": "Intel Core i5-9500",
    "condition": "Brand New Sealed",
    "id": 2331,
    "master_id": 1913,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9500K",
    "name": "Intel Core i5-9500K",
    "condition": "Used",
    "id": 2332,
    "master_id": 1914,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9500K",
    "name": "Intel Core i5-9500K",
    "condition": "Used With Box",
    "id": 2333,
    "master_id": 1914,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9500K",
    "name": "Intel Core i5-9500K",
    "condition": "Brand New Sealed",
    "id": 2334,
    "master_id": 1914,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600",
    "name": "Intel Core i5-9600",
    "condition": "Used",
    "id": 2335,
    "master_id": 1915,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600",
    "name": "Intel Core i5-9600",
    "condition": "Used With Box",
    "id": 2336,
    "master_id": 1915,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600",
    "name": "Intel Core i5-9600",
    "condition": "Brand New Sealed",
    "id": 2337,
    "master_id": 1915,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600K",
    "name": "Intel Core i5-9600K",
    "condition": "Used",
    "id": 2338,
    "master_id": 1916,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600K",
    "name": "Intel Core i5-9600K",
    "condition": "Used With Box",
    "id": 2339,
    "master_id": 1916,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600K",
    "name": "Intel Core i5-9600K",
    "condition": "Brand New Sealed",
    "id": 2340,
    "master_id": 1916,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600KF",
    "name": "Intel Core i5-9600KF",
    "condition": "Used",
    "id": 2341,
    "master_id": 1917,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600KF",
    "name": "Intel Core i5-9600KF",
    "condition": "Used With Box",
    "id": 2342,
    "master_id": 1917,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-9600KF",
    "name": "Intel Core i5-9600KF",
    "condition": "Brand New Sealed",
    "id": 2343,
    "master_id": 1917,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400",
    "name": "Intel Core i5-10400",
    "condition": "Used",
    "id": 2344,
    "master_id": 1918,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400",
    "name": "Intel Core i5-10400",
    "condition": "Used With Box",
    "id": 2345,
    "master_id": 1918,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400",
    "name": "Intel Core i5-10400",
    "condition": "Brand New Sealed",
    "id": 2346,
    "master_id": 1918,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400F",
    "name": "Intel Core i5-10400F",
    "condition": "Used",
    "id": 2347,
    "master_id": 1919,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400F",
    "name": "Intel Core i5-10400F",
    "condition": "Used With Box",
    "id": 2348,
    "master_id": 1919,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400F",
    "name": "Intel Core i5-10400F",
    "condition": "Brand New Sealed",
    "id": 2349,
    "master_id": 1919,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400T",
    "name": "Intel Core i5-10400T",
    "condition": "Used",
    "id": 6194,
    "master_id": 6177,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400T",
    "name": "Intel Core i5-10400T",
    "condition": "Used With Box",
    "id": 6195,
    "master_id": 6177,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10400T",
    "name": "Intel Core i5-10400T",
    "condition": "Brand New Sealed",
    "id": 6196,
    "master_id": 6177,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10500",
    "name": "Intel Core i5-10500",
    "condition": "Used",
    "id": 2350,
    "master_id": 1920,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10500",
    "name": "Intel Core i5-10500",
    "condition": "Used With Box",
    "id": 2351,
    "master_id": 1920,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10500",
    "name": "Intel Core i5-10500",
    "condition": "Brand New Sealed",
    "id": 2352,
    "master_id": 1920,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600",
    "name": "Intel Core i5-10600",
    "condition": "Used",
    "id": 2353,
    "master_id": 1921,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600",
    "name": "Intel Core i5-10600",
    "condition": "Used With Box",
    "id": 2354,
    "master_id": 1921,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600",
    "name": "Intel Core i5-10600",
    "condition": "Brand New Sealed",
    "id": 2355,
    "master_id": 1921,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600K",
    "name": "Intel Core i5-10600K",
    "condition": "Used",
    "id": 2356,
    "master_id": 1922,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600K",
    "name": "Intel Core i5-10600K",
    "condition": "Used With Box",
    "id": 2357,
    "master_id": 1922,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600K",
    "name": "Intel Core i5-10600K",
    "condition": "Brand New Sealed",
    "id": 2358,
    "master_id": 1922,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600KF",
    "name": "Intel Core i5-10600KF",
    "condition": "Used",
    "id": 2359,
    "master_id": 1923,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600KF",
    "name": "Intel Core i5-10600KF",
    "condition": "Used With Box",
    "id": 2360,
    "master_id": 1923,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-10600KF",
    "name": "Intel Core i5-10600KF",
    "condition": "Brand New Sealed",
    "id": 2361,
    "master_id": 1923,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11400F",
    "name": "Intel Core i5-11400F",
    "condition": "Used",
    "id": 2362,
    "master_id": 1924,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11400F",
    "name": "Intel Core i5-11400F",
    "condition": "Used With Box",
    "id": 2363,
    "master_id": 1924,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11400F",
    "name": "Intel Core i5-11400F",
    "condition": "Brand New Sealed",
    "id": 2364,
    "master_id": 1924,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11400",
    "name": "Intel Core i5-11400",
    "condition": "Used",
    "id": 2365,
    "master_id": 1925,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11400",
    "name": "Intel Core i5-11400",
    "condition": "Used With Box",
    "id": 2366,
    "master_id": 1925,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11400",
    "name": "Intel Core i5-11400",
    "condition": "Brand New Sealed",
    "id": 2367,
    "master_id": 1925,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11500",
    "name": "Intel Core i5-11500",
    "condition": "Used",
    "id": 2368,
    "master_id": 1926,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11500",
    "name": "Intel Core i5-11500",
    "condition": "Used With Box",
    "id": 2369,
    "master_id": 1926,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11500",
    "name": "Intel Core i5-11500",
    "condition": "Brand New Sealed",
    "id": 2370,
    "master_id": 1926,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11600K",
    "name": "Intel Core i5-11600K",
    "condition": "Used",
    "id": 2371,
    "master_id": 1927,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11600K",
    "name": "Intel Core i5-11600K",
    "condition": "Used With Box",
    "id": 2372,
    "master_id": 1927,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-11600K",
    "name": "Intel Core i5-11600K",
    "condition": "Brand New Sealed",
    "id": 2373,
    "master_id": 1927,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400",
    "name": "Intel Core i5-12400",
    "condition": "Used",
    "id": 3897,
    "master_id": 3896,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400",
    "name": "Intel Core i5-12400",
    "condition": "Used With Box",
    "id": 3898,
    "master_id": 3896,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400",
    "name": "Intel Core i5-12400",
    "condition": "Brand New Sealed",
    "id": 3899,
    "master_id": 3896,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400F",
    "name": "Intel Core i5-12400F",
    "condition": "Used",
    "id": 3901,
    "master_id": 3900,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400F",
    "name": "Intel Core i5-12400F",
    "condition": "Used With Box",
    "id": 3902,
    "master_id": 3900,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400F",
    "name": "Intel Core i5-12400F",
    "condition": "Brand New Sealed",
    "id": 3903,
    "master_id": 3900,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400T",
    "name": "Intel Core i5-12400T",
    "condition": "Used",
    "id": 3905,
    "master_id": 3904,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400T",
    "name": "Intel Core i5-12400T",
    "condition": "Used With Box",
    "id": 3906,
    "master_id": 3904,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12400T",
    "name": "Intel Core i5-12400T",
    "condition": "Brand New Sealed",
    "id": 3907,
    "master_id": 3904,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12500",
    "name": "Intel Core i5-12500",
    "condition": "Used",
    "id": 3909,
    "master_id": 3908,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12500",
    "name": "Intel Core i5-12500",
    "condition": "Used With Box",
    "id": 3910,
    "master_id": 3908,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12500",
    "name": "Intel Core i5-12500",
    "condition": "Brand New Sealed",
    "id": 3911,
    "master_id": 3908,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12500T",
    "name": "Intel Core i5-12500T",
    "condition": "Used",
    "id": 3913,
    "master_id": 3912,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12500T",
    "name": "Intel Core i5-12500T",
    "condition": "Used With Box",
    "id": 3914,
    "master_id": 3912,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12500T",
    "name": "Intel Core i5-12500T",
    "condition": "Brand New Sealed",
    "id": 3915,
    "master_id": 3912,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12600K",
    "name": "Intel Core i5-12600K",
    "condition": "Used",
    "id": 2374,
    "master_id": 1928,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12600K",
    "name": "Intel Core i5-12600K",
    "condition": "Used With Box",
    "id": 2375,
    "master_id": 1928,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-12600K",
    "name": "Intel Core i5-12600K",
    "condition": "Brand New Sealed",
    "id": 2376,
    "master_id": 1928,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400",
    "name": "Intel Core i5-13400",
    "condition": "Used",
    "id": 2377,
    "master_id": 1929,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400",
    "name": "Intel Core i5-13400",
    "condition": "Used With Box",
    "id": 2378,
    "master_id": 1929,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400",
    "name": "Intel Core i5-13400",
    "condition": "Brand New Sealed",
    "id": 2379,
    "master_id": 1929,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400F",
    "name": "Intel Core i5-13400F",
    "condition": "Used",
    "id": 2380,
    "master_id": 1930,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400F",
    "name": "Intel Core i5-13400F",
    "condition": "Used With Box",
    "id": 2381,
    "master_id": 1930,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400F",
    "name": "Intel Core i5-13400F",
    "condition": "Brand New Sealed",
    "id": 2382,
    "master_id": 1930,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400T",
    "name": "Intel Core i5-13400T",
    "condition": "Used",
    "id": 2383,
    "master_id": 1931,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400T",
    "name": "Intel Core i5-13400T",
    "condition": "Used With Box",
    "id": 2384,
    "master_id": 1931,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13400T",
    "name": "Intel Core i5-13400T",
    "condition": "Brand New Sealed",
    "id": 2385,
    "master_id": 1931,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13490F",
    "name": "Intel Core i5-13490F",
    "condition": "Used",
    "id": 2386,
    "master_id": 1932,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13490F",
    "name": "Intel Core i5-13490F",
    "condition": "Used With Box",
    "id": 2387,
    "master_id": 1932,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13490F",
    "name": "Intel Core i5-13490F",
    "condition": "Brand New Sealed",
    "id": 2388,
    "master_id": 1932,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13500",
    "name": "Intel Core i5-13500",
    "condition": "Used",
    "id": 2389,
    "master_id": 1933,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13500",
    "name": "Intel Core i5-13500",
    "condition": "Used With Box",
    "id": 2390,
    "master_id": 1933,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13500",
    "name": "Intel Core i5-13500",
    "condition": "Brand New Sealed",
    "id": 2391,
    "master_id": 1933,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13500T",
    "name": "Intel Core i5-13500T",
    "condition": "Used",
    "id": 2392,
    "master_id": 1934,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13500T",
    "name": "Intel Core i5-13500T",
    "condition": "Used With Box",
    "id": 2393,
    "master_id": 1934,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13500T",
    "name": "Intel Core i5-13500T",
    "condition": "Brand New Sealed",
    "id": 2394,
    "master_id": 1934,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600",
    "name": "Intel Core i5-13600",
    "condition": "Used",
    "id": 2395,
    "master_id": 1935,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600",
    "name": "Intel Core i5-13600",
    "condition": "Used With Box",
    "id": 2396,
    "master_id": 1935,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600",
    "name": "Intel Core i5-13600",
    "condition": "Brand New Sealed",
    "id": 2397,
    "master_id": 1935,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600T",
    "name": "Intel Core i5-13600T",
    "condition": "Used",
    "id": 6191,
    "master_id": 6176,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600T",
    "name": "Intel Core i5-13600T",
    "condition": "Used With Box",
    "id": 6192,
    "master_id": 6176,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600T",
    "name": "Intel Core i5-13600T",
    "condition": "Brand New Sealed",
    "id": 6193,
    "master_id": 6176,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600K",
    "name": "Intel Core i5-13600K",
    "condition": "Used",
    "id": 2398,
    "master_id": 1936,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600K",
    "name": "Intel Core i5-13600K",
    "condition": "Used With Box",
    "id": 2399,
    "master_id": 1936,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600K",
    "name": "Intel Core i5-13600K",
    "condition": "Brand New Sealed",
    "id": 2400,
    "master_id": 1936,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600KF",
    "name": "Intel Core i5-13600KF",
    "condition": "Used",
    "id": 2401,
    "master_id": 1937,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600KF",
    "name": "Intel Core i5-13600KF",
    "condition": "Used With Box",
    "id": 2402,
    "master_id": 1937,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-13600KF",
    "name": "Intel Core i5-13600KF",
    "condition": "Brand New Sealed",
    "id": 2403,
    "master_id": 1937,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400",
    "name": "Intel Core i5-14400",
    "condition": "Used",
    "id": 6232,
    "master_id": 6210,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400",
    "name": "Intel Core i5-14400",
    "condition": "Used With Box",
    "id": 6236,
    "master_id": 6210,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400",
    "name": "Intel Core i5-14400",
    "condition": "Brand New Sealed",
    "id": 6237,
    "master_id": 6210,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400T",
    "name": "Intel Core i5-14400T",
    "condition": "Used",
    "id": 6226,
    "master_id": 6208,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400T",
    "name": "Intel Core i5-14400T",
    "condition": "Used With Box",
    "id": 6227,
    "master_id": 6208,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400T",
    "name": "Intel Core i5-14400T",
    "condition": "Brand New Sealed",
    "id": 6228,
    "master_id": 6208,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400F",
    "name": "Intel Core i5-14400F",
    "condition": "Used",
    "id": 6229,
    "master_id": 6209,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400F",
    "name": "Intel Core i5-14400F",
    "condition": "Used With Box",
    "id": 6230,
    "master_id": 6209,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14400F",
    "name": "Intel Core i5-14400F",
    "condition": "Brand New Sealed",
    "id": 6231,
    "master_id": 6209,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14490F",
    "name": "Intel Core i5-14490F",
    "condition": "Used",
    "id": 6223,
    "master_id": 6207,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14490F",
    "name": "Intel Core i5-14490F",
    "condition": "Used With Box",
    "id": 6224,
    "master_id": 6207,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14490F",
    "name": "Intel Core i5-14490F",
    "condition": "Brand New Sealed",
    "id": 6225,
    "master_id": 6207,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14500",
    "name": "Intel Core i5-14500",
    "condition": "Used",
    "id": 6169,
    "master_id": 6149,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14500",
    "name": "Intel Core i5-14500",
    "condition": "Used With Box",
    "id": 6170,
    "master_id": 6149,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14500",
    "name": "Intel Core i5-14500",
    "condition": "Brand New Sealed",
    "id": 6171,
    "master_id": 6149,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14500T",
    "name": "Intel Core i5-14500T",
    "condition": "Used",
    "id": 6220,
    "master_id": 6206,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14500T",
    "name": "Intel Core i5-14500T",
    "condition": "Used With Box",
    "id": 6221,
    "master_id": 6206,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14500T",
    "name": "Intel Core i5-14500T",
    "condition": "Brand New Sealed",
    "id": 6222,
    "master_id": 6206,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600",
    "name": "Intel Core i5-14600",
    "condition": "Used",
    "id": 6185,
    "master_id": 6174,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600",
    "name": "Intel Core i5-14600",
    "condition": "Used With Box",
    "id": 6186,
    "master_id": 6174,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600",
    "name": "Intel Core i5-14600",
    "condition": "Brand New Sealed",
    "id": 6187,
    "master_id": 6174,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600KF",
    "name": "Intel Core i5-14600KF",
    "condition": "Used",
    "id": 6182,
    "master_id": 6173,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600KF",
    "name": "Intel Core i5-14600KF",
    "condition": "Used With Box",
    "id": 6183,
    "master_id": 6173,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600KF",
    "name": "Intel Core i5-14600KF",
    "condition": "Brand New Sealed",
    "id": 6184,
    "master_id": 6173,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600K",
    "name": "Intel Core i5-14600K",
    "condition": "Used",
    "id": 6172,
    "master_id": 6150,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600K",
    "name": "Intel Core i5-14600K",
    "condition": "Used With Box",
    "id": 6180,
    "master_id": 6150,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600K",
    "name": "Intel Core i5-14600K",
    "condition": "Brand New Sealed",
    "id": 6181,
    "master_id": 6150,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600T",
    "name": "Intel Core i5-14600T",
    "condition": "Used",
    "id": 6217,
    "master_id": 6205,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600T",
    "name": "Intel Core i5-14600T",
    "condition": "Used With Box",
    "id": 6218,
    "master_id": 6205,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i5",
    "model": "i5-14600T",
    "name": "Intel Core i5-14600T",
    "condition": "Brand New Sealed",
    "id": 6219,
    "master_id": 6205,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600",
    "name": "Intel Core i7-2600",
    "condition": "Used",
    "id": 2404,
    "master_id": 1938,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600",
    "name": "Intel Core i7-2600",
    "condition": "Used With Box",
    "id": 2405,
    "master_id": 1938,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600",
    "name": "Intel Core i7-2600",
    "condition": "Brand New Sealed",
    "id": 2406,
    "master_id": 1938,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600K",
    "name": "Intel Core i7-2600K",
    "condition": "Used",
    "id": 2407,
    "master_id": 1939,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600K",
    "name": "Intel Core i7-2600K",
    "condition": "Used With Box",
    "id": 2408,
    "master_id": 1939,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600K",
    "name": "Intel Core i7-2600K",
    "condition": "Brand New Sealed",
    "id": 2409,
    "master_id": 1939,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600S",
    "name": "Intel Core i7-2600S",
    "condition": "Used",
    "id": 2410,
    "master_id": 1940,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600S",
    "name": "Intel Core i7-2600S",
    "condition": "Used With Box",
    "id": 2411,
    "master_id": 1940,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2600S",
    "name": "Intel Core i7-2600S",
    "condition": "Brand New Sealed",
    "id": 2412,
    "master_id": 1940,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2700K",
    "name": "Intel Core i7-2700K",
    "condition": "Used",
    "id": 2413,
    "master_id": 1941,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2700K",
    "name": "Intel Core i7-2700K",
    "condition": "Used With Box",
    "id": 2414,
    "master_id": 1941,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-2700K",
    "name": "Intel Core i7-2700K",
    "condition": "Brand New Sealed",
    "id": 2415,
    "master_id": 1941,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770",
    "name": "Intel Core i7-3770",
    "condition": "Used",
    "id": 2416,
    "master_id": 1942,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770",
    "name": "Intel Core i7-3770",
    "condition": "Used With Box",
    "id": 2417,
    "master_id": 1942,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770",
    "name": "Intel Core i7-3770",
    "condition": "Brand New Sealed",
    "id": 2418,
    "master_id": 1942,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770K",
    "name": "Intel Core i7-3770K",
    "condition": "Used",
    "id": 2419,
    "master_id": 1943,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770K",
    "name": "Intel Core i7-3770K",
    "condition": "Used With Box",
    "id": 2420,
    "master_id": 1943,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770K",
    "name": "Intel Core i7-3770K",
    "condition": "Brand New Sealed",
    "id": 2421,
    "master_id": 1943,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770S",
    "name": "Intel Core i7-3770S",
    "condition": "Used",
    "id": 2422,
    "master_id": 1944,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770S",
    "name": "Intel Core i7-3770S",
    "condition": "Used With Box",
    "id": 2423,
    "master_id": 1944,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770S",
    "name": "Intel Core i7-3770S",
    "condition": "Brand New Sealed",
    "id": 2424,
    "master_id": 1944,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770T",
    "name": "Intel Core i7-3770T",
    "condition": "Used",
    "id": 2425,
    "master_id": 1945,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770T",
    "name": "Intel Core i7-3770T",
    "condition": "Used With Box",
    "id": 2426,
    "master_id": 1945,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3770T",
    "name": "Intel Core i7-3770T",
    "condition": "Brand New Sealed",
    "id": 2427,
    "master_id": 1945,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3820",
    "name": "Intel Core i7-3820",
    "condition": "Used",
    "id": 2428,
    "master_id": 1946,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3820",
    "name": "Intel Core i7-3820",
    "condition": "Used With Box",
    "id": 2429,
    "master_id": 1946,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3820",
    "name": "Intel Core i7-3820",
    "condition": "Brand New Sealed",
    "id": 2430,
    "master_id": 1946,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3930K",
    "name": "Intel Core i7-3930K",
    "condition": "Used",
    "id": 2431,
    "master_id": 1947,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3930K",
    "name": "Intel Core i7-3930K",
    "condition": "Used With Box",
    "id": 2432,
    "master_id": 1947,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3930K",
    "name": "Intel Core i7-3930K",
    "condition": "Brand New Sealed",
    "id": 2433,
    "master_id": 1947,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3960X",
    "name": "Intel Core i7-3960X",
    "condition": "Used",
    "id": 2434,
    "master_id": 1948,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3960X",
    "name": "Intel Core i7-3960X",
    "condition": "Used With Box",
    "id": 2435,
    "master_id": 1948,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3960X",
    "name": "Intel Core i7-3960X",
    "condition": "Brand New Sealed",
    "id": 2436,
    "master_id": 1948,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3970X",
    "name": "Intel Core i7-3970X",
    "condition": "Used",
    "id": 2437,
    "master_id": 1949,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3970X",
    "name": "Intel Core i7-3970X",
    "condition": "Used With Box",
    "id": 2438,
    "master_id": 1949,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-3970X",
    "name": "Intel Core i7-3970X",
    "condition": "Brand New Sealed",
    "id": 2439,
    "master_id": 1949,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4765T",
    "name": "Intel Core i7-4765T",
    "condition": "Used",
    "id": 2440,
    "master_id": 1950,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4765T",
    "name": "Intel Core i7-4765T",
    "condition": "Used With Box",
    "id": 2441,
    "master_id": 1950,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4765T",
    "name": "Intel Core i7-4765T",
    "condition": "Brand New Sealed",
    "id": 2442,
    "master_id": 1950,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770",
    "name": "Intel Core i7-4770",
    "condition": "Used",
    "id": 2443,
    "master_id": 1951,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770",
    "name": "Intel Core i7-4770",
    "condition": "Used With Box",
    "id": 2444,
    "master_id": 1951,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770",
    "name": "Intel Core i7-4770",
    "condition": "Brand New Sealed",
    "id": 2445,
    "master_id": 1951,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770K",
    "name": "Intel Core i7-4770K",
    "condition": "Used",
    "id": 2446,
    "master_id": 1952,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770K",
    "name": "Intel Core i7-4770K",
    "condition": "Used With Box",
    "id": 2447,
    "master_id": 1952,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770K",
    "name": "Intel Core i7-4770K",
    "condition": "Brand New Sealed",
    "id": 2448,
    "master_id": 1952,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770R",
    "name": "Intel Core i7-4770R",
    "condition": "Used",
    "id": 2449,
    "master_id": 1953,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770R",
    "name": "Intel Core i7-4770R",
    "condition": "Used With Box",
    "id": 2450,
    "master_id": 1953,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770R",
    "name": "Intel Core i7-4770R",
    "condition": "Brand New Sealed",
    "id": 2451,
    "master_id": 1953,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770S",
    "name": "Intel Core i7-4770S",
    "condition": "Used",
    "id": 2452,
    "master_id": 1954,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770S",
    "name": "Intel Core i7-4770S",
    "condition": "Used With Box",
    "id": 2453,
    "master_id": 1954,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770S",
    "name": "Intel Core i7-4770S",
    "condition": "Brand New Sealed",
    "id": 2454,
    "master_id": 1954,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770T",
    "name": "Intel Core i7-4770T",
    "condition": "Used",
    "id": 2455,
    "master_id": 1955,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770T",
    "name": "Intel Core i7-4770T",
    "condition": "Used With Box",
    "id": 2456,
    "master_id": 1955,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770T",
    "name": "Intel Core i7-4770T",
    "condition": "Brand New Sealed",
    "id": 2457,
    "master_id": 1955,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770TE",
    "name": "Intel Core i7-4770TE",
    "condition": "Used",
    "id": 2458,
    "master_id": 1956,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770TE",
    "name": "Intel Core i7-4770TE",
    "condition": "Used With Box",
    "id": 2459,
    "master_id": 1956,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4770TE",
    "name": "Intel Core i7-4770TE",
    "condition": "Brand New Sealed",
    "id": 2460,
    "master_id": 1956,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4771",
    "name": "Intel Core i7-4771",
    "condition": "Used",
    "id": 2461,
    "master_id": 1957,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4771",
    "name": "Intel Core i7-4771",
    "condition": "Used With Box",
    "id": 2462,
    "master_id": 1957,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4771",
    "name": "Intel Core i7-4771",
    "condition": "Brand New Sealed",
    "id": 2463,
    "master_id": 1957,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4785T",
    "name": "Intel Core i7-4785T",
    "condition": "Used",
    "id": 2464,
    "master_id": 1958,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4785T",
    "name": "Intel Core i7-4785T",
    "condition": "Used With Box",
    "id": 2465,
    "master_id": 1958,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4785T",
    "name": "Intel Core i7-4785T",
    "condition": "Brand New Sealed",
    "id": 2466,
    "master_id": 1958,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790",
    "name": "Intel Core i7-4790",
    "condition": "Used",
    "id": 2467,
    "master_id": 1959,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790",
    "name": "Intel Core i7-4790",
    "condition": "Used With Box",
    "id": 2468,
    "master_id": 1959,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790",
    "name": "Intel Core i7-4790",
    "condition": "Brand New Sealed",
    "id": 2469,
    "master_id": 1959,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790K",
    "name": "Intel Core i7-4790K",
    "condition": "Used",
    "id": 2470,
    "master_id": 1960,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790K",
    "name": "Intel Core i7-4790K",
    "condition": "Used With Box",
    "id": 2471,
    "master_id": 1960,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790K",
    "name": "Intel Core i7-4790K",
    "condition": "Brand New Sealed",
    "id": 2472,
    "master_id": 1960,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790S",
    "name": "Intel Core i7-4790S",
    "condition": "Used",
    "id": 2473,
    "master_id": 1961,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790S",
    "name": "Intel Core i7-4790S",
    "condition": "Used With Box",
    "id": 2474,
    "master_id": 1961,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790S",
    "name": "Intel Core i7-4790S",
    "condition": "Brand New Sealed",
    "id": 2475,
    "master_id": 1961,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790T",
    "name": "Intel Core i7-4790T",
    "condition": "Used",
    "id": 2476,
    "master_id": 1962,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790T",
    "name": "Intel Core i7-4790T",
    "condition": "Used With Box",
    "id": 2477,
    "master_id": 1962,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4790T",
    "name": "Intel Core i7-4790T",
    "condition": "Brand New Sealed",
    "id": 2478,
    "master_id": 1962,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4820K",
    "name": "Intel Core i7-4820K",
    "condition": "Used",
    "id": 2479,
    "master_id": 1963,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4820K",
    "name": "Intel Core i7-4820K",
    "condition": "Used With Box",
    "id": 2480,
    "master_id": 1963,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4820K",
    "name": "Intel Core i7-4820K",
    "condition": "Brand New Sealed",
    "id": 2481,
    "master_id": 1963,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4930K",
    "name": "Intel Core i7-4930K",
    "condition": "Used",
    "id": 2482,
    "master_id": 1964,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4930K",
    "name": "Intel Core i7-4930K",
    "condition": "Used With Box",
    "id": 2483,
    "master_id": 1964,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4930K",
    "name": "Intel Core i7-4930K",
    "condition": "Brand New Sealed",
    "id": 2484,
    "master_id": 1964,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4960X",
    "name": "Intel Core i7-4960X",
    "condition": "Used",
    "id": 2485,
    "master_id": 1965,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4960X",
    "name": "Intel Core i7-4960X",
    "condition": "Used With Box",
    "id": 2486,
    "master_id": 1965,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-4960X",
    "name": "Intel Core i7-4960X",
    "condition": "Brand New Sealed",
    "id": 2487,
    "master_id": 1965,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5775C",
    "name": "Intel Core i7-5775C",
    "condition": "Used",
    "id": 2488,
    "master_id": 1966,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5775C",
    "name": "Intel Core i7-5775C",
    "condition": "Used With Box",
    "id": 2489,
    "master_id": 1966,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5775C",
    "name": "Intel Core i7-5775C",
    "condition": "Brand New Sealed",
    "id": 2490,
    "master_id": 1966,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5820K",
    "name": "Intel Core i7-5820K",
    "condition": "Used",
    "id": 2491,
    "master_id": 1967,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5820K",
    "name": "Intel Core i7-5820K",
    "condition": "Used With Box",
    "id": 2492,
    "master_id": 1967,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5820K",
    "name": "Intel Core i7-5820K",
    "condition": "Brand New Sealed",
    "id": 2493,
    "master_id": 1967,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5930K",
    "name": "Intel Core i7-5930K",
    "condition": "Used",
    "id": 2494,
    "master_id": 1968,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5930K",
    "name": "Intel Core i7-5930K",
    "condition": "Used With Box",
    "id": 2495,
    "master_id": 1968,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5930K",
    "name": "Intel Core i7-5930K",
    "condition": "Brand New Sealed",
    "id": 2496,
    "master_id": 1968,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5960X",
    "name": "Intel Core i7-5960X",
    "condition": "Used",
    "id": 2497,
    "master_id": 1969,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5960X",
    "name": "Intel Core i7-5960X",
    "condition": "Used With Box",
    "id": 2498,
    "master_id": 1969,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-5960X",
    "name": "Intel Core i7-5960X",
    "condition": "Brand New Sealed",
    "id": 2499,
    "master_id": 1969,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700",
    "name": "Intel Core i7-6700",
    "condition": "Used",
    "id": 2500,
    "master_id": 1970,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700",
    "name": "Intel Core i7-6700",
    "condition": "Used With Box",
    "id": 2501,
    "master_id": 1970,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700",
    "name": "Intel Core i7-6700",
    "condition": "Brand New Sealed",
    "id": 2502,
    "master_id": 1970,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700K",
    "name": "Intel Core i7-6700K",
    "condition": "Used",
    "id": 2503,
    "master_id": 1971,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700K",
    "name": "Intel Core i7-6700K",
    "condition": "Used With Box",
    "id": 2504,
    "master_id": 1971,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700K",
    "name": "Intel Core i7-6700K",
    "condition": "Brand New Sealed",
    "id": 2505,
    "master_id": 1971,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700T",
    "name": "Intel Core i7-6700T",
    "condition": "Used",
    "id": 2506,
    "master_id": 1972,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700T",
    "name": "Intel Core i7-6700T",
    "condition": "Used With Box",
    "id": 2507,
    "master_id": 1972,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700T",
    "name": "Intel Core i7-6700T",
    "condition": "Brand New Sealed",
    "id": 2508,
    "master_id": 1972,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700TE",
    "name": "Intel Core i7-6700TE",
    "condition": "Used",
    "id": 2509,
    "master_id": 1973,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700TE",
    "name": "Intel Core i7-6700TE",
    "condition": "Used With Box",
    "id": 2510,
    "master_id": 1973,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6700TE",
    "name": "Intel Core i7-6700TE",
    "condition": "Brand New Sealed",
    "id": 2511,
    "master_id": 1973,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6800K",
    "name": "Intel Core i7-6800K",
    "condition": "Used",
    "id": 2512,
    "master_id": 1974,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6800K",
    "name": "Intel Core i7-6800K",
    "condition": "Used With Box",
    "id": 2513,
    "master_id": 1974,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6800K",
    "name": "Intel Core i7-6800K",
    "condition": "Brand New Sealed",
    "id": 2514,
    "master_id": 1974,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6850K",
    "name": "Intel Core i7-6850K",
    "condition": "Used",
    "id": 2515,
    "master_id": 1975,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6850K",
    "name": "Intel Core i7-6850K",
    "condition": "Used With Box",
    "id": 2516,
    "master_id": 1975,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6850K",
    "name": "Intel Core i7-6850K",
    "condition": "Brand New Sealed",
    "id": 2517,
    "master_id": 1975,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6900K",
    "name": "Intel Core i7-6900K",
    "condition": "Used",
    "id": 2518,
    "master_id": 1976,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6900K",
    "name": "Intel Core i7-6900K",
    "condition": "Used With Box",
    "id": 2519,
    "master_id": 1976,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6900K",
    "name": "Intel Core i7-6900K",
    "condition": "Brand New Sealed",
    "id": 2520,
    "master_id": 1976,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6950X",
    "name": "Intel Core i7-6950X",
    "condition": "Used",
    "id": 2521,
    "master_id": 1977,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6950X",
    "name": "Intel Core i7-6950X",
    "condition": "Used With Box",
    "id": 2522,
    "master_id": 1977,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-6950X",
    "name": "Intel Core i7-6950X",
    "condition": "Brand New Sealed",
    "id": 2523,
    "master_id": 1977,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700",
    "name": "Intel Core i7-7700",
    "condition": "Used",
    "id": 2524,
    "master_id": 1978,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700",
    "name": "Intel Core i7-7700",
    "condition": "Used With Box",
    "id": 2525,
    "master_id": 1978,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700",
    "name": "Intel Core i7-7700",
    "condition": "Brand New Sealed",
    "id": 2526,
    "master_id": 1978,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700K",
    "name": "Intel Core i7-7700K",
    "condition": "Used",
    "id": 2527,
    "master_id": 1979,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700K",
    "name": "Intel Core i7-7700K",
    "condition": "Used With Box",
    "id": 2528,
    "master_id": 1979,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700K",
    "name": "Intel Core i7-7700K",
    "condition": "Brand New Sealed",
    "id": 2529,
    "master_id": 1979,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700T",
    "name": "Intel Core i7-7700T",
    "condition": "Used",
    "id": 2530,
    "master_id": 1980,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700T",
    "name": "Intel Core i7-7700T",
    "condition": "Used With Box",
    "id": 2531,
    "master_id": 1980,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7700T",
    "name": "Intel Core i7-7700T",
    "condition": "Brand New Sealed",
    "id": 2532,
    "master_id": 1980,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7740X",
    "name": "Intel Core i7-7740X",
    "condition": "Used",
    "id": 2533,
    "master_id": 1981,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7740X",
    "name": "Intel Core i7-7740X",
    "condition": "Used With Box",
    "id": 2534,
    "master_id": 1981,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7740X",
    "name": "Intel Core i7-7740X",
    "condition": "Brand New Sealed",
    "id": 2535,
    "master_id": 1981,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7800X",
    "name": "Intel Core i7-7800X",
    "condition": "Used",
    "id": 2536,
    "master_id": 1982,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7800X",
    "name": "Intel Core i7-7800X",
    "condition": "Used With Box",
    "id": 2537,
    "master_id": 1982,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7800X",
    "name": "Intel Core i7-7800X",
    "condition": "Brand New Sealed",
    "id": 2538,
    "master_id": 1982,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7820X",
    "name": "Intel Core i7-7820X",
    "condition": "Used",
    "id": 2539,
    "master_id": 1983,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7820X",
    "name": "Intel Core i7-7820X",
    "condition": "Used With Box",
    "id": 2540,
    "master_id": 1983,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-7820X",
    "name": "Intel Core i7-7820X",
    "condition": "Brand New Sealed",
    "id": 2541,
    "master_id": 1983,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8086",
    "name": "Intel Core i7-8086",
    "condition": "Used",
    "id": 2542,
    "master_id": 1984,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8086",
    "name": "Intel Core i7-8086",
    "condition": "Used With Box",
    "id": 2543,
    "master_id": 1984,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8086",
    "name": "Intel Core i7-8086",
    "condition": "Brand New Sealed",
    "id": 2544,
    "master_id": 1984,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8086K",
    "name": "Intel Core i7-8086K",
    "condition": "Used",
    "id": 2545,
    "master_id": 1985,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8086K",
    "name": "Intel Core i7-8086K",
    "condition": "Used With Box",
    "id": 2546,
    "master_id": 1985,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8086K",
    "name": "Intel Core i7-8086K",
    "condition": "Brand New Sealed",
    "id": 2547,
    "master_id": 1985,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700",
    "name": "Intel Core i7-8700",
    "condition": "Used",
    "id": 2548,
    "master_id": 1986,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700",
    "name": "Intel Core i7-8700",
    "condition": "Used With Box",
    "id": 2549,
    "master_id": 1986,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700",
    "name": "Intel Core i7-8700",
    "condition": "Brand New Sealed",
    "id": 2550,
    "master_id": 1986,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700K",
    "name": "Intel Core i7-8700K",
    "condition": "Used",
    "id": 2551,
    "master_id": 1987,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700K",
    "name": "Intel Core i7-8700K",
    "condition": "Used With Box",
    "id": 2552,
    "master_id": 1987,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700K",
    "name": "Intel Core i7-8700K",
    "condition": "Brand New Sealed",
    "id": 2553,
    "master_id": 1987,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700T",
    "name": "Intel Core i7-8700T",
    "condition": "Used",
    "id": 2554,
    "master_id": 1988,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700T",
    "name": "Intel Core i7-8700T",
    "condition": "Used With Box",
    "id": 2555,
    "master_id": 1988,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-8700T",
    "name": "Intel Core i7-8700T",
    "condition": "Brand New Sealed",
    "id": 2556,
    "master_id": 1988,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700",
    "name": "Intel Core i7-9700",
    "condition": "Used",
    "id": 2557,
    "master_id": 1989,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700",
    "name": "Intel Core i7-9700",
    "condition": "Used With Box",
    "id": 2558,
    "master_id": 1989,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700",
    "name": "Intel Core i7-9700",
    "condition": "Brand New Sealed",
    "id": 2559,
    "master_id": 1989,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700E",
    "name": "Intel Core i7-9700E",
    "condition": "Used",
    "id": 2560,
    "master_id": 1990,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700E",
    "name": "Intel Core i7-9700E",
    "condition": "Used With Box",
    "id": 2561,
    "master_id": 1990,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700E",
    "name": "Intel Core i7-9700E",
    "condition": "Brand New Sealed",
    "id": 2562,
    "master_id": 1990,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700F",
    "name": "Intel Core i7-9700F",
    "condition": "Used",
    "id": 2563,
    "master_id": 1991,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700F",
    "name": "Intel Core i7-9700F",
    "condition": "Used With Box",
    "id": 2564,
    "master_id": 1991,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700F",
    "name": "Intel Core i7-9700F",
    "condition": "Brand New Sealed",
    "id": 2565,
    "master_id": 1991,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700K",
    "name": "Intel Core i7-9700K",
    "condition": "Used",
    "id": 2566,
    "master_id": 1992,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700K",
    "name": "Intel Core i7-9700K",
    "condition": "Used With Box",
    "id": 2567,
    "master_id": 1992,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700K",
    "name": "Intel Core i7-9700K",
    "condition": "Brand New Sealed",
    "id": 2568,
    "master_id": 1992,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700KF",
    "name": "Intel Core i7-9700KF",
    "condition": "Used",
    "id": 2569,
    "master_id": 1993,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700KF",
    "name": "Intel Core i7-9700KF",
    "condition": "Used With Box",
    "id": 2570,
    "master_id": 1993,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700KF",
    "name": "Intel Core i7-9700KF",
    "condition": "Brand New Sealed",
    "id": 2571,
    "master_id": 1993,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700T",
    "name": "Intel Core i7-9700T",
    "condition": "Used",
    "id": 2572,
    "master_id": 1994,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700T",
    "name": "Intel Core i7-9700T",
    "condition": "Used With Box",
    "id": 2573,
    "master_id": 1994,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700T",
    "name": "Intel Core i7-9700T",
    "condition": "Brand New Sealed",
    "id": 2574,
    "master_id": 1994,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700TE",
    "name": "Intel Core i7-9700TE",
    "condition": "Used",
    "id": 2575,
    "master_id": 1995,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700TE",
    "name": "Intel Core i7-9700TE",
    "condition": "Used With Box",
    "id": 2576,
    "master_id": 1995,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9700TE",
    "name": "Intel Core i7-9700TE",
    "condition": "Brand New Sealed",
    "id": 2577,
    "master_id": 1995,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9800X",
    "name": "Intel Core i7-9800X",
    "condition": "Used",
    "id": 2578,
    "master_id": 1996,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9800X",
    "name": "Intel Core i7-9800X",
    "condition": "Used With Box",
    "id": 2579,
    "master_id": 1996,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-9800X",
    "name": "Intel Core i7-9800X",
    "condition": "Brand New Sealed",
    "id": 2580,
    "master_id": 1996,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700",
    "name": "Intel Core i7-10700",
    "condition": "Used",
    "id": 2581,
    "master_id": 1997,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700",
    "name": "Intel Core i7-10700",
    "condition": "Used With Box",
    "id": 2582,
    "master_id": 1997,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700",
    "name": "Intel Core i7-10700",
    "condition": "Brand New Sealed",
    "id": 2583,
    "master_id": 1997,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700E",
    "name": "Intel Core i7-10700E",
    "condition": "Used",
    "id": 2584,
    "master_id": 1998,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700E",
    "name": "Intel Core i7-10700E",
    "condition": "Used With Box",
    "id": 2585,
    "master_id": 1998,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700E",
    "name": "Intel Core i7-10700E",
    "condition": "Brand New Sealed",
    "id": 2586,
    "master_id": 1998,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700F",
    "name": "Intel Core i7-10700F",
    "condition": "Used",
    "id": 2587,
    "master_id": 1999,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700F",
    "name": "Intel Core i7-10700F",
    "condition": "Used With Box",
    "id": 2588,
    "master_id": 1999,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700F",
    "name": "Intel Core i7-10700F",
    "condition": "Brand New Sealed",
    "id": 2589,
    "master_id": 1999,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700K",
    "name": "Intel Core i7-10700K",
    "condition": "Used",
    "id": 2590,
    "master_id": 2000,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700K",
    "name": "Intel Core i7-10700K",
    "condition": "Used With Box",
    "id": 2591,
    "master_id": 2000,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700K",
    "name": "Intel Core i7-10700K",
    "condition": "Brand New Sealed",
    "id": 2592,
    "master_id": 2000,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700KF",
    "name": "Intel Core i7-10700KF",
    "condition": "Used",
    "id": 2593,
    "master_id": 2001,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700KF",
    "name": "Intel Core i7-10700KF",
    "condition": "Used With Box",
    "id": 2594,
    "master_id": 2001,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700KF",
    "name": "Intel Core i7-10700KF",
    "condition": "Brand New Sealed",
    "id": 2595,
    "master_id": 2001,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700T",
    "name": "Intel Core i7-10700T",
    "condition": "Used",
    "id": 2596,
    "master_id": 2002,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700T",
    "name": "Intel Core i7-10700T",
    "condition": "Used With Box",
    "id": 2597,
    "master_id": 2002,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700T",
    "name": "Intel Core i7-10700T",
    "condition": "Brand New Sealed",
    "id": 2598,
    "master_id": 2002,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700TE",
    "name": "Intel Core i7-10700TE",
    "condition": "Used",
    "id": 2599,
    "master_id": 2003,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700TE",
    "name": "Intel Core i7-10700TE",
    "condition": "Used With Box",
    "id": 2600,
    "master_id": 2003,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-10700TE",
    "name": "Intel Core i7-10700TE",
    "condition": "Brand New Sealed",
    "id": 2601,
    "master_id": 2003,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700",
    "name": "Intel Core i7-11700",
    "condition": "Used",
    "id": 2602,
    "master_id": 2004,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700",
    "name": "Intel Core i7-11700",
    "condition": "Used With Box",
    "id": 2603,
    "master_id": 2004,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700",
    "name": "Intel Core i7-11700",
    "condition": "Brand New Sealed",
    "id": 2604,
    "master_id": 2004,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700F",
    "name": "Intel Core i7-11700F",
    "condition": "Used",
    "id": 2605,
    "master_id": 2005,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700F",
    "name": "Intel Core i7-11700F",
    "condition": "Used With Box",
    "id": 2606,
    "master_id": 2005,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700F",
    "name": "Intel Core i7-11700F",
    "condition": "Brand New Sealed",
    "id": 2607,
    "master_id": 2005,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700K",
    "name": "Intel Core i7-11700K",
    "condition": "Used",
    "id": 2608,
    "master_id": 2006,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700K",
    "name": "Intel Core i7-11700K",
    "condition": "Used With Box",
    "id": 2609,
    "master_id": 2006,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-11700K",
    "name": "Intel Core i7-11700K",
    "condition": "Brand New Sealed",
    "id": 2610,
    "master_id": 2006,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700K",
    "name": "Intel Core i7-12700K",
    "condition": "Used",
    "id": 2611,
    "master_id": 2007,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700K",
    "name": "Intel Core i7-12700K",
    "condition": "Used With Box",
    "id": 2612,
    "master_id": 2007,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700K",
    "name": "Intel Core i7-12700K",
    "condition": "Brand New Sealed",
    "id": 2613,
    "master_id": 2007,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700F",
    "name": "Intel Core i7-12700F",
    "condition": "Used",
    "id": 3651,
    "master_id": 3650,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700F",
    "name": "Intel Core i7-12700F",
    "condition": "Used With Box",
    "id": 3652,
    "master_id": 3650,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700F",
    "name": "Intel Core i7-12700F",
    "condition": "Brand New Sealed",
    "id": 3653,
    "master_id": 3650,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700T",
    "name": "Intel Core i7-12700T",
    "condition": "Used",
    "id": 3647,
    "master_id": 3646,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700T",
    "name": "Intel Core i7-12700T",
    "condition": "Used With Box",
    "id": 3648,
    "master_id": 3646,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700T",
    "name": "Intel Core i7-12700T",
    "condition": "Brand New Sealed",
    "id": 3649,
    "master_id": 3646,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700KF",
    "name": "Intel Core i7-12700KF",
    "condition": "Used",
    "id": 2614,
    "master_id": 2008,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700KF",
    "name": "Intel Core i7-12700KF",
    "condition": "Used With Box",
    "id": 2615,
    "master_id": 2008,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-12700KF",
    "name": "Intel Core i7-12700KF",
    "condition": "Brand New Sealed",
    "id": 2616,
    "master_id": 2008,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700",
    "name": "Intel Core i7-13700",
    "condition": "Used",
    "id": 2617,
    "master_id": 2009,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700",
    "name": "Intel Core i7-13700",
    "condition": "Used With Box",
    "id": 2618,
    "master_id": 2009,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700",
    "name": "Intel Core i7-13700",
    "condition": "Brand New Sealed",
    "id": 2619,
    "master_id": 2009,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700K",
    "name": "Intel Core i7-13700K",
    "condition": "Used",
    "id": 2620,
    "master_id": 2010,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700K",
    "name": "Intel Core i7-13700K",
    "condition": "Used With Box",
    "id": 2621,
    "master_id": 2010,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700K",
    "name": "Intel Core i7-13700K",
    "condition": "Brand New Sealed",
    "id": 2622,
    "master_id": 2010,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700KF",
    "name": "Intel Core i7-13700KF",
    "condition": "Used",
    "id": 2623,
    "master_id": 2011,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700KF",
    "name": "Intel Core i7-13700KF",
    "condition": "Used With Box",
    "id": 2624,
    "master_id": 2011,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700KF",
    "name": "Intel Core i7-13700KF",
    "condition": "Brand New Sealed",
    "id": 2625,
    "master_id": 2011,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700F",
    "name": "Intel Core i7-13700F",
    "condition": "Used",
    "id": 2626,
    "master_id": 2012,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700F",
    "name": "Intel Core i7-13700F",
    "condition": "Used With Box",
    "id": 2627,
    "master_id": 2012,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700F",
    "name": "Intel Core i7-13700F",
    "condition": "Brand New Sealed",
    "id": 2628,
    "master_id": 2012,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700T",
    "name": "Intel Core i7-13700T",
    "condition": "Used",
    "id": 2629,
    "master_id": 2013,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700T",
    "name": "Intel Core i7-13700T",
    "condition": "Used With Box",
    "id": 2630,
    "master_id": 2013,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13700T",
    "name": "Intel Core i7-13700T",
    "condition": "Brand New Sealed",
    "id": 2631,
    "master_id": 2013,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13790F",
    "name": "Intel Core i7-13790F",
    "condition": "Used",
    "id": 2632,
    "master_id": 2014,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13790F",
    "name": "Intel Core i7-13790F",
    "condition": "Used With Box",
    "id": 2633,
    "master_id": 2014,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-13790F",
    "name": "Intel Core i7-13790F",
    "condition": "Brand New Sealed",
    "id": 2634,
    "master_id": 2014,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700",
    "name": "Intel Core i7-14700",
    "condition": "Used",
    "id": 6166,
    "master_id": 6148,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700",
    "name": "Intel Core i7-14700",
    "condition": "Used With Box",
    "id": 6167,
    "master_id": 6148,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700",
    "name": "Intel Core i7-14700",
    "condition": "Brand New Sealed",
    "id": 6168,
    "master_id": 6148,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700T",
    "name": "Intel Core i7-14700T",
    "condition": "Used",
    "id": 6211,
    "master_id": 6203,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700T",
    "name": "Intel Core i7-14700T",
    "condition": "Used With Box",
    "id": 6212,
    "master_id": 6203,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700T",
    "name": "Intel Core i7-14700T",
    "condition": "Brand New Sealed",
    "id": 6213,
    "master_id": 6203,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700F",
    "name": "Intel Core i7-14700F",
    "condition": "Used",
    "id": 6214,
    "master_id": 6204,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700F",
    "name": "Intel Core i7-14700F",
    "condition": "Used With Box",
    "id": 6215,
    "master_id": 6204,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700F",
    "name": "Intel Core i7-14700F",
    "condition": "Brand New Sealed",
    "id": 6216,
    "master_id": 6204,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700KF",
    "name": "Intel Core i7-14700KF",
    "condition": "Used",
    "id": 6163,
    "master_id": 6147,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700KF",
    "name": "Intel Core i7-14700KF",
    "condition": "Used With Box",
    "id": 6164,
    "master_id": 6147,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700KF",
    "name": "Intel Core i7-14700KF",
    "condition": "Brand New Sealed",
    "id": 6165,
    "master_id": 6147,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700K",
    "name": "Intel Core i7-14700K",
    "condition": "Used",
    "id": 6160,
    "master_id": 6146,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700K",
    "name": "Intel Core i7-14700K",
    "condition": "Used With Box",
    "id": 6161,
    "master_id": 6146,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i7",
    "model": "i7-14700K",
    "name": "Intel Core i7-14700K",
    "condition": "Brand New Sealed",
    "id": 6162,
    "master_id": 6146,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7900X",
    "name": "Intel Core i9-7900X",
    "condition": "Used",
    "id": 2635,
    "master_id": 2015,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7900X",
    "name": "Intel Core i9-7900X",
    "condition": "Used With Box",
    "id": 2636,
    "master_id": 2015,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7900X",
    "name": "Intel Core i9-7900X",
    "condition": "Brand New Sealed",
    "id": 2637,
    "master_id": 2015,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7920X",
    "name": "Intel Core i9-7920X",
    "condition": "Used",
    "id": 2638,
    "master_id": 2016,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7920X",
    "name": "Intel Core i9-7920X",
    "condition": "Used With Box",
    "id": 2639,
    "master_id": 2016,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7920X",
    "name": "Intel Core i9-7920X",
    "condition": "Brand New Sealed",
    "id": 2640,
    "master_id": 2016,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7940X",
    "name": "Intel Core i9-7940X",
    "condition": "Used",
    "id": 2641,
    "master_id": 2017,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7940X",
    "name": "Intel Core i9-7940X",
    "condition": "Used With Box",
    "id": 2642,
    "master_id": 2017,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7940X",
    "name": "Intel Core i9-7940X",
    "condition": "Brand New Sealed",
    "id": 2643,
    "master_id": 2017,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7960X",
    "name": "Intel Core i9-7960X",
    "condition": "Used",
    "id": 2644,
    "master_id": 2018,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7960X",
    "name": "Intel Core i9-7960X",
    "condition": "Used With Box",
    "id": 2645,
    "master_id": 2018,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7960X",
    "name": "Intel Core i9-7960X",
    "condition": "Brand New Sealed",
    "id": 2646,
    "master_id": 2018,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7980XE",
    "name": "Intel Core i9-7980XE",
    "condition": "Used",
    "id": 2647,
    "master_id": 2019,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7980XE",
    "name": "Intel Core i9-7980XE",
    "condition": "Used With Box",
    "id": 2648,
    "master_id": 2019,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-7980XE",
    "name": "Intel Core i9-7980XE",
    "condition": "Brand New Sealed",
    "id": 2649,
    "master_id": 2019,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9820X",
    "name": "Intel Core i9-9820X",
    "condition": "Used",
    "id": 2650,
    "master_id": 2020,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9820X",
    "name": "Intel Core i9-9820X",
    "condition": "Used With Box",
    "id": 2651,
    "master_id": 2020,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9820X",
    "name": "Intel Core i9-9820X",
    "condition": "Brand New Sealed",
    "id": 2652,
    "master_id": 2020,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900",
    "name": "Intel Core i9-9900",
    "condition": "Used",
    "id": 2653,
    "master_id": 2021,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900",
    "name": "Intel Core i9-9900",
    "condition": "Used With Box",
    "id": 2654,
    "master_id": 2021,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900",
    "name": "Intel Core i9-9900",
    "condition": "Brand New Sealed",
    "id": 2655,
    "master_id": 2021,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900K",
    "name": "Intel Core i9-9900K",
    "condition": "Used",
    "id": 2656,
    "master_id": 2022,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900K",
    "name": "Intel Core i9-9900K",
    "condition": "Used With Box",
    "id": 2657,
    "master_id": 2022,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900K",
    "name": "Intel Core i9-9900K",
    "condition": "Brand New Sealed",
    "id": 2658,
    "master_id": 2022,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900KF",
    "name": "Intel Core i9-9900KF",
    "condition": "Used",
    "id": 2659,
    "master_id": 2023,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900KF",
    "name": "Intel Core i9-9900KF",
    "condition": "Used With Box",
    "id": 2660,
    "master_id": 2023,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900KF",
    "name": "Intel Core i9-9900KF",
    "condition": "Brand New Sealed",
    "id": 2661,
    "master_id": 2023,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900KS",
    "name": "Intel Core i9-9900KS",
    "condition": "Used",
    "id": 2662,
    "master_id": 2024,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900KS",
    "name": "Intel Core i9-9900KS",
    "condition": "Used With Box",
    "id": 2663,
    "master_id": 2024,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900KS",
    "name": "Intel Core i9-9900KS",
    "condition": "Brand New Sealed",
    "id": 2664,
    "master_id": 2024,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900T",
    "name": "Intel Core i9-9900T",
    "condition": "Used",
    "id": 2665,
    "master_id": 2025,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900T",
    "name": "Intel Core i9-9900T",
    "condition": "Used With Box",
    "id": 2666,
    "master_id": 2025,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900T",
    "name": "Intel Core i9-9900T",
    "condition": "Brand New Sealed",
    "id": 2667,
    "master_id": 2025,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900X",
    "name": "Intel Core i9-9900X",
    "condition": "Used",
    "id": 2668,
    "master_id": 2026,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900X",
    "name": "Intel Core i9-9900X",
    "condition": "Used With Box",
    "id": 2669,
    "master_id": 2026,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9900X",
    "name": "Intel Core i9-9900X",
    "condition": "Brand New Sealed",
    "id": 2670,
    "master_id": 2026,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9920X",
    "name": "Intel Core i9-9920X",
    "condition": "Used",
    "id": 2671,
    "master_id": 2027,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9920X",
    "name": "Intel Core i9-9920X",
    "condition": "Used With Box",
    "id": 2672,
    "master_id": 2027,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9920X",
    "name": "Intel Core i9-9920X",
    "condition": "Brand New Sealed",
    "id": 2673,
    "master_id": 2027,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9960X",
    "name": "Intel Core i9-9960X",
    "condition": "Used",
    "id": 2674,
    "master_id": 2028,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9960X",
    "name": "Intel Core i9-9960X",
    "condition": "Used With Box",
    "id": 2675,
    "master_id": 2028,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9960X",
    "name": "Intel Core i9-9960X",
    "condition": "Brand New Sealed",
    "id": 2676,
    "master_id": 2028,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9980XE",
    "name": "Intel Core i9-9980XE",
    "condition": "Used",
    "id": 2677,
    "master_id": 2029,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9980XE",
    "name": "Intel Core i9-9980XE",
    "condition": "Used With Box",
    "id": 2678,
    "master_id": 2029,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9980XE",
    "name": "Intel Core i9-9980XE",
    "condition": "Brand New Sealed",
    "id": 2679,
    "master_id": 2029,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9990XE",
    "name": "Intel Core i9-9990XE",
    "condition": "Used",
    "id": 2680,
    "master_id": 2030,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9990XE",
    "name": "Intel Core i9-9990XE",
    "condition": "Used With Box",
    "id": 2681,
    "master_id": 2030,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-9990XE",
    "name": "Intel Core i9-9990XE",
    "condition": "Brand New Sealed",
    "id": 2682,
    "master_id": 2030,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10850K",
    "name": "Intel Core i9-10850K",
    "condition": "Used",
    "id": 2683,
    "master_id": 2031,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10850K",
    "name": "Intel Core i9-10850K",
    "condition": "Used With Box",
    "id": 2684,
    "master_id": 2031,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10850K",
    "name": "Intel Core i9-10850K",
    "condition": "Brand New Sealed",
    "id": 2685,
    "master_id": 2031,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900",
    "name": "Intel Core i9-10900",
    "condition": "Used",
    "id": 2686,
    "master_id": 2032,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900",
    "name": "Intel Core i9-10900",
    "condition": "Used With Box",
    "id": 2687,
    "master_id": 2032,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900",
    "name": "Intel Core i9-10900",
    "condition": "Brand New Sealed",
    "id": 2688,
    "master_id": 2032,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900E",
    "name": "Intel Core i9-10900E",
    "condition": "Used",
    "id": 2689,
    "master_id": 2033,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900E",
    "name": "Intel Core i9-10900E",
    "condition": "Used With Box",
    "id": 2690,
    "master_id": 2033,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900E",
    "name": "Intel Core i9-10900E",
    "condition": "Brand New Sealed",
    "id": 2691,
    "master_id": 2033,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900F",
    "name": "Intel Core i9-10900F",
    "condition": "Used",
    "id": 2692,
    "master_id": 2034,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900F",
    "name": "Intel Core i9-10900F",
    "condition": "Used With Box",
    "id": 2693,
    "master_id": 2034,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900F",
    "name": "Intel Core i9-10900F",
    "condition": "Brand New Sealed",
    "id": 2694,
    "master_id": 2034,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900K",
    "name": "Intel Core i9-10900K",
    "condition": "Used",
    "id": 2695,
    "master_id": 2035,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900K",
    "name": "Intel Core i9-10900K",
    "condition": "Used With Box",
    "id": 2696,
    "master_id": 2035,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900K",
    "name": "Intel Core i9-10900K",
    "condition": "Brand New Sealed",
    "id": 2697,
    "master_id": 2035,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900KF",
    "name": "Intel Core i9-10900KF",
    "condition": "Used",
    "id": 2698,
    "master_id": 2036,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900KF",
    "name": "Intel Core i9-10900KF",
    "condition": "Used With Box",
    "id": 2699,
    "master_id": 2036,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900KF",
    "name": "Intel Core i9-10900KF",
    "condition": "Brand New Sealed",
    "id": 2700,
    "master_id": 2036,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900T",
    "name": "Intel Core i9-10900T",
    "condition": "Used",
    "id": 2701,
    "master_id": 2037,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900T",
    "name": "Intel Core i9-10900T",
    "condition": "Used With Box",
    "id": 2702,
    "master_id": 2037,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900T",
    "name": "Intel Core i9-10900T",
    "condition": "Brand New Sealed",
    "id": 2703,
    "master_id": 2037,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900TE",
    "name": "Intel Core i9-10900TE",
    "condition": "Used",
    "id": 2704,
    "master_id": 2038,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900TE",
    "name": "Intel Core i9-10900TE",
    "condition": "Used With Box",
    "id": 2705,
    "master_id": 2038,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900TE",
    "name": "Intel Core i9-10900TE",
    "condition": "Brand New Sealed",
    "id": 2706,
    "master_id": 2038,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900X",
    "name": "Intel Core i9-10900X",
    "condition": "Used",
    "id": 2707,
    "master_id": 2039,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900X",
    "name": "Intel Core i9-10900X",
    "condition": "Used With Box",
    "id": 2708,
    "master_id": 2039,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10900X",
    "name": "Intel Core i9-10900X",
    "condition": "Brand New Sealed",
    "id": 2709,
    "master_id": 2039,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10920X",
    "name": "Intel Core i9-10920X",
    "condition": "Used",
    "id": 2710,
    "master_id": 2040,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10920X",
    "name": "Intel Core i9-10920X",
    "condition": "Used With Box",
    "id": 2711,
    "master_id": 2040,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10920X",
    "name": "Intel Core i9-10920X",
    "condition": "Brand New Sealed",
    "id": 2712,
    "master_id": 2040,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10940X",
    "name": "Intel Core i9-10940X",
    "condition": "Used",
    "id": 2713,
    "master_id": 2041,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10940X",
    "name": "Intel Core i9-10940X",
    "condition": "Used With Box",
    "id": 2714,
    "master_id": 2041,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10940X",
    "name": "Intel Core i9-10940X",
    "condition": "Brand New Sealed",
    "id": 2715,
    "master_id": 2041,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10980XE",
    "name": "Intel Core i9-10980XE",
    "condition": "Used",
    "id": 2716,
    "master_id": 2042,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10980XE",
    "name": "Intel Core i9-10980XE",
    "condition": "Used With Box",
    "id": 2717,
    "master_id": 2042,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-10980XE",
    "name": "Intel Core i9-10980XE",
    "condition": "Brand New Sealed",
    "id": 2718,
    "master_id": 2042,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900KF",
    "name": "Intel Core i9-11900KF",
    "condition": "Used",
    "id": 2719,
    "master_id": 2043,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900KF",
    "name": "Intel Core i9-11900KF",
    "condition": "Used With Box",
    "id": 2720,
    "master_id": 2043,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900KF",
    "name": "Intel Core i9-11900KF",
    "condition": "Brand New Sealed",
    "id": 2721,
    "master_id": 2043,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900K",
    "name": "Intel Core i9-11900K",
    "condition": "Used",
    "id": 2722,
    "master_id": 2044,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900K",
    "name": "Intel Core i9-11900K",
    "condition": "Used With Box",
    "id": 2723,
    "master_id": 2044,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900K",
    "name": "Intel Core i9-11900K",
    "condition": "Brand New Sealed",
    "id": 2724,
    "master_id": 2044,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900F",
    "name": "Intel Core i9-11900F",
    "condition": "Used",
    "id": 2725,
    "master_id": 2045,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900F",
    "name": "Intel Core i9-11900F",
    "condition": "Used With Box",
    "id": 2726,
    "master_id": 2045,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-11900F",
    "name": "Intel Core i9-11900F",
    "condition": "Brand New Sealed",
    "id": 2727,
    "master_id": 2045,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-12900K",
    "name": "Intel Core i9-12900K",
    "condition": "Used",
    "id": 2728,
    "master_id": 2046,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-12900K",
    "name": "Intel Core i9-12900K",
    "condition": "Used With Box",
    "id": 2729,
    "master_id": 2046,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-12900K",
    "name": "Intel Core i9-12900K",
    "condition": "Brand New Sealed",
    "id": 2730,
    "master_id": 2046,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900T",
    "name": "Intel Core i9-13900T",
    "condition": "Used",
    "id": 2731,
    "master_id": 2047,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900T",
    "name": "Intel Core i9-13900T",
    "condition": "Used With Box",
    "id": 2732,
    "master_id": 2047,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900T",
    "name": "Intel Core i9-13900T",
    "condition": "Brand New Sealed",
    "id": 2733,
    "master_id": 2047,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900F",
    "name": "Intel Core i9-13900F",
    "condition": "Used",
    "id": 2734,
    "master_id": 2048,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900F",
    "name": "Intel Core i9-13900F",
    "condition": "Used With Box",
    "id": 2735,
    "master_id": 2048,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900F",
    "name": "Intel Core i9-13900F",
    "condition": "Brand New Sealed",
    "id": 2736,
    "master_id": 2048,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900",
    "name": "Intel Core i9-13900",
    "condition": "Used",
    "id": 2737,
    "master_id": 2049,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900",
    "name": "Intel Core i9-13900",
    "condition": "Used With Box",
    "id": 2738,
    "master_id": 2049,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900",
    "name": "Intel Core i9-13900",
    "condition": "Brand New Sealed",
    "id": 2739,
    "master_id": 2049,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900KF",
    "name": "Intel Core i9-13900KF",
    "condition": "Used",
    "id": 2740,
    "master_id": 2050,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900KF",
    "name": "Intel Core i9-13900KF",
    "condition": "Used With Box",
    "id": 2741,
    "master_id": 2050,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900KF",
    "name": "Intel Core i9-13900KF",
    "condition": "Brand New Sealed",
    "id": 2742,
    "master_id": 2050,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900K",
    "name": "Intel Core i9-13900K",
    "condition": "Used",
    "id": 2743,
    "master_id": 2051,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900K",
    "name": "Intel Core i9-13900K",
    "condition": "Used With Box",
    "id": 2744,
    "master_id": 2051,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900K",
    "name": "Intel Core i9-13900K",
    "condition": "Brand New Sealed",
    "id": 2745,
    "master_id": 2051,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900KS",
    "name": "Intel Core i9-13900KS",
    "condition": "Used",
    "id": 2746,
    "master_id": 2052,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900KS",
    "name": "Intel Core i9-13900KS",
    "condition": "Used With Box",
    "id": 2747,
    "master_id": 2052,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-13900KS",
    "name": "Intel Core i9-13900KS",
    "condition": "Brand New Sealed",
    "id": 2748,
    "master_id": 2052,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900",
    "name": "Intel Core i9-14900",
    "condition": "Used",
    "id": 6157,
    "master_id": 6145,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900",
    "name": "Intel Core i9-14900",
    "condition": "Used With Box",
    "id": 6158,
    "master_id": 6145,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900",
    "name": "Intel Core i9-14900",
    "condition": "Brand New Sealed",
    "id": 6159,
    "master_id": 6145,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900T",
    "name": "Intel Core i9-14900T",
    "condition": "Used",
    "id": 6200,
    "master_id": 6179,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900T",
    "name": "Intel Core i9-14900T",
    "condition": "Used With Box",
    "id": 6201,
    "master_id": 6179,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900T",
    "name": "Intel Core i9-14900T",
    "condition": "Brand New Sealed",
    "id": 6202,
    "master_id": 6179,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900KF",
    "name": "Intel Core i9-14900KF",
    "condition": "Used",
    "id": 6154,
    "master_id": 6144,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900KF",
    "name": "Intel Core i9-14900KF",
    "condition": "Used With Box",
    "id": 6155,
    "master_id": 6144,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900KF",
    "name": "Intel Core i9-14900KF",
    "condition": "Brand New Sealed",
    "id": 6156,
    "master_id": 6144,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900K",
    "name": "Intel Core i9-14900K",
    "condition": "Used",
    "id": 6151,
    "master_id": 6143,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900K",
    "name": "Intel Core i9-14900K",
    "condition": "Used With Box",
    "id": 6152,
    "master_id": 6143,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900K",
    "name": "Intel Core i9-14900K",
    "condition": "Brand New Sealed",
    "id": 6153,
    "master_id": 6143,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900KS",
    "name": "Intel Core i9-14900KS",
    "condition": "Used",
    "id": 6140,
    "master_id": 6139,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900KS",
    "name": "Intel Core i9-14900KS",
    "condition": "Used With Box",
    "id": 6141,
    "master_id": 6139,
  },
  {
    "type": "CPU",
    "brand": "Intel",
    "series": "Core i9",
    "model": "i9-14900KS",
    "name": "Intel Core i9-14900KS",
    "condition": "Brand New Sealed",
    "id": 6142,
    "master_id": 6139,
  },
];

let nvidiaGPUArray = [
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 700",
    "model": "780 3 GB",
    "name": "GTX 780 3 GB",
    "condition": "Used",
    "id": 1524,
    "master_id": 1427
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 700",
    "model": "780 3 GB",
    "name": "GTX 780 3 GB",
    "condition": "Used With Box",
    "id": 1525,
    "master_id": 1427
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 700",
    "model": "780 3 GB",
    "name": "GTX 780 3 GB",
    "condition": "Brand New Sealed",
    "id": 1526,
    "master_id": 1427
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 700",
    "model": "780 Ti 3 GB",
    "name": "GTX 780 Ti 3 GB",
    "condition": "Used",
    "id": 1527,
    "master_id": 1428
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 700",
    "model": "780 Ti 3 GB",
    "name": "GTX 780 Ti 3 GB",
    "condition": "Used With Box",
    "id": 1528,
    "master_id": 1428
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 700",
    "model": "780 Ti 3 GB",
    "name": "GTX 780 Ti 3 GB",
    "condition": "Brand New Sealed",
    "id": 1529,
    "master_id": 1428
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "950 2 GB",
    "name": "GTX 950 2 GB",
    "condition": "Used",
    "id": 1530,
    "master_id": 1429
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "950 2 GB",
    "name": "GTX 950 2 GB",
    "condition": "Used With Box",
    "id": 1531,
    "master_id": 1429
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "950 2 GB",
    "name": "GTX 950 2 GB",
    "condition": "Brand New Sealed",
    "id": 1532,
    "master_id": 1429
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "960 2 GB",
    "name": "GTX 960 2 GB",
    "condition": "Used",
    "id": 1533,
    "master_id": 1430
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "960 2 GB",
    "name": "GTX 960 2 GB",
    "condition": "Used With Box",
    "id": 1534,
    "master_id": 1430
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "960 2 GB",
    "name": "GTX 960 2 GB",
    "condition": "Brand New Sealed",
    "id": 1535,
    "master_id": 1430
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "960 4 GB",
    "name": "GTX 960 4 GB",
    "condition": "Used",
    "id": 1536,
    "master_id": 1431
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "960 4 GB",
    "name": "GTX 960 4 GB",
    "condition": "Used With Box",
    "id": 1537,
    "master_id": 1431
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "960 4 GB",
    "name": "GTX 960 4 GB",
    "condition": "Brand New Sealed",
    "id": 1538,
    "master_id": 1431
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "970 4 GB",
    "name": "GTX 970 4 GB",
    "condition": "Used",
    "id": 1539,
    "master_id": 1432
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "970 4 GB",
    "name": "GTX 970 4 GB",
    "condition": "Used With Box",
    "id": 1540,
    "master_id": 1432
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "970 4 GB",
    "name": "GTX 970 4 GB",
    "condition": "Brand New Sealed",
    "id": 1541,
    "master_id": 1432
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "980 4 GB",
    "name": "GTX 980 4 GB",
    "condition": "Used",
    "id": 1542,
    "master_id": 1433
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "980 4 GB",
    "name": "GTX 980 4 GB",
    "condition": "Used With Box",
    "id": 1543,
    "master_id": 1433
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "980 4 GB",
    "name": "GTX 980 4 GB",
    "condition": "Brand New Sealed",
    "id": 1544,
    "master_id": 1433
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "980 Ti 6 GB",
    "name": "GTX 980 Ti 6 GB",
    "condition": "Used",
    "id": 1545,
    "master_id": 1434
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "980 Ti 6 GB",
    "name": "GTX 980 Ti 6 GB",
    "condition": "Used With Box",
    "id": 1546,
    "master_id": 1434
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 900",
    "model": "980 Ti 6 GB",
    "name": "GTX 980 Ti 6 GB",
    "condition": "Brand New Sealed",
    "id": 1547,
    "master_id": 1434
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1030 2 GB",
    "name": "GT 1030 2 GB",
    "condition": "Used",
    "id": 1548,
    "master_id": 1435
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1030 2 GB",
    "name": "GT 1030 2 GB",
    "condition": "Used With Box",
    "id": 1549,
    "master_id": 1435
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1030 2 GB",
    "name": "GT 1030 2 GB",
    "condition": "Brand New Sealed",
    "id": 1550,
    "master_id": 1435
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 2 GB",
    "name": "GTX 1050 2 GB",
    "condition": "Used",
    "id": 1551,
    "master_id": 1436
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 2 GB",
    "name": "GTX 1050 2 GB",
    "condition": "Used With Box",
    "id": 1552,
    "master_id": 1436
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 2 GB",
    "name": "GTX 1050 2 GB",
    "condition": "Brand New Sealed",
    "id": 1553,
    "master_id": 1436
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 3 GB",
    "name": "GTX 1050 3 GB",
    "condition": "Used",
    "id": 1554,
    "master_id": 1437
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 3 GB",
    "name": "GTX 1050 3 GB",
    "condition": "Used With Box",
    "id": 1555,
    "master_id": 1437
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 3 GB",
    "name": "GTX 1050 3 GB",
    "condition": "Brand New Sealed",
    "id": 1556,
    "master_id": 1437
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 Ti 4 GB",
    "name": "GTX 1050 Ti 4 GB",
    "condition": "Used",
    "id": 1557,
    "master_id": 1438
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 Ti 4 GB",
    "name": "GTX 1050 Ti 4 GB",
    "condition": "Used With Box",
    "id": 1558,
    "master_id": 1438
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1050 Ti 4 GB",
    "name": "GTX 1050 Ti 4 GB",
    "condition": "Brand New Sealed",
    "id": 1559,
    "master_id": 1438
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1060 3 GB",
    "name": "GTX 1060 3 GB",
    "condition": "Used",
    "id": 1560,
    "master_id": 1439
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1060 3 GB",
    "name": "GTX 1060 3 GB",
    "condition": "Used With Box",
    "id": 1561,
    "master_id": 1439
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1060 3 GB",
    "name": "GTX 1060 3 GB",
    "condition": "Brand New Sealed",
    "id": 1562,
    "master_id": 1439
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1060 6 GB",
    "name": "GTX 1060 6 GB",
    "condition": "Used",
    "id": 1563,
    "master_id": 1440
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1060 6 GB",
    "name": "GTX 1060 6 GB",
    "condition": "Used With Box",
    "id": 1564,
    "master_id": 1440
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1060 6 GB",
    "name": "GTX 1060 6 GB",
    "condition": "Brand New Sealed",
    "id": 1565,
    "master_id": 1440
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1070 8 GB",
    "name": "GTX 1070 8 GB",
    "condition": "Used",
    "id": 1566,
    "master_id": 1441
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1070 8 GB",
    "name": "GTX 1070 8 GB",
    "condition": "Used With Box",
    "id": 1567,
    "master_id": 1441
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1070 8 GB",
    "name": "GTX 1070 8 GB",
    "condition": "Brand New Sealed",
    "id": 1568,
    "master_id": 1441
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1070 Ti 8 GB",
    "name": "GTX 1070 Ti 8 GB",
    "condition": "Used",
    "id": 1569,
    "master_id": 1442
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1070 Ti 8 GB",
    "name": "GTX 1070 Ti 8 GB",
    "condition": "Used With Box",
    "id": 1570,
    "master_id": 1442
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1070 Ti 8 GB",
    "name": "GTX 1070 Ti 8 GB",
    "condition": "Brand New Sealed",
    "id": 1571,
    "master_id": 1442
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1080 8 GB",
    "name": "GTX 1080 8 GB",
    "condition": "Used",
    "id": 1572,
    "master_id": 1443
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1080 8 GB",
    "name": "GTX 1080 8 GB",
    "condition": "Used With Box",
    "id": 1573,
    "master_id": 1443
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1080 8 GB",
    "name": "GTX 1080 8 GB",
    "condition": "Brand New Sealed",
    "id": 1574,
    "master_id": 1443
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1080 Ti 11 GB",
    "name": "GTX 1080 Ti 11 GB",
    "condition": "Used",
    "id": 1575,
    "master_id": 1444
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1080 Ti 11 GB",
    "name": "GTX 1080 Ti 11 GB",
    "condition": "Used With Box",
    "id": 1576,
    "master_id": 1444
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1000",
    "model": "1080 Ti 11 GB",
    "name": "GTX 1080 Ti 11 GB",
    "condition": "Brand New Sealed",
    "id": 1577,
    "master_id": 1444
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1650 4 GB",
    "name": "GTX 1650 4 GB",
    "condition": "Used",
    "id": 1578,
    "master_id": 1445
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1650 4 GB",
    "name": "GTX 1650 4 GB",
    "condition": "Used With Box",
    "id": 1579,
    "master_id": 1445
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1650 4 GB",
    "name": "GTX 1650 4 GB",
    "condition": "Brand New Sealed",
    "id": 1580,
    "master_id": 1445
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1650 Super 4 GB",
    "name": "GTX 1650 Super 4 GB",
    "condition": "Used",
    "id": 1581,
    "master_id": 1446
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1650 Super 4 GB",
    "name": "GTX 1650 Super 4 GB",
    "condition": "Used With Box",
    "id": 1582,
    "master_id": 1446
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1650 Super 4 GB",
    "name": "GTX 1650 Super 4 GB",
    "condition": "Brand New Sealed",
    "id": 1583,
    "master_id": 1446
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 6 GB",
    "name": "GTX 1660 6 GB",
    "condition": "Used",
    "id": 1584,
    "master_id": 1447
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 6 GB",
    "name": "GTX 1660 6 GB",
    "condition": "Used With Box",
    "id": 1585,
    "master_id": 1447
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 6 GB",
    "name": "GTX 1660 6 GB",
    "condition": "Brand New Sealed",
    "id": 1586,
    "master_id": 1447
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 Super 6 GB",
    "name": "GTX 1660 Super 6 GB",
    "condition": "Used",
    "id": 1587,
    "master_id": 1448
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 Super 6 GB",
    "name": "GTX 1660 Super 6 GB",
    "condition": "Used With Box",
    "id": 1588,
    "master_id": 1448
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 Super 6 GB",
    "name": "GTX 1660 Super 6 GB",
    "condition": "Brand New Sealed",
    "id": 1589,
    "master_id": 1448
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 Ti 6 GB",
    "name": "GTX 1660 Ti 6 GB",
    "condition": "Used",
    "id": 1590,
    "master_id": 1449
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 Ti 6 GB",
    "name": "GTX 1660 Ti 6 GB",
    "condition": "Used With Box",
    "id": 1591,
    "master_id": 1449
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "GTX 1600",
    "model": "1660 Ti 6 GB",
    "name": "GTX 1660 Ti 6 GB",
    "condition": "Brand New Sealed",
    "id": 1592,
    "master_id": 1449
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 6 GB",
    "name": "RTX 2060 6 GB",
    "condition": "Used",
    "id": 1593,
    "master_id": 1450
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 6 GB",
    "name": "RTX 2060 6 GB",
    "condition": "Used With Box",
    "id": 1594,
    "master_id": 1450
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 6 GB",
    "name": "RTX 2060 6 GB",
    "condition": "Brand New Sealed",
    "id": 1595,
    "master_id": 1450
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 12 GB",
    "name": "RTX 2060 12 GB",
    "condition": "Used",
    "id": 3893,
    "master_id": 3892
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 12 GB",
    "name": "RTX 2060 12 GB",
    "condition": "Used With Box",
    "id": 3894,
    "master_id": 3892
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 12 GB",
    "name": "RTX 2060 12 GB",
    "condition": "Brand New Sealed",
    "id": 3895,
    "master_id": 3892
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 Super 6 GB",
    "name": "RTX 2060 Super 6 GB",
    "condition": "Used",
    "id": 1596,
    "master_id": 1451
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 Super 6 GB",
    "name": "RTX 2060 Super 6 GB",
    "condition": "Used With Box",
    "id": 1597,
    "master_id": 1451
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 Super 6 GB",
    "name": "RTX 2060 Super 6 GB",
    "condition": "Brand New Sealed",
    "id": 1598,
    "master_id": 1451
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 Super 8 GB",
    "name": "RTX 2060 Super 8 GB",
    "condition": "Used",
    "id": 1599,
    "master_id": 1452
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 Super 8 GB",
    "name": "RTX 2060 Super 8 GB",
    "condition": "Used With Box",
    "id": 1600,
    "master_id": 1452
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2060 Super 8 GB",
    "name": "RTX 2060 Super 8 GB",
    "condition": "Brand New Sealed",
    "id": 1601,
    "master_id": 1452
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2070 8 GB",
    "name": "RTX 2070 8 GB",
    "condition": "Used",
    "id": 1602,
    "master_id": 1453
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2070 8 GB",
    "name": "RTX 2070 8 GB",
    "condition": "Used With Box",
    "id": 1603,
    "master_id": 1453
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2070 8 GB",
    "name": "RTX 2070 8 GB",
    "condition": "Brand New Sealed",
    "id": 1604,
    "master_id": 1453
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2070 Super 8 GB",
    "name": "RTX 2070 Super 8 GB",
    "condition": "Used",
    "id": 1605,
    "master_id": 1454
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2070 Super 8 GB",
    "name": "RTX 2070 Super 8 GB",
    "condition": "Used With Box",
    "id": 1606,
    "master_id": 1454
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2070 Super 8 GB",
    "name": "RTX 2070 Super 8 GB",
    "condition": "Brand New Sealed",
    "id": 1607,
    "master_id": 1454
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 8 GB",
    "name": "RTX 2080 8 GB",
    "condition": "Used",
    "id": 1608,
    "master_id": 1455
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 8 GB",
    "name": "RTX 2080 8 GB",
    "condition": "Used With Box",
    "id": 1609,
    "master_id": 1455
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 8 GB",
    "name": "RTX 2080 8 GB",
    "condition": "Brand New Sealed",
    "id": 1610,
    "master_id": 1455
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 Super 8 GB",
    "name": "RTX 2080 Super 8 GB",
    "condition": "Used",
    "id": 1611,
    "master_id": 1456
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 Super 8 GB",
    "name": "RTX 2080 Super 8 GB",
    "condition": "Used With Box",
    "id": 1612,
    "master_id": 1456
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 Super 8 GB",
    "name": "RTX 2080 Super 8 GB",
    "condition": "Brand New Sealed",
    "id": 1613,
    "master_id": 1456
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 Ti 11 GB",
    "name": "RTX 2080 Ti 11 GB",
    "condition": "Used",
    "id": 1614,
    "master_id": 1457
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 Ti 11 GB",
    "name": "RTX 2080 Ti 11 GB",
    "condition": "Used With Box",
    "id": 1615,
    "master_id": 1457
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 2000",
    "model": "2080 Ti 11 GB",
    "name": "RTX 2080 Ti 11 GB",
    "condition": "Brand New Sealed",
    "id": 1616,
    "master_id": 1457
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3050 8 GB",
    "name": "RTX 3050 8 GB",
    "condition": "Used",
    "id": 1617,
    "master_id": 1458
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3050 8 GB",
    "name": "RTX 3050 8 GB",
    "condition": "Used With Box",
    "id": 1618,
    "master_id": 1458
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3050 8 GB",
    "name": "RTX 3050 8 GB",
    "condition": "Brand New Sealed",
    "id": 1619,
    "master_id": 1458
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 8 GB",
    "name": "RTX 3060 8 GB",
    "condition": "Used",
    "id": 3889,
    "master_id": 3888
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 8 GB",
    "name": "RTX 3060 8 GB",
    "condition": "Used With Box",
    "id": 3890,
    "master_id": 3888
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 8 GB",
    "name": "RTX 3060 8 GB",
    "condition": "Brand New Sealed",
    "id": 3891,
    "master_id": 3888
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 12 GB",
    "name": "RTX 3060 12 GB",
    "condition": "Used",
    "id": 1620,
    "master_id": 1459
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 12 GB",
    "name": "RTX 3060 12 GB",
    "condition": "Used With Box",
    "id": 1621,
    "master_id": 1459
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 12 GB",
    "name": "RTX 3060 12 GB",
    "condition": "Brand New Sealed",
    "id": 1622,
    "master_id": 1459
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 Ti 8 GB",
    "name": "RTX 3060 Ti 8 GB",
    "condition": "Used",
    "id": 1623,
    "master_id": 1460
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 Ti 8 GB",
    "name": "RTX 3060 Ti 8 GB",
    "condition": "Used With Box",
    "id": 1624,
    "master_id": 1460
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3060 Ti 8 GB",
    "name": "RTX 3060 Ti 8 GB",
    "condition": "Brand New Sealed",
    "id": 1625,
    "master_id": 1460
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3070 8 GB",
    "name": "RTX 3070 8 GB",
    "condition": "Used",
    "id": 1626,
    "master_id": 1461
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3070 8 GB",
    "name": "RTX 3070 8 GB",
    "condition": "Used With Box",
    "id": 1627,
    "master_id": 1461
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3070 8 GB",
    "name": "RTX 3070 8 GB",
    "condition": "Brand New Sealed",
    "id": 1628,
    "master_id": 1461
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3070 Ti 8 GB",
    "name": "RTX 3070 Ti 8 GB",
    "condition": "Used",
    "id": 1629,
    "master_id": 1462
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3070 Ti 8 GB",
    "name": "RTX 3070 Ti 8 GB",
    "condition": "Used With Box",
    "id": 1630,
    "master_id": 1462
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3070 Ti 8 GB",
    "name": "RTX 3070 Ti 8 GB",
    "condition": "Brand New Sealed",
    "id": 1631,
    "master_id": 1462
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 10 GB",
    "name": "RTX 3080 10 GB",
    "condition": "Used",
    "id": 1632,
    "master_id": 1463
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 10 GB",
    "name": "RTX 3080 10 GB",
    "condition": "Used With Box",
    "id": 1633,
    "master_id": 1463
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 10 GB",
    "name": "RTX 3080 10 GB",
    "condition": "Brand New Sealed",
    "id": 1634,
    "master_id": 1463
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 12 GB",
    "name": "RTX 3080 12 GB",
    "condition": "Used",
    "id": 1635,
    "master_id": 1464
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 12 GB",
    "name": "RTX 3080 12 GB",
    "condition": "Used With Box",
    "id": 1636,
    "master_id": 1464
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 12 GB",
    "name": "RTX 3080 12 GB",
    "condition": "Brand New Sealed",
    "id": 1638,
    "master_id": 1464
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 Ti 12 GB",
    "name": "RTX 3080 Ti 12 GB",
    "condition": "Used",
    "id": 1639,
    "master_id": 1465
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 Ti 12 GB",
    "name": "RTX 3080 Ti 12 GB",
    "condition": "Used With Box",
    "id": 1640,
    "master_id": 1465
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3080 Ti 12 GB",
    "name": "RTX 3080 Ti 12 GB",
    "condition": "Brand New Sealed",
    "id": 1641,
    "master_id": 1465
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3090 24 GB",
    "name": "RTX 3090 24 GB",
    "condition": "Used",
    "id": 1642,
    "master_id": 1466
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3090 24 GB",
    "name": "RTX 3090 24 GB",
    "condition": "Used With Box",
    "id": 1643,
    "master_id": 1466
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3090 24 GB",
    "name": "RTX 3090 24 GB",
    "condition": "Brand New Sealed",
    "id": 1644,
    "master_id": 1466
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3090 Ti 24 GB",
    "name": "RTX 3090 Ti 24 GB",
    "condition": "Used",
    "id": 1645,
    "master_id": 1467
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3090 Ti 24 GB",
    "name": "RTX 3090 Ti 24 GB",
    "condition": "Used With Box",
    "id": 1646,
    "master_id": 1467
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 3000",
    "model": "3090 Ti 24 GB",
    "name": "RTX 3090 Ti 24 GB",
    "condition": "Brand New Sealed",
    "id": 1647,
    "master_id": 1467
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 8 GB",
    "name": "RTX 4060 8 GB",
    "condition": "Used",
    "id": 3672,
    "master_id": 3671
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 8 GB",
    "name": "RTX 4060 8 GB",
    "condition": "Used With Box",
    "id": 3673,
    "master_id": 3671
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 8 GB",
    "name": "RTX 4060 8 GB",
    "condition": "Brand New Sealed",
    "id": 3674,
    "master_id": 3671
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 Ti 8 GB",
    "name": "RTX 4060 Ti 8 GB",
    "condition": "Used",
    "id": 3664,
    "master_id": 3663
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 Ti 8 GB",
    "name": "RTX 4060 Ti 8 GB",
    "condition": "Used With Box",
    "id": 3665,
    "master_id": 3663
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 Ti 8 GB",
    "name": "RTX 4060 Ti 8 GB",
    "condition": "Brand New Sealed",
    "id": 3666,
    "master_id": 3663
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 Ti 16 GB",
    "name": "RTX 4060 Ti 16 GB",
    "condition": "Used",
    "id": 3668,
    "master_id": 3667
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 Ti 16 GB",
    "name": "RTX 4060 Ti 16 GB",
    "condition": "Used With Box",
    "id": 3669,
    "master_id": 3667
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4060 Ti 16 GB",
    "name": "RTX 4060 Ti 16 GB",
    "condition": "Brand New Sealed",
    "id": 3670,
    "master_id": 3667
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 12 GB",
    "name": "RTX 4070 12 GB",
    "condition": "Used",
    "id": 1648,
    "master_id": 1468
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 12 GB",
    "name": "RTX 4070 12 GB",
    "condition": "Used With Box",
    "id": 1649,
    "master_id": 1468
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 12 GB",
    "name": "RTX 4070 12 GB",
    "condition": "Brand New Sealed",
    "id": 1650,
    "master_id": 1468
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Super 12 GB",
    "name": "RTX 4070 Super 12 GB",
    "condition": "Used",
    "id": 7895,
    "master_id": 7893
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Super 12 GB",
    "name": "RTX 4070 Super 12 GB",
    "condition": "Used With Box",
    "id": 7896,
    "master_id": 7893
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Super 12 GB",
    "name": "RTX 4070 Super 12 GB",
    "condition": "Brand New Sealed",
    "id": 7897,
    "master_id": 7893
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Ti 12 GB",
    "name": "RTX 4070 Ti 12 GB",
    "condition": "Used",
    "id": 1651,
    "master_id": 1469
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Ti 12 GB",
    "name": "RTX 4070 Ti 12 GB",
    "condition": "Used With Box",
    "id": 1652,
    "master_id": 1469
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Ti 12 GB",
    "name": "RTX 4070 Ti 12 GB",
    "condition": "Brand New Sealed",
    "id": 1653,
    "master_id": 1469
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Ti Super 16 GB",
    "name": "RTX 4070 Ti Super 16 GB",
    "condition": "Used",
    "id": 7898,
    "master_id": 7894
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Ti Super 16 GB",
    "name": "RTX 4070 Ti Super 16 GB",
    "condition": "Used With Box",
    "id": 7899,
    "master_id": 7894
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4070 Ti Super 16 GB",
    "name": "RTX 4070 Ti Super 16 GB",
    "condition": "Brand New Sealed",
    "id": 7900,
    "master_id": 7894
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4080 16 GB",
    "name": "RTX 4080 16 GB",
    "condition": "Used",
    "id": 1654,
    "master_id": 1470
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4080 16 GB",
    "name": "RTX 4080 16 GB",
    "condition": "Used With Box",
    "id": 1655,
    "master_id": 1470
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4080 16 GB",
    "name": "RTX 4080 16 GB",
    "condition": "Brand New Sealed",
    "id": 1656,
    "master_id": 1470
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4080 Super 16 GB",
    "name": "RTX 4080 Super 16 GB",
    "condition": "Used",
    "id": 7908,
    "master_id": 7901
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4080 Super 16 GB",
    "name": "RTX 4080 Super 16 GB",
    "condition": "Used With Box",
    "id": 7909,
    "master_id": 7901
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4080 Super 16 GB",
    "name": "RTX 4080 Super 16 GB",
    "condition": "Brand New Sealed",
    "id": 7910,
    "master_id": 7901
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4090 24 GB",
    "name": "RTX 4090 24 GB",
    "condition": "Used",
    "id": 1657,
    "master_id": 1471
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4090 24 GB",
    "name": "RTX 4090 24 GB",
    "condition": "Used With Box",
    "id": 1658,
    "master_id": 1471
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "RTX 4000",
    "model": "4090 24 GB",
    "name": "RTX 4090 24 GB",
    "condition": "Brand New Sealed",
    "id": 1659,
    "master_id": 1471
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan 6 GB",
    "name": "GTX Titan 6 GB",
    "condition": "Used",
    "id": 1816,
    "master_id": 1472
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan 6 GB",
    "name": "GTX Titan 6 GB",
    "condition": "Used With Box",
    "id": 1817,
    "master_id": 1472
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan 6 GB",
    "name": "GTX Titan 6 GB",
    "condition": "Brand New Sealed",
    "id": 1818,
    "master_id": 1472
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan Black 6 GB",
    "name": "GTX Titan Black 6 GB",
    "condition": "Used",
    "id": 1663,
    "master_id": 1473
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan Black 6 GB",
    "name": "GTX Titan Black 6 GB",
    "condition": "Used With Box",
    "id": 1664,
    "master_id": 1473
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan Black 6 GB",
    "name": "GTX Titan Black 6 GB",
    "condition": "Brand New Sealed",
    "id": 1665,
    "master_id": 1473
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan X 12 GB",
    "name": "GTX Titan X 12 GB",
    "condition": "Used",
    "id": 1666,
    "master_id": 1474
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan X 12 GB",
    "name": "GTX Titan X 12 GB",
    "condition": "Used With Box",
    "id": 1667,
    "master_id": 1474
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan X 12 GB",
    "name": "GTX Titan X 12 GB",
    "condition": "Brand New Sealed",
    "id": 1668,
    "master_id": 1474
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan XP 12 GB",
    "name": "GTX Titan XP 12 GB",
    "condition": "Used",
    "id": 1669,
    "master_id": 1475
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan XP 12 GB",
    "name": "GTX Titan XP 12 GB",
    "condition": "Used With Box",
    "id": 1670,
    "master_id": 1475
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan XP 12 GB",
    "name": "GTX Titan XP 12 GB",
    "condition": "Brand New Sealed",
    "id": 1671,
    "master_id": 1475
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan X Pascal 12 GB",
    "name": "GTX Titan X Pascal 12 GB",
    "condition": "Used",
    "id": 1672,
    "master_id": 1476
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan X Pascal 12 GB",
    "name": "GTX Titan X Pascal 12 GB",
    "condition": "Used With Box",
    "id": 1673,
    "master_id": 1476
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan X Pascal 12 GB",
    "name": "GTX Titan X Pascal 12 GB",
    "condition": "Brand New Sealed",
    "id": 1674,
    "master_id": 1476
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan Z 12 GB",
    "name": "GTX Titan Z 12 GB",
    "condition": "Used",
    "id": 1675,
    "master_id": 1477
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan Z 12 GB",
    "name": "GTX Titan Z 12 GB",
    "condition": "Used With Box",
    "id": 1676,
    "master_id": 1477
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan Z 12 GB",
    "name": "GTX Titan Z 12 GB",
    "condition": "Brand New Sealed",
    "id": 1677,
    "master_id": 1477
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan V Volta 12 GB",
    "name": "Titan V Volta 12 GB",
    "condition": "Used",
    "id": 1678,
    "master_id": 1478
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan V Volta 12 GB",
    "name": "Titan V Volta 12 GB",
    "condition": "Used With Box",
    "id": 1679,
    "master_id": 1478
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan V Volta 12 GB",
    "name": "Titan V Volta 12 GB",
    "condition": "Brand New Sealed",
    "id": 1680,
    "master_id": 1478
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan 24 GB",
    "name": "RTX Titan 24 GB",
    "condition": "Used",
    "id": 1681,
    "master_id": 1479
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan 24 GB",
    "name": "RTX Titan 24 GB",
    "condition": "Used With Box",
    "id": 1682,
    "master_id": 1479
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Titan Series",
    "model": "Titan 24 GB",
    "name": "RTX Titan 24 GB",
    "condition": "Brand New Sealed",
    "id": 1683,
    "master_id": 1479
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "NVS 510 2 GB",
    "name": "Quadro NVS 510 2 GB",
    "condition": "Used",
    "id": 1684,
    "master_id": 1480
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "NVS 510 2 GB",
    "name": "Quadro NVS 510 2 GB",
    "condition": "Used With Box",
    "id": 1685,
    "master_id": 1480
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "NVS 510 2 GB",
    "name": "Quadro NVS 510 2 GB",
    "condition": "Brand New Sealed",
    "id": 1686,
    "master_id": 1480
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "NVS 810 4 GB",
    "name": "Quadro NVS 810 4 GB",
    "condition": "Used",
    "id": 1687,
    "master_id": 1481
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "NVS 810 4 GB",
    "name": "Quadro NVS 810 4 GB",
    "condition": "Used With Box",
    "id": 1688,
    "master_id": 1481
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "NVS 810 4 GB",
    "name": "Quadro NVS 810 4 GB",
    "condition": "Brand New Sealed",
    "id": 1689,
    "master_id": 1481
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K420 2 GB",
    "name": "Quadro K420 2 GB",
    "condition": "Used",
    "id": 1690,
    "master_id": 1482
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K420 2 GB",
    "name": "Quadro K420 2 GB",
    "condition": "Used With Box",
    "id": 1691,
    "master_id": 1482
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K420 2 GB",
    "name": "Quadro K420 2 GB",
    "condition": "Brand New Sealed",
    "id": 1692,
    "master_id": 1482
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K600 1 GB",
    "name": "Quadro K600 1 GB",
    "condition": "Used",
    "id": 1693,
    "master_id": 1483
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K600 1 GB",
    "name": "Quadro K600 1 GB",
    "condition": "Used With Box",
    "id": 1694,
    "master_id": 1483
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K600 1 GB",
    "name": "Quadro K600 1 GB",
    "condition": "Brand New Sealed",
    "id": 1695,
    "master_id": 1483
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K620 2 GB",
    "name": "Quadro K620 2 GB",
    "condition": "Used",
    "id": 1696,
    "master_id": 1484
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K620 2 GB",
    "name": "Quadro K620 2 GB",
    "condition": "Used With Box",
    "id": 1697,
    "master_id": 1484
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K620 2 GB",
    "name": "Quadro K620 2 GB",
    "condition": "Brand New Sealed",
    "id": 1698,
    "master_id": 1484
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K1200 4 GB",
    "name": "Quadro K1200 4 GB",
    "condition": "Used",
    "id": 1699,
    "master_id": 1485
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K1200 4 GB",
    "name": "Quadro K1200 4 GB",
    "condition": "Used With Box",
    "id": 1700,
    "master_id": 1485
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K1200 4 GB",
    "name": "Quadro K1200 4 GB",
    "condition": "Brand New Sealed",
    "id": 1701,
    "master_id": 1485
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2000D 2 GB",
    "name": "Quadro K2000D 2 GB",
    "condition": "Used",
    "id": 1702,
    "master_id": 1486
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2000D 2 GB",
    "name": "Quadro K2000D 2 GB",
    "condition": "Used With Box",
    "id": 1703,
    "master_id": 1486
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2000D 2 GB",
    "name": "Quadro K2000D 2 GB",
    "condition": "Brand New Sealed",
    "id": 1704,
    "master_id": 1486
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2000 2 GB",
    "name": "Quadro K2000 2 GB",
    "condition": "Used",
    "id": 1705,
    "master_id": 1487
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2000 2 GB",
    "name": "Quadro K2000 2 GB",
    "condition": "Used With Box",
    "id": 1706,
    "master_id": 1487
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2000 2 GB",
    "name": "Quadro K2000 2 GB",
    "condition": "Brand New Sealed",
    "id": 1707,
    "master_id": 1487
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2200 4 GB",
    "name": "Quadro K2200 4 GB",
    "condition": "Used",
    "id": 1708,
    "master_id": 1488
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2200 4 GB",
    "name": "Quadro K2200 4 GB",
    "condition": "Used With Box",
    "id": 1709,
    "master_id": 1488
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K2200 4 GB",
    "name": "Quadro K2200 4 GB",
    "condition": "Brand New Sealed",
    "id": 1710,
    "master_id": 1488
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K4000 3 GB",
    "name": "Quadro K4000 3 GB",
    "condition": "Used",
    "id": 1711,
    "master_id": 1489
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K4000 3 GB",
    "name": "Quadro K4000 3 GB",
    "condition": "Used With Box",
    "id": 1712,
    "master_id": 1489
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K4000 3 GB",
    "name": "Quadro K4000 3 GB",
    "condition": "Brand New Sealed",
    "id": 1713,
    "master_id": 1489
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K4200 4 GB",
    "name": "Quadro K4200 4 GB",
    "condition": "Used",
    "id": 1714,
    "master_id": 1490
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K4200 4 GB",
    "name": "Quadro K4200 4 GB",
    "condition": "Used With Box",
    "id": 1715,
    "master_id": 1490
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K4200 4 GB",
    "name": "Quadro K4200 4 GB",
    "condition": "Brand New Sealed",
    "id": 1716,
    "master_id": 1490
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5000 Mac 4 GB",
    "name": "Quadro K5000 for Mac 4 GB",
    "condition": "Used",
    "id": 1717,
    "master_id": 1491
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5000 Mac 4 GB",
    "name": "Quadro K5000 for Mac 4 GB",
    "condition": "Used With Box",
    "id": 1718,
    "master_id": 1491
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5000 Mac 4 GB",
    "name": "Quadro K5000 for Mac 4 GB",
    "condition": "Brand New Sealed",
    "id": 1719,
    "master_id": 1491
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5000 4 GB",
    "name": "Quadro K5000 4 GB",
    "condition": "Used",
    "id": 1720,
    "master_id": 1492
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5000 4 GB",
    "name": "Quadro K5000 4 GB",
    "condition": "Used With Box",
    "id": 1721,
    "master_id": 1492
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5000 4 GB",
    "name": "Quadro K5000 4 GB",
    "condition": "Brand New Sealed",
    "id": 1722,
    "master_id": 1492
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5200 8 GB",
    "name": "Quadro K5200 8 GB",
    "condition": "Used",
    "id": 1723,
    "master_id": 1493
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5200 8 GB",
    "name": "Quadro K5200 8 GB",
    "condition": "Used With Box",
    "id": 1724,
    "master_id": 1493
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K5200 8 GB",
    "name": "Quadro K5200 8 GB",
    "condition": "Brand New Sealed",
    "id": 1725,
    "master_id": 1493
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K6000 12 GB",
    "name": "Quadro K6000 12 GB",
    "condition": "Used",
    "id": 1726,
    "master_id": 1494
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K6000 12 GB",
    "name": "Quadro K6000 12 GB",
    "condition": "Used With Box",
    "id": 1727,
    "master_id": 1494
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "K6000 12 GB",
    "name": "Quadro K6000 12 GB",
    "condition": "Brand New Sealed",
    "id": 1728,
    "master_id": 1494
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M2000 4 GB",
    "name": "Quadro M2000 4 GB",
    "condition": "Used",
    "id": 1729,
    "master_id": 1495
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M2000 4 GB",
    "name": "Quadro M2000 4 GB",
    "condition": "Used With Box",
    "id": 1730,
    "master_id": 1495
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M2000 4 GB",
    "name": "Quadro M2000 4 GB",
    "condition": "Brand New Sealed",
    "id": 1731,
    "master_id": 1495
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M4000 8 GB",
    "name": "Quadro M4000 8 GB",
    "condition": "Used",
    "id": 1732,
    "master_id": 1496
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M4000 8 GB",
    "name": "Quadro M4000 8 GB",
    "condition": "Used With Box",
    "id": 1733,
    "master_id": 1496
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M4000 8 GB",
    "name": "Quadro M4000 8 GB",
    "condition": "Brand New Sealed",
    "id": 1734,
    "master_id": 1496
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M5000 8 GB",
    "name": "Quadro M5000 8 GB",
    "condition": "Used",
    "id": 1735,
    "master_id": 1497
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M5000 8 GB",
    "name": "Quadro M5000 8 GB",
    "condition": "Used With Box",
    "id": 1736,
    "master_id": 1497
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M5000 8 GB",
    "name": "Quadro M5000 8 GB",
    "condition": "Brand New Sealed",
    "id": 1737,
    "master_id": 1497
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M6000 12 GB",
    "name": "Quadro M6000 12 GB",
    "condition": "Used",
    "id": 1738,
    "master_id": 1498
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M6000 12 GB",
    "name": "Quadro M6000 12 GB",
    "condition": "Used With Box",
    "id": 1739,
    "master_id": 1498
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M6000 12 GB",
    "name": "Quadro M6000 12 GB",
    "condition": "Brand New Sealed",
    "id": 1740,
    "master_id": 1498
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M6000 24 GB",
    "name": "Quadro M6000 24 GB",
    "condition": "Used",
    "id": 1741,
    "master_id": 1499
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M6000 24 GB",
    "name": "Quadro M6000 24 GB",
    "condition": "Used With Box",
    "id": 1742,
    "master_id": 1499
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "M6000 24 GB",
    "name": "Quadro M6000 24 GB",
    "condition": "Brand New Sealed",
    "id": 1743,
    "master_id": 1499
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P400 2 GB",
    "name": "Quadro P400 2 GB",
    "condition": "Used",
    "id": 1744,
    "master_id": 1500
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P400 2 GB",
    "name": "Quadro P400 2 GB",
    "condition": "Used With Box",
    "id": 1745,
    "master_id": 1500
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P400 2 GB",
    "name": "Quadro P400 2 GB",
    "condition": "Brand New Sealed",
    "id": 1746,
    "master_id": 1500
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P620 2 GB",
    "name": "Quadro P620 2 GB",
    "condition": "Used",
    "id": 1747,
    "master_id": 1501
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P620 2 GB",
    "name": "Quadro P620 2 GB",
    "condition": "Used With Box",
    "id": 1748,
    "master_id": 1501
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P620 2 GB",
    "name": "Quadro P620 2 GB",
    "condition": "Brand New Sealed",
    "id": 1749,
    "master_id": 1501
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P1000 4 GB",
    "name": "Quadro P1000 4 GB",
    "condition": "Used",
    "id": 1750,
    "master_id": 1502
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P1000 4 GB",
    "name": "Quadro P1000 4 GB",
    "condition": "Used With Box",
    "id": 1751,
    "master_id": 1502
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P1000 4 GB",
    "name": "Quadro P1000 4 GB",
    "condition": "Brand New Sealed",
    "id": 1752,
    "master_id": 1502
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P2000 5 GB",
    "name": "Quadro P2000 5 GB",
    "condition": "Used",
    "id": 1753,
    "master_id": 1503
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P2000 5 GB",
    "name": "Quadro P2000 5 GB",
    "condition": "Used With Box",
    "id": 1754,
    "master_id": 1503
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P2000 5 GB",
    "name": "Quadro P2000 5 GB",
    "condition": "Brand New Sealed",
    "id": 1755,
    "master_id": 1503
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P2200 5 GB",
    "name": "Quadro P2200 5 GB",
    "condition": "Used",
    "id": 1756,
    "master_id": 1504
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P2200 5 GB",
    "name": "Quadro P2200 5 GB",
    "condition": "Used With Box",
    "id": 1757,
    "master_id": 1504
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P2200 5 GB",
    "name": "Quadro P2200 5 GB",
    "condition": "Brand New Sealed",
    "id": 1758,
    "master_id": 1504
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P4000 8 GB",
    "name": "Quadro P4000 8 GB",
    "condition": "Used",
    "id": 1759,
    "master_id": 1505
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P4000 8 GB",
    "name": "Quadro P4000 8 GB",
    "condition": "Used With Box",
    "id": 1760,
    "master_id": 1505
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P4000 8 GB",
    "name": "Quadro P4000 8 GB",
    "condition": "Brand New Sealed",
    "id": 1761,
    "master_id": 1505
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P5000 16 GB",
    "name": "Quadro P5000 16 GB",
    "condition": "Used",
    "id": 1762,
    "master_id": 1506
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P5000 16 GB",
    "name": "Quadro P5000 16 GB",
    "condition": "Used With Box",
    "id": 1763,
    "master_id": 1506
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P5000 16 GB",
    "name": "Quadro P5000 16 GB",
    "condition": "Brand New Sealed",
    "id": 1764,
    "master_id": 1506
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P6000 24 GB",
    "name": "Quadro P6000 24 GB",
    "condition": "Used",
    "id": 1765,
    "master_id": 1507
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P6000 24 GB",
    "name": "Quadro P6000 24 GB",
    "condition": "Used With Box",
    "id": 1766,
    "master_id": 1507
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "P6000 24 GB",
    "name": "Quadro P6000 24 GB",
    "condition": "Brand New Sealed",
    "id": 1767,
    "master_id": 1507
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "GP100 16 GB",
    "name": "Quadro GP100 16 GB",
    "condition": "Used",
    "id": 1768,
    "master_id": 1508
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "GP100 16 GB",
    "name": "Quadro GP100 16 GB",
    "condition": "Used With Box",
    "id": 1769,
    "master_id": 1508
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "GP100 16 GB",
    "name": "Quadro GP100 16 GB",
    "condition": "Brand New Sealed",
    "id": 1770,
    "master_id": 1508
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "GV100 32 GB",
    "name": "Quadro GV100 32 GB",
    "condition": "Used",
    "id": 1771,
    "master_id": 1509
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "GV100 32 GB",
    "name": "Quadro GV100 32 GB",
    "condition": "Used With Box",
    "id": 1772,
    "master_id": 1509
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "GV100 32 GB",
    "name": "Quadro GV100 32 GB",
    "condition": "Brand New Sealed",
    "id": 1773,
    "master_id": 1509
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T400 2 GB",
    "name": "Quadro T400 2 GB",
    "condition": "Used",
    "id": 1774,
    "master_id": 1510
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T400 2 GB",
    "name": "Quadro T400 2 GB",
    "condition": "Used With Box",
    "id": 1775,
    "master_id": 1510
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T400 2 GB",
    "name": "Quadro T400 2 GB",
    "condition": "Brand New Sealed",
    "id": 1776,
    "master_id": 1510
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T600 4 GB",
    "name": "Quadro T600 4 GB",
    "condition": "Used",
    "id": 1777,
    "master_id": 1511
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T600 4 GB",
    "name": "Quadro T600 4 GB",
    "condition": "Used With Box",
    "id": 1778,
    "master_id": 1511
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T600 4 GB",
    "name": "Quadro T600 4 GB",
    "condition": "Brand New Sealed",
    "id": 1779,
    "master_id": 1511
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T1000 4 GB",
    "name": "Quadro T1000 4 GB",
    "condition": "Used",
    "id": 1780,
    "master_id": 1512
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T1000 4 GB",
    "name": "Quadro T1000 4 GB",
    "condition": "Used With Box",
    "id": 1781,
    "master_id": 1512
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T1000 4 GB",
    "name": "Quadro T1000 4 GB",
    "condition": "Brand New Sealed",
    "id": 1782,
    "master_id": 1512
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T1000 8 GB",
    "name": "Quadro T1000 8 GB",
    "condition": "Used",
    "id": 3660,
    "master_id": 3659
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T1000 8 GB",
    "name": "Quadro T1000 8 GB",
    "condition": "Used With Box",
    "id": 3661,
    "master_id": 3659
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "T1000 8 GB",
    "name": "Quadro T1000 8 GB",
    "condition": "Brand New Sealed",
    "id": 3662,
    "master_id": 3659
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 4000 8 GB",
    "name": "Quadro RTX 4000 8 GB",
    "condition": "Used",
    "id": 1783,
    "master_id": 1513
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 4000 8 GB",
    "name": "Quadro RTX 4000 8 GB",
    "condition": "Used With Box",
    "id": 1784,
    "master_id": 1513
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 4000 8 GB",
    "name": "Quadro RTX 4000 8 GB",
    "condition": "Brand New Sealed",
    "id": 1785,
    "master_id": 1513
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 5000 16 GB",
    "name": "Quadro RTX 5000 16 GB",
    "condition": "Used",
    "id": 1786,
    "master_id": 1514
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 5000 16 GB",
    "name": "Quadro RTX 5000 16 GB",
    "condition": "Used With Box",
    "id": 1787,
    "master_id": 1514
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 5000 16 GB",
    "name": "Quadro RTX 5000 16 GB",
    "condition": "Brand New Sealed",
    "id": 1788,
    "master_id": 1514
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 6000 24 GB",
    "name": "Quadro RTX 6000 24 GB",
    "condition": "Used",
    "id": 1789,
    "master_id": 1515
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 6000 24 GB",
    "name": "Quadro RTX 6000 24 GB",
    "condition": "Used With Box",
    "id": 1790,
    "master_id": 1515
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 6000 24 GB",
    "name": "Quadro RTX 6000 24 GB",
    "condition": "Brand New Sealed",
    "id": 1791,
    "master_id": 1515
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 8000 48 GB",
    "name": "Quadro RTX 8000 48 GB",
    "condition": "Used",
    "id": 1792,
    "master_id": 1516
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 8000 48 GB",
    "name": "Quadro RTX 8000 48 GB",
    "condition": "Used With Box",
    "id": 1793,
    "master_id": 1516
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 8000 48 GB",
    "name": "Quadro RTX 8000 48 GB",
    "condition": "Brand New Sealed",
    "id": 1794,
    "master_id": 1516
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 8000 (Passive) 48 GB",
    "name": "Quadro RTX 8000 (Passive) 48 GB",
    "condition": "Used",
    "id": 7923,
    "master_id": 7906
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 8000 (Passive) 48 GB",
    "name": "Quadro RTX 8000 (Passive) 48 GB",
    "condition": "Used With Box",
    "id": 7924,
    "master_id": 7906
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Quadro",
    "model": "RTX 8000 (Passive) 48 GB",
    "name": "Quadro RTX 8000 (Passive) 48 GB",
    "condition": "Brand New Sealed",
    "id": 7925,
    "master_id": 7906
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A2000 6 GB",
    "name": "RTX A2000 6 GB",
    "condition": "Used",
    "id": 1795,
    "master_id": 1517
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A2000 6 GB",
    "name": "RTX A2000 6 GB",
    "condition": "Used With Box",
    "id": 1796,
    "master_id": 1517
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A2000 6 GB",
    "name": "RTX A2000 6 GB",
    "condition": "Brand New Sealed",
    "id": 1797,
    "master_id": 1517
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A2000 12 GB",
    "name": "RTX A2000 12 GB",
    "condition": "Used",
    "id": 1798,
    "master_id": 1518
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A2000 12 GB",
    "name": "RTX A2000 12 GB",
    "condition": "Used With Box",
    "id": 1799,
    "master_id": 1518
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A2000 12 GB",
    "name": "RTX A2000 12 GB",
    "condition": "Brand New Sealed",
    "id": 1800,
    "master_id": 1518
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A4000 16 GB",
    "name": "RTX A4000 16 GB",
    "condition": "Used",
    "id": 1801,
    "master_id": 1519
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A4000 16 GB",
    "name": "RTX A4000 16 GB",
    "condition": "Used With Box",
    "id": 1802,
    "master_id": 1519
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A4000 16 GB",
    "name": "RTX A4000 16 GB",
    "condition": "Brand New Sealed",
    "id": 1803,
    "master_id": 1519
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A4500 20 GB",
    "name": "RTX A4500 20 GB",
    "condition": "Used",
    "id": 1804,
    "master_id": 1520
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A4500 20 GB",
    "name": "RTX A4500 20 GB",
    "condition": "Used With Box",
    "id": 1805,
    "master_id": 1520
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A4500 20 GB",
    "name": "RTX A4500 20 GB",
    "condition": "Brand New Sealed",
    "id": 1806,
    "master_id": 1520
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A5000 24 GB",
    "name": "RTX A5000 24 GB",
    "condition": "Used",
    "id": 1807,
    "master_id": 1521
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A5000 24 GB",
    "name": "RTX A5000 24 GB",
    "condition": "Used With Box",
    "id": 1808,
    "master_id": 1521
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A5000 24 GB",
    "name": "RTX A5000 24 GB",
    "condition": "Brand New Sealed",
    "id": 1809,
    "master_id": 1521
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A5500 24 GB",
    "name": "RTX A5500 24 GB",
    "condition": "Used",
    "id": 1810,
    "master_id": 1522
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A5500 24 GB",
    "name": "RTX A5500 24 GB",
    "condition": "Used With Box",
    "id": 1811,
    "master_id": 1522
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A5500 24 GB",
    "name": "RTX A5500 24 GB",
    "condition": "Brand New Sealed",
    "id": 1812,
    "master_id": 1522
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A6000 48 GB",
    "name": "RTX A6000 48 GB",
    "condition": "Used",
    "id": 1813,
    "master_id": 1523
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A6000 48 GB",
    "name": "RTX A6000 48 GB",
    "condition": "Used With Box",
    "id": 1814,
    "master_id": 1523
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "A Series",
    "model": "RTX A6000 48 GB",
    "name": "RTX A6000 48 GB",
    "condition": "Brand New Sealed",
    "id": 1815,
    "master_id": 1523
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "4000 Ada 20 GB",
    "name": "RTX 4000 Ada 20 GB",
    "condition": "Used",
    "id": 7911,
    "master_id": 7902
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "4000 Ada 20 GB",
    "name": "RTX 4000 Ada 20 GB",
    "condition": "Used With Box",
    "id": 7912,
    "master_id": 7902
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "4000 Ada 20 GB",
    "name": "RTX 4000 Ada 20 GB",
    "condition": "Brand New Sealed",
    "id": 7913,
    "master_id": 7902
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "4500 Ada 24 GB",
    "name": "RTX 4500 Ada 24 GB",
    "condition": "Used",
    "id": 7914,
    "master_id": 7903
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "4500 Ada 24 GB",
    "name": "RTX 4500 Ada 24 GB",
    "condition": "Used With Box",
    "id": 7915,
    "master_id": 7903
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "4500 Ada 24 GB",
    "name": "RTX 4500 Ada 24 GB",
    "condition": "Brand New Sealed",
    "id": 7916,
    "master_id": 7903
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "5000 Ada 32 GB",
    "name": "RTX 5000 Ada 32 GB",
    "condition": "Used",
    "id": 7917,
    "master_id": 7904
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "5000 Ada 32 GB",
    "name": "RTX 5000 Ada 32 GB",
    "condition": "Used With Box",
    "id": 7918,
    "master_id": 7904
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "5000 Ada 32 GB",
    "name": "RTX 5000 Ada 32 GB",
    "condition": "Brand New Sealed",
    "id": 7919,
    "master_id": 7904
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "6000 Ada 48 GB",
    "name": "RTX 6000 Ada 48 GB",
    "condition": "Used",
    "id": 7920,
    "master_id": 7905
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "6000 Ada 48 GB",
    "name": "RTX 6000 Ada 48 GB",
    "condition": "Used With Box",
    "id": 7921,
    "master_id": 7905
  },
  {
    "type": "GPU",
    "manufacturer": "Nvidia",
    "series": "Ada Series",
    "model": "6000 Ada 48 GB",
    "name": "RTX 6000 Ada 48 GB",
    "condition": "Brand New Sealed",
    "id": 7922,
    "master_id": 7905
  }
]

let amdGPUArray = [
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260 1 GB",
    "name": "R7 260 1 GB",
    "condition": "Used",
    "id": 3078,
    "master_id": 3008
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260 1 GB",
    "name": "R7 260 1 GB",
    "condition": "Used With Box",
    "id": 3079,
    "master_id": 3008
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260 1 GB",
    "name": "R7 260 1 GB",
    "condition": "Brand New Sealed",
    "id": 3080,
    "master_id": 3008
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260X 1 GB",
    "name": "R7 260X 1 GB",
    "condition": "Used",
    "id": 3081,
    "master_id": 3009
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260X 1 GB",
    "name": "R7 260X 1 GB",
    "condition": "Used With Box",
    "id": 3082,
    "master_id": 3009
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260X 1 GB",
    "name": "R7 260X 1 GB",
    "condition": "Brand New Sealed",
    "id": 3083,
    "master_id": 3009
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260X 2 GB",
    "name": "R7 260X 2 GB",
    "condition": "Used",
    "id": 3084,
    "master_id": 3010
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260X 2 GB",
    "name": "R7 260X 2 GB",
    "condition": "Used With Box",
    "id": 3085,
    "master_id": 3010
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "260X 2 GB",
    "name": "R7 260X 2 GB",
    "condition": "Brand New Sealed",
    "id": 3086,
    "master_id": 3010
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "265 2 GB",
    "name": "R7 265 2 GB",
    "condition": "Used",
    "id": 3087,
    "master_id": 3011
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "265 2 GB",
    "name": "R7 265 2 GB",
    "condition": "Used With Box",
    "id": 3088,
    "master_id": 3011
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "265 2 GB",
    "name": "R7 265 2 GB",
    "condition": "Brand New Sealed",
    "id": 3089,
    "master_id": 3011
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "370 2 GB",
    "name": "R7 370 2 GB",
    "condition": "Used",
    "id": 3093,
    "master_id": 3013
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "370 2 GB",
    "name": "R7 370 2 GB",
    "condition": "Used With Box",
    "id": 3094,
    "master_id": 3013
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "370 2 GB",
    "name": "R7 370 2 GB",
    "condition": "Brand New Sealed",
    "id": 3095,
    "master_id": 3013
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "370 4 GB",
    "name": "R7 370 4 GB",
    "condition": "Used",
    "id": 3096,
    "master_id": 3014
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "370 4 GB",
    "name": "R7 370 4 GB",
    "condition": "Used With Box",
    "id": 3097,
    "master_id": 3014
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R7",
    "model": "370 4 GB",
    "name": "R7 370 4 GB",
    "condition": "Brand New Sealed",
    "id": 3098,
    "master_id": 3014
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270 2 GB",
    "name": "R9 270 2 GB",
    "condition": "Used",
    "id": 3099,
    "master_id": 3015
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270 2 GB",
    "name": "R9 270 2 GB",
    "condition": "Used With Box",
    "id": 3100,
    "master_id": 3015
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270 2 GB",
    "name": "R9 270 2 GB",
    "condition": "Brand New Sealed",
    "id": 3101,
    "master_id": 3015
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270X 2 GB",
    "name": "R9 270X 2 GB",
    "condition": "Used",
    "id": 3102,
    "master_id": 3016
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270X 2 GB",
    "name": "R9 270X 2 GB",
    "condition": "Used With Box",
    "id": 3103,
    "master_id": 3016
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270X 2 GB",
    "name": "R9 270X 2 GB",
    "condition": "Brand New Sealed",
    "id": 3104,
    "master_id": 3016
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270X 4 GB",
    "name": "R9 270X 4 GB",
    "condition": "Used",
    "id": 3105,
    "master_id": 3017
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270X 4 GB",
    "name": "R9 270X 4 GB",
    "condition": "Used With Box",
    "id": 3106,
    "master_id": 3017
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "270X 4 GB",
    "name": "R9 270X 4 GB",
    "condition": "Brand New Sealed",
    "id": 3107,
    "master_id": 3017
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "280 3 GB",
    "name": "R9 280 3 GB",
    "condition": "Used",
    "id": 3108,
    "master_id": 3018
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "280 3 GB",
    "name": "R9 280 3 GB",
    "condition": "Used With Box",
    "id": 3109,
    "master_id": 3018
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "280 3 GB",
    "name": "R9 280 3 GB",
    "condition": "Brand New Sealed",
    "id": 3110,
    "master_id": 3018
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "280X 3 GB",
    "name": "R9 280X 3 GB",
    "condition": "Used",
    "id": 3111,
    "master_id": 3019
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "280X 3 GB",
    "name": "R9 280X 3 GB",
    "condition": "Used With Box",
    "id": 3112,
    "master_id": 3019
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "280X 3 GB",
    "name": "R9 280X 3 GB",
    "condition": "Brand New Sealed",
    "id": 3113,
    "master_id": 3019
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "285 2 GB",
    "name": "R9 285 2 GB",
    "condition": "Used",
    "id": 3114,
    "master_id": 3020
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "285 2 GB",
    "name": "R9 285 2 GB",
    "condition": "Used With Box",
    "id": 3115,
    "master_id": 3020
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "285 2 GB",
    "name": "R9 285 2 GB",
    "condition": "Brand New Sealed",
    "id": 3116,
    "master_id": 3020
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "290 4 GB",
    "name": "R9 290 4 GB",
    "condition": "Used",
    "id": 3117,
    "master_id": 3021
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "290 4 GB",
    "name": "R9 290 4 GB",
    "condition": "Used With Box",
    "id": 3118,
    "master_id": 3021
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "290 4 GB",
    "name": "R9 290 4 GB",
    "condition": "Brand New Sealed",
    "id": 3119,
    "master_id": 3021
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "290X 4 GB",
    "name": "R9 290X 4 GB",
    "condition": "Used",
    "id": 3120,
    "master_id": 3022
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "290X 4 GB",
    "name": "R9 290X 4 GB",
    "condition": "Used With Box",
    "id": 3121,
    "master_id": 3022
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "290X 4 GB",
    "name": "R9 290X 4 GB",
    "condition": "Brand New Sealed",
    "id": 3122,
    "master_id": 3022
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "295X2 12 GB",
    "name": "R9 295X2 8 GB",
    "condition": "Used",
    "id": 3123,
    "master_id": 3023
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "295X2 12 GB",
    "name": "R9 295X2 8 GB",
    "condition": "Used With Box",
    "id": 3124,
    "master_id": 3023
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "295X2 12 GB",
    "name": "R9 295X2 8 GB",
    "condition": "Brand New Sealed",
    "id": 3125,
    "master_id": 3023
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "360 2 GB",
    "name": "R9 360 2 GB",
    "condition": "Used",
    "id": 3126,
    "master_id": 3012
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "360 2 GB",
    "name": "R9 360 2 GB",
    "condition": "Used With Box",
    "id": 3127,
    "master_id": 3012
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "360 2 GB",
    "name": "R9 360 2 GB",
    "condition": "Brand New Sealed",
    "id": 3128,
    "master_id": 3012
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "370X 2 GB",
    "name": "R9 370X 2 GB",
    "condition": "Used",
    "id": 3135,
    "master_id": 3027
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "370X 2 GB",
    "name": "R9 370X 2 GB",
    "condition": "Used With Box",
    "id": 3136,
    "master_id": 3027
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "370X 2 GB",
    "name": "R9 370X 2 GB",
    "condition": "Brand New Sealed",
    "id": 3137,
    "master_id": 3027
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "370X 4 GB",
    "name": "R9 370X 4 GB",
    "condition": "Used",
    "id": 3138,
    "master_id": 3028
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "370X 4 GB",
    "name": "R9 370X 4 GB",
    "condition": "Used With Box",
    "id": 3139,
    "master_id": 3028
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "370X 4 GB",
    "name": "R9 370X 4 GB",
    "condition": "Brand New Sealed",
    "id": 3140,
    "master_id": 3028
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380 2 GB",
    "name": "R9 380 2 GB",
    "condition": "Used",
    "id": 3141,
    "master_id": 3029
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380 2 GB",
    "name": "R9 380 2 GB",
    "condition": "Used With Box",
    "id": 3142,
    "master_id": 3029
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380 2 GB",
    "name": "R9 380 2 GB",
    "condition": "Brand New Sealed",
    "id": 3143,
    "master_id": 3029
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380 4 GB",
    "name": "R9 380 4 GB",
    "condition": "Used",
    "id": 3144,
    "master_id": 3030
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380 4 GB",
    "name": "R9 380 4 GB",
    "condition": "Used With Box",
    "id": 3145,
    "master_id": 3030
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380 4 GB",
    "name": "R9 380 4 GB",
    "condition": "Brand New Sealed",
    "id": 3146,
    "master_id": 3030
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380X 4 GB",
    "name": "R9 380X 4 GB",
    "condition": "Used",
    "id": 3147,
    "master_id": 3031
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380X 4 GB",
    "name": "R9 380X 4 GB",
    "condition": "Used With Box",
    "id": 3148,
    "master_id": 3031
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "380X 4 GB",
    "name": "R9 380X 4 GB",
    "condition": "Brand New Sealed",
    "id": 3149,
    "master_id": 3031
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "390 8 GB",
    "name": "R9 390 8 GB",
    "condition": "Used",
    "id": 3150,
    "master_id": 3032
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "390 8 GB",
    "name": "R9 390 8 GB",
    "condition": "Used With Box",
    "id": 3151,
    "master_id": 3032
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "390 8 GB",
    "name": "R9 390 8 GB",
    "condition": "Brand New Sealed",
    "id": 3152,
    "master_id": 3032
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "390X 8 GB",
    "name": "R9 390X 8 GB",
    "condition": "Used",
    "id": 3153,
    "master_id": 3033
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "390X 8 GB",
    "name": "R9 390X 8 GB",
    "condition": "Used With Box",
    "id": 3154,
    "master_id": 3033
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "390X 8 GB",
    "name": "R9 390X 8 GB",
    "condition": "Brand New Sealed",
    "id": 3155,
    "master_id": 3033
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Nano 4 GB",
    "name": "R9 Nano 4 GB",
    "condition": "Used",
    "id": 3156,
    "master_id": 3034
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Nano 4 GB",
    "name": "R9 Nano 4 GB",
    "condition": "Used With Box",
    "id": 3157,
    "master_id": 3034
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Nano 4 GB",
    "name": "R9 Nano 4 GB",
    "condition": "Brand New Sealed",
    "id": 3158,
    "master_id": 3034
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Fury X 4 GB",
    "name": "R9 Fury X 4 GB",
    "condition": "Used",
    "id": 3159,
    "master_id": 3035
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Fury X 4 GB",
    "name": "R9 Fury X 4 GB",
    "condition": "Used With Box",
    "id": 3160,
    "master_id": 3035
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Fury X 4 GB",
    "name": "R9 Fury X 4 GB",
    "condition": "Brand New Sealed",
    "id": 3161,
    "master_id": 3035
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Fury 4 GB",
    "name": "R9 Fury 4 GB",
    "condition": "Used",
    "id": 3162,
    "master_id": 3036
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Fury 4 GB",
    "name": "R9 Fury 4 GB",
    "condition": "Used With Box",
    "id": 3163,
    "master_id": 3036
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "R9",
    "model": "Fury 4 GB",
    "name": "R9 Fury 4 GB",
    "condition": "Brand New Sealed",
    "id": 3164,
    "master_id": 3036
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "460 2 GB",
    "name": "RX 460 2 GB",
    "condition": "Used",
    "id": 3165,
    "master_id": 3037
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "460 2 GB",
    "name": "RX 460 2 GB",
    "condition": "Used With Box",
    "id": 3166,
    "master_id": 3037
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "460 2 GB",
    "name": "RX 460 2 GB",
    "condition": "Brand New Sealed",
    "id": 3167,
    "master_id": 3037
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "460 4 GB",
    "name": "RX 460 4 GB",
    "condition": "Used",
    "id": 3168,
    "master_id": 3038
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "460 4 GB",
    "name": "RX 460 4 GB",
    "condition": "Used With Box",
    "id": 3169,
    "master_id": 3038
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "460 4 GB",
    "name": "RX 460 4 GB",
    "condition": "Brand New Sealed",
    "id": 3170,
    "master_id": 3038
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "470 4 GB",
    "name": "RX 470 4 GB",
    "condition": "Used",
    "id": 3171,
    "master_id": 3039
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "470 4 GB",
    "name": "RX 470 4 GB",
    "condition": "Used With Box",
    "id": 3172,
    "master_id": 3039
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "470 4 GB",
    "name": "RX 470 4 GB",
    "condition": "Brand New Sealed",
    "id": 3173,
    "master_id": 3039
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "470 8 GB",
    "name": "RX 470 8 GB",
    "condition": "Used",
    "id": 3174,
    "master_id": 3040
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "470 8 GB",
    "name": "RX 470 8 GB",
    "condition": "Used With Box",
    "id": 3175,
    "master_id": 3040
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "470 8 GB",
    "name": "RX 470 8 GB",
    "condition": "Brand New Sealed",
    "id": 3176,
    "master_id": 3040
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "480 4 GB",
    "name": "RX 480 4 GB",
    "condition": "Used",
    "id": 3177,
    "master_id": 3041
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "480 4 GB",
    "name": "RX 480 4 GB",
    "condition": "Used With Box",
    "id": 3178,
    "master_id": 3041
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "480 4 GB",
    "name": "RX 480 4 GB",
    "condition": "Brand New Sealed",
    "id": 3179,
    "master_id": 3041
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "480 8 GB",
    "name": "RX 480 8 GB",
    "condition": "Used",
    "id": 3180,
    "master_id": 3042
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "480 8 GB",
    "name": "RX 480 8 GB",
    "condition": "Used With Box",
    "id": 3181,
    "master_id": 3042
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 400",
    "model": "480 8 GB",
    "name": "RX 480 8 GB",
    "condition": "Brand New Sealed",
    "id": 3182,
    "master_id": 3042
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "550 2 GB",
    "name": "RX 550 2 GB",
    "condition": "Used",
    "id": 3183,
    "master_id": 3043
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "550 2 GB",
    "name": "RX 550 2 GB",
    "condition": "Used With Box",
    "id": 3184,
    "master_id": 3043
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "550 2 GB",
    "name": "RX 550 2 GB",
    "condition": "Brand New Sealed",
    "id": 3185,
    "master_id": 3043
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "550 4 GB",
    "name": "RX 550 4 GB",
    "condition": "Used",
    "id": 3186,
    "master_id": 3044
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "550 4 GB",
    "name": "RX 550 4 GB",
    "condition": "Used With Box",
    "id": 3187,
    "master_id": 3044
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "550 4 GB",
    "name": "RX 550 4 GB",
    "condition": "Brand New Sealed",
    "id": 3188,
    "master_id": 3044
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "560 2 GB",
    "name": "RX 560 2 GB",
    "condition": "Used",
    "id": 3189,
    "master_id": 3045
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "560 2 GB",
    "name": "RX 560 2 GB",
    "condition": "Used With Box",
    "id": 3190,
    "master_id": 3045
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "560 2 GB",
    "name": "RX 560 2 GB",
    "condition": "Brand New Sealed",
    "id": 3191,
    "master_id": 3045
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "560 4 GB",
    "name": "RX 560 4 GB",
    "condition": "Used",
    "id": 3192,
    "master_id": 3046
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "560 4 GB",
    "name": "RX 560 4 GB",
    "condition": "Used With Box",
    "id": 3193,
    "master_id": 3046
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "560 4 GB",
    "name": "RX 560 4 GB",
    "condition": "Brand New Sealed",
    "id": 3194,
    "master_id": 3046
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "570 4 GB",
    "name": "RX 570 4 GB",
    "condition": "Used",
    "id": 3195,
    "master_id": 3047
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "570 4 GB",
    "name": "RX 570 4 GB",
    "condition": "Used With Box",
    "id": 3196,
    "master_id": 3047
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "570 4 GB",
    "name": "RX 570 4 GB",
    "condition": "Brand New Sealed",
    "id": 3197,
    "master_id": 3047
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "570 8 GB",
    "name": "RX 570 8 GB",
    "condition": "Used",
    "id": 3198,
    "master_id": 3048
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "570 8 GB",
    "name": "RX 570 8 GB",
    "condition": "Used With Box",
    "id": 3199,
    "master_id": 3048
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "570 8 GB",
    "name": "RX 570 8 GB",
    "condition": "Brand New Sealed",
    "id": 3200,
    "master_id": 3048
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "580 4 GB",
    "name": "RX 580 4 GB",
    "condition": "Used",
    "id": 3201,
    "master_id": 3049
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "580 4 GB",
    "name": "RX 580 4 GB",
    "condition": "Used With Box",
    "id": 3202,
    "master_id": 3049
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "580 4 GB",
    "name": "RX 580 4 GB",
    "condition": "Brand New Sealed",
    "id": 3203,
    "master_id": 3049
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "580 8 GB",
    "name": "RX 580 8 GB",
    "condition": "Used",
    "id": 3204,
    "master_id": 3050
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "580 8 GB",
    "name": "RX 580 8 GB",
    "condition": "Used With Box",
    "id": 3205,
    "master_id": 3050
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "580 8 GB",
    "name": "RX 580 8 GB",
    "condition": "Brand New Sealed",
    "id": 3206,
    "master_id": 3050
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "590 8 GB",
    "name": "RX 590 8 GB",
    "condition": "Used",
    "id": 3207,
    "master_id": 3051
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "590 8 GB",
    "name": "RX 590 8 GB",
    "condition": "Used With Box",
    "id": 3208,
    "master_id": 3051
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 500",
    "model": "590 8 GB",
    "name": "RX 590 8 GB",
    "condition": "Brand New Sealed",
    "id": 3209,
    "master_id": 3051
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5500 XT 4 GB",
    "name": "RX 5500 XT 4 GB",
    "condition": "Used",
    "id": 3210,
    "master_id": 3052
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5500 XT 4 GB",
    "name": "RX 5500 XT 4 GB",
    "condition": "Used With Box",
    "id": 3211,
    "master_id": 3052
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5500 XT 4 GB",
    "name": "RX 5500 XT 4 GB",
    "condition": "Brand New Sealed",
    "id": 3212,
    "master_id": 3052
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5500 XT 8 GB",
    "name": "RX 5500 XT 8 GB",
    "condition": "Used",
    "id": 3213,
    "master_id": 3053
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5500 XT 8 GB",
    "name": "RX 5500 XT 8 GB",
    "condition": "Used With Box",
    "id": 3214,
    "master_id": 3053
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5500 XT 8 GB",
    "name": "RX 5500 XT 8 GB",
    "condition": "Brand New Sealed",
    "id": 3215,
    "master_id": 3053
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5600 6 GB",
    "name": "RX 5600 6 GB",
    "condition": "Used",
    "id": 3216,
    "master_id": 3054
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5600 6 GB",
    "name": "RX 5600 6 GB",
    "condition": "Used With Box",
    "id": 3217,
    "master_id": 3054
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5600 6 GB",
    "name": "RX 5600 6 GB",
    "condition": "Brand New Sealed",
    "id": 3218,
    "master_id": 3054
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5600 XT 6 GB",
    "name": "RX 5600 XT 6 GB",
    "condition": "Used",
    "id": 3219,
    "master_id": 3055
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5600 XT 6 GB",
    "name": "RX 5600 XT 6 GB",
    "condition": "Used With Box",
    "id": 3220,
    "master_id": 3055
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5600 XT 6 GB",
    "name": "RX 5600 XT 6 GB",
    "condition": "Brand New Sealed",
    "id": 3221,
    "master_id": 3055
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5700 8 GB",
    "name": "RX 5700 8 GB",
    "condition": "Used",
    "id": 3222,
    "master_id": 3056
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5700 8 GB",
    "name": "RX 5700 8 GB",
    "condition": "Used With Box",
    "id": 3223,
    "master_id": 3056
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5700 8 GB",
    "name": "RX 5700 8 GB",
    "condition": "Brand New Sealed",
    "id": 3224,
    "master_id": 3056
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5700 XT 8 GB",
    "name": "RX 5700 XT 8 GB",
    "condition": "Used",
    "id": 3225,
    "master_id": 3057
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5700 XT 8 GB",
    "name": "RX 5700 XT 8 GB",
    "condition": "Used With Box",
    "id": 3226,
    "master_id": 3057
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 5000",
    "model": "5700 XT 8 GB",
    "name": "RX 5700 XT 8 GB",
    "condition": "Brand New Sealed",
    "id": 3227,
    "master_id": 3057
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6300 2 GB",
    "name": "RX 6300 2 GB",
    "condition": "Used",
    "id": 3228,
    "master_id": 3058
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6300 2 GB",
    "name": "RX 6300 2 GB",
    "condition": "Used With Box",
    "id": 3229,
    "master_id": 3058
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6300 2 GB",
    "name": "RX 6300 2 GB",
    "condition": "Brand New Sealed",
    "id": 3230,
    "master_id": 3058
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6400 4 GB",
    "name": "RX 6400 4 GB",
    "condition": "Used",
    "id": 3231,
    "master_id": 3059
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6400 4 GB",
    "name": "RX 6400 4 GB",
    "condition": "Used With Box",
    "id": 3232,
    "master_id": 3059
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6400 4 GB",
    "name": "RX 6400 4 GB",
    "condition": "Brand New Sealed",
    "id": 3233,
    "master_id": 3059
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6500 XT 4 GB",
    "name": "RX 6500 XT 4 GB",
    "condition": "Used",
    "id": 3234,
    "master_id": 3060
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6500 XT 4 GB",
    "name": "RX 6500 XT 4 GB",
    "condition": "Used With Box",
    "id": 3235,
    "master_id": 3060
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6500 XT 4 GB",
    "name": "RX 6500 XT 4 GB",
    "condition": "Brand New Sealed",
    "id": 3236,
    "master_id": 3060
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6600 8 GB",
    "name": "RX 6600 8 GB",
    "condition": "Used",
    "id": 3237,
    "master_id": 3061
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6600 8 GB",
    "name": "RX 6600 8 GB",
    "condition": "Used With Box",
    "id": 3238,
    "master_id": 3061
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6600 8 GB",
    "name": "RX 6600 8 GB",
    "condition": "Brand New Sealed",
    "id": 3239,
    "master_id": 3061
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6600 XT 8 GB",
    "name": "RX 6600 XT 8 GB",
    "condition": "Used",
    "id": 3240,
    "master_id": 3062
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6600 XT 8 GB",
    "name": "RX 6600 XT 8 GB",
    "condition": "Used With Box",
    "id": 3241,
    "master_id": 3062
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6600 XT 8 GB",
    "name": "RX 6600 XT 8 GB",
    "condition": "Brand New Sealed",
    "id": 3242,
    "master_id": 3062
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6650 XT 8 GB",
    "name": "RX 6650 XT 8 GB",
    "condition": "Used",
    "id": 3243,
    "master_id": 3063
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6650 XT 8 GB",
    "name": "RX 6650 XT 8 GB",
    "condition": "Used With Box",
    "id": 3244,
    "master_id": 3063
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6650 XT 8 GB",
    "name": "RX 6650 XT 8 GB",
    "condition": "Brand New Sealed",
    "id": 3245,
    "master_id": 3063
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 10 GB",
    "name": "RX 6700 10 GB",
    "condition": "Used",
    "id": 7579,
    "master_id": 7566
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 10 GB",
    "name": "RX 6700 10 GB",
    "condition": "Used With Box",
    "id": 7580,
    "master_id": 7566
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 10 GB",
    "name": "RX 6700 10 GB",
    "condition": "Brand New Sealed",
    "id": 7581,
    "master_id": 7566
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 12 GB",
    "name": "RX 6700 12 GB",
    "condition": "Used",
    "id": 3246,
    "master_id": 3064
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 12 GB",
    "name": "RX 6700 12 GB",
    "condition": "Used With Box",
    "id": 3247,
    "master_id": 3064
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 12 GB",
    "name": "RX 6700 12 GB",
    "condition": "Brand New Sealed",
    "id": 3248,
    "master_id": 3064
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 XT 12 GB",
    "name": "RX 6700 XT 12 GB",
    "condition": "Used",
    "id": 3249,
    "master_id": 3065
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 XT 12 GB",
    "name": "RX 6700 XT 12 GB",
    "condition": "Used With Box",
    "id": 3250,
    "master_id": 3065
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6700 XT 12 GB",
    "name": "RX 6700 XT 12 GB",
    "condition": "Brand New Sealed",
    "id": 3251,
    "master_id": 3065
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6750 XT 12 GB",
    "name": "RX 6750 XT 12 GB",
    "condition": "Used",
    "id": 3252,
    "master_id": 3066
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6750 XT 12 GB",
    "name": "RX 6750 XT 12 GB",
    "condition": "Used With Box",
    "id": 3253,
    "master_id": 3066
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6750 XT 12 GB",
    "name": "RX 6750 XT 12 GB",
    "condition": "Brand New Sealed",
    "id": 3254,
    "master_id": 3066
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6800 16 GB",
    "name": "RX 6800 16 GB",
    "condition": "Used",
    "id": 3255,
    "master_id": 3067
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6800 16 GB",
    "name": "RX 6800 16 GB",
    "condition": "Used With Box",
    "id": 3256,
    "master_id": 3067
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6800 16 GB",
    "name": "RX 6800 16 GB",
    "condition": "Brand New Sealed",
    "id": 3257,
    "master_id": 3067
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6800 XT 16 GB",
    "name": "RX 6800 XT 16 GB",
    "condition": "Used",
    "id": 3258,
    "master_id": 3068
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6800 XT 16 GB",
    "name": "RX 6800 XT 16 GB",
    "condition": "Used With Box",
    "id": 3259,
    "master_id": 3068
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6800 XT 16 GB",
    "name": "RX 6800 XT 16 GB",
    "condition": "Brand New Sealed",
    "id": 3260,
    "master_id": 3068
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6900 XT 16 GB",
    "name": "RX 6900 XT 16 GB",
    "condition": "Used",
    "id": 3261,
    "master_id": 3069
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6900 XT 16 GB",
    "name": "RX 6900 XT 16 GB",
    "condition": "Used With Box",
    "id": 3262,
    "master_id": 3069
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6900 XT 16 GB",
    "name": "RX 6900 XT 16 GB",
    "condition": "Brand New Sealed",
    "id": 3263,
    "master_id": 3069
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6950 XT 16 GB",
    "name": "RX 6950 XT 16 GB",
    "condition": "Used",
    "id": 3264,
    "master_id": 3070
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6950 XT 16 GB",
    "name": "RX 6950 XT 16 GB",
    "condition": "Used With Box",
    "id": 3265,
    "master_id": 3070
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 6000",
    "model": "6950 XT 16 GB",
    "name": "RX 6950 XT 16 GB",
    "condition": "Brand New Sealed",
    "id": 3266,
    "master_id": 3070
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7600 8 GB",
    "name": "RX 7600 8 GB",
    "condition": "Used",
    "id": 3641,
    "master_id": 3640
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7600 8 GB",
    "name": "RX 7600 8 GB",
    "condition": "Used With Box",
    "id": 3642,
    "master_id": 3640
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7600 8 GB",
    "name": "RX 7600 8 GB",
    "condition": "Brand New Sealed",
    "id": 3643,
    "master_id": 3640
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7600 XT 16 GB",
    "name": "RX 7600 XT 16 GB",
    "condition": "Used",
    "id": 7573,
    "master_id": 7564
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7600 XT 16 GB",
    "name": "RX 7600 XT 16 GB",
    "condition": "Used With Box",
    "id": 7574,
    "master_id": 7564
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7600 XT 16 GB",
    "name": "RX 7600 XT 16 GB",
    "condition": "Brand New Sealed",
    "id": 7575,
    "master_id": 7564
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7700 XT 12 GB",
    "name": "RX 7700 XT 12 GB",
    "condition": "Used",
    "id": 7570,
    "master_id": 7563
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7700 XT 12 GB",
    "name": "RX 7700 XT 12 GB",
    "condition": "Used With Box",
    "id": 7571,
    "master_id": 7563
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7700 XT 12 GB",
    "name": "RX 7700 XT 12 GB",
    "condition": "Brand New Sealed",
    "id": 7572,
    "master_id": 7563
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7800 XT 16 GB",
    "name": "RX 7800 XT 16 GB",
    "condition": "Used",
    "id": 7567,
    "master_id": 7562
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7800 XT 16 GB",
    "name": "RX 7800 XT 16 GB",
    "condition": "Used With Box",
    "id": 7568,
    "master_id": 7562
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7800 XT 16 GB",
    "name": "RX 7800 XT 16 GB",
    "condition": "Brand New Sealed",
    "id": 7569,
    "master_id": 7562
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7900 GRE 16 GB",
    "name": "RX 7900 GRE 16 GB",
    "condition": "Used",
    "id": 7576,
    "master_id": 7565
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7900 GRE 16 GB",
    "name": "RX 7900 GRE 16 GB",
    "condition": "Used With Box",
    "id": 7577,
    "master_id": 7565
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "7900 GRE 16 GB",
    "name": "RX 7900 GRE 16 GB",
    "condition": "Brand New Sealed",
    "id": 7578,
    "master_id": 7565
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "RX 7900 XT 20 GB",
    "name": "RX 7900 XT 20 GB",
    "condition": "Used",
    "id": 3267,
    "master_id": 3071
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "RX 7900 XT 20 GB",
    "name": "RX 7900 XT 20 GB",
    "condition": "Used With Box",
    "id": 3268,
    "master_id": 3071
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "RX 7900 XT 20 GB",
    "name": "RX 7900 XT 20 GB",
    "condition": "Brand New Sealed",
    "id": 3269,
    "master_id": 3071
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "RX 7900 XTX 24 GB",
    "name": "RX 7900 XTX 24 GB",
    "condition": "Used",
    "id": 3270,
    "master_id": 3072
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "RX 7900 XTX 24 GB",
    "name": "RX 7900 XTX 24 GB",
    "condition": "Used With Box",
    "id": 3271,
    "master_id": 3072
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "RX 7000",
    "model": "RX 7900 XTX 24 GB",
    "name": "RX 7900 XTX 24 GB",
    "condition": "Brand New Sealed",
    "id": 3272,
    "master_id": 3072
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Frontier Edition 16 GB",
    "name": "RX Vega Frontier Edition 16 GB",
    "condition": "Used",
    "id": 3273,
    "master_id": 3073
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Frontier Edition 16 GB",
    "name": "RX Vega Frontier Edition 16 GB",
    "condition": "Used With Box",
    "id": 3274,
    "master_id": 3073
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Frontier Edition 16 GB",
    "name": "RX Vega Frontier Edition 16 GB",
    "condition": "Brand New Sealed",
    "id": 3275,
    "master_id": 3073
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 64 Liquid 8 GB",
    "name": "RX Vega 64 Liquid 8 GB",
    "condition": "Used",
    "id": 3276,
    "master_id": 3074
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 64 Liquid 8 GB",
    "name": "RX Vega 64 Liquid 8 GB",
    "condition": "Used With Box",
    "id": 3277,
    "master_id": 3074
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 64 Liquid 8 GB",
    "name": "RX Vega 64 Liquid 8 GB",
    "condition": "Brand New Sealed",
    "id": 3278,
    "master_id": 3074
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 64 8 GB",
    "name": "RX Vega 64 8 GB",
    "condition": "Used",
    "id": 3279,
    "master_id": 3075
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 64 8 GB",
    "name": "RX Vega 64 8 GB",
    "condition": "Used With Box",
    "id": 3280,
    "master_id": 3075
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 64 8 GB",
    "name": "RX Vega 64 8 GB",
    "condition": "Brand New Sealed",
    "id": 3281,
    "master_id": 3075
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 56 8 GB",
    "name": "RX Vega 56 8 GB",
    "condition": "Used",
    "id": 3282,
    "master_id": 3076
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 56 8 GB",
    "name": "RX Vega 56 8 GB",
    "condition": "Used With Box",
    "id": 3283,
    "master_id": 3076
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Vega 56 8 GB",
    "name": "RX Vega 56 8 GB",
    "condition": "Brand New Sealed",
    "id": 3284,
    "master_id": 3076
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Radeon VII 16 GB",
    "name": "Radeon VII 16 GB",
    "condition": "Used",
    "id": 3285,
    "master_id": 3077
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Radeon VII 16 GB",
    "name": "Radeon VII 16 GB",
    "condition": "Used With Box",
    "id": 3286,
    "master_id": 3077
  },
  {
    "type": "GPU",
    "manufacturer": "AMD",
    "series": "Vega",
    "model": "Radeon VII 16 GB",
    "name": "Radeon VII 16 GB",
    "condition": "Brand New Sealed",
    "id": 3287,
    "master_id": 3077
  }
];

let intelGPUArray = [
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A310 4 GB",
    name: "Arc A310 4 GB",
    condition: "Used",
    id: 3401,
    master_id: 3395,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A310 4 GB",
    name: "Arc A310 4 GB",
    condition: "Used With Box",
    id: 3402,
    master_id: 3395,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A310 4 GB",
    name: "Arc A310 4 GB",
    condition: "Brand New Sealed",
    id: 3403,
    master_id: 3395,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A380 6 GB",
    name: "Arc A380 6 GB",
    condition: "Used",
    id: 3404,
    master_id: 3396,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A380 6 GB",
    name: "Arc A380 6 GB",
    condition: "Used With Box",
    id: 3405,
    master_id: 3396,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A380 6 GB",
    name: "Arc A380 6 GB",
    condition: "Brand New Sealed",
    id: 3406,
    master_id: 3396,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A580 8 GB",
    name: "Arc A580 8 GB",
    condition: "Used",
    id: 3407,
    master_id: 3397,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A580 8 GB",
    name: "Arc A580 8 GB",
    condition: "Used With Box",
    id: 3408,
    master_id: 3397,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A580 8 GB",
    name: "Arc A580 8 GB",
    condition: "Brand New Sealed",
    id: 3409,
    master_id: 3397,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A750 8 GB",
    name: "Arc A750 8 GB",
    condition: "Used",
    id: 3410,
    master_id: 3398,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A750 8 GB",
    name: "Arc A750 8 GB",
    condition: "Used With Box",
    id: 3411,
    master_id: 3398,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A750 8 GB",
    name: "Arc A750 8 GB",
    condition: "Brand New Sealed",
    id: 3412,
    master_id: 3398,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A770 8 GB",
    name: "Arc A770 8 GB",
    condition: "Used",
    id: 3413,
    master_id: 3399,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A770 8 GB",
    name: "Arc A770 8 GB",
    condition: "Used With Box",
    id: 3414,
    master_id: 3399,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A770 8 GB",
    name: "Arc A770 8 GB",
    condition: "Brand New Sealed",
    id: 3415,
    master_id: 3399,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A770 16 GB",
    name: "Arc A770 16 GB",
    condition: "Used",
    id: 3416,
    master_id: 3400,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A770 16 GB",
    name: "Arc A770 16 GB",
    condition: "Used With Box",
    id: 3417,
    master_id: 3400,
  },
  {
    type: "GPU",
    manufacturer: "Intel",
    series: "Alchemist (A) Series",
    model: "A770 16 GB",
    name: "Arc A770 16 GB",
    condition: "Brand New Sealed",
    id: 3418,
    master_id: 3400,
  },
];

let ramArray = [
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 525,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 524,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 523,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 522,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 521,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 520,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 519,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 518,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 517,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 516,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 515,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 514,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 513,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 512,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 511,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 510,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 509,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 508,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 507,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 506,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 505,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 504,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 503,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 502,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 501,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 500,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 499,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 498,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 497,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 496,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Used",
    id: 495,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Used",
    id: 494,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Used",
    id: 493,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Used",
    id: 492,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Used",
    id: 491,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Used",
    id: 490,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Used",
    id: 489,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Used",
    id: 488,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Used",
    id: 487,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Used",
    id: 486,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Used",
    id: 485,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Used",
    id: 484,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Used",
    id: 483,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Used",
    id: 482,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR3",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Used",
    id: 481,
    "master id": 43,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 578,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 577,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 576,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 575,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 574,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 573,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 572,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 571,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 570,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 569,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 568,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 567,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 566,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 565,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 564,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 563,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 562,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 561,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 560,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 559,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 558,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 557,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 556,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 555,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 554,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 553,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 552,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 551,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 550,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 549,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Used",
    id: 548,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Used",
    id: 547,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Used",
    id: 546,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Used",
    id: 545,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Used",
    id: 544,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Used",
    id: 543,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Used",
    id: 542,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Used",
    id: 541,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Used",
    id: 540,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Used",
    id: 539,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Used",
    id: 538,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Used",
    id: 537,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Used",
    id: 536,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Used",
    id: 535,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR4",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Used",
    id: 534,
    "master id": 533,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 624,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 623,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Brand New Sealed",
    id: 622,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 621,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 620,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Brand New Sealed",
    id: 619,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 618,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 617,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Brand New Sealed",
    id: 616,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 615,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 614,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Brand New Sealed",
    id: 613,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 612,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 611,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Brand New Sealed",
    id: 610,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 609,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 608,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Used With Box",
    id: 607,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 606,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 605,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Used With Box",
    id: 604,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 603,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 602,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Used With Box",
    id: 601,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 600,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 599,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Used With Box",
    id: 598,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 597,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 596,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Used With Box",
    id: 595,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "128 GB",
    condition: "Used",
    id: 594,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "128 GB",
    condition: "Used",
    id: 593,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "128 GB",
    condition: "Used",
    id: 592,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "64 GB",
    condition: "Used",
    id: 591,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "64 GB",
    condition: "Used",
    id: 590,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "64 GB",
    condition: "Used",
    id: 589,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "32 GB",
    condition: "Used",
    id: 588,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "32 GB",
    condition: "Used",
    id: 587,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "32 GB",
    condition: "Used",
    id: 586,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "16 GB",
    condition: "Used",
    id: 585,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "16 GB",
    condition: "Used",
    id: 584,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "16 GB",
    condition: "Used",
    id: 583,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Server ECC",
    capacity: "8 GB",
    condition: "Used",
    id: 582,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Laptop SODIMM",
    capacity: "8 GB",
    condition: "Used",
    id: 581,
    "master id": 579,
  },
  {
    type: "RAM",
    generation: "DDR5",
    application: "Desktop DIMM",
    capacity: "8 GB",
    condition: "Used",
    id: 580,
    "master id": 579,
  },
];

let storageArray = [
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "Under 240 GB M.2 NVMe SSD",
    condition: "Used",
    capacity: "Under 240 GB",
    id: 3423,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "240 GB - 480 GB M.2 NVMe SSD",
    condition: "Used",
    capacity: "240 GB - 480 GB",
    id: 3424,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "480 GB - 750 GB M.2 NVMe SSD",
    condition: "Used",
    capacity: "480 GB - 750 GB",
    id: 3425,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "750 GB - 999 GB M.2 NVMe SSD",
    condition: "Used",
    capacity: "750 GB - 999 GB",
    id: 3426,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "1 TB M.2 NVMe SSD",
    condition: "Used",
    capacity: "1 TB",
    id: 3427,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "2 TB M.2 NVMe SSD",
    condition: "Used",
    capacity: "2 TB",
    id: 3428,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "4 TB M.2 NVMe SSD",
    condition: "Used",
    capacity: "4 TB",
    id: 3429,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "8 TB M.2 NVMe SSD",
    condition: "Used",
    capacity: "8 TB",
    id: 3430,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "Under 240 GB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "Under 240 GB",
    id: 3431,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "240 GB - 480 GB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "240 GB - 480 GB",
    id: 3432,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "480 GB - 750 GB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "480 GB - 750 GB",
    id: 3433,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "750 GB - 999 GB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "750 GB - 999 GB",
    id: 3434,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "1 TB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "1 TB",
    id: 3435,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "2 TB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "2 TB",
    id: 3436,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "4 TB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "4 TB",
    id: 3437,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "8 TB M.2 NVMe SSD",
    condition: "Used With Box",
    capacity: "8 TB",
    id: 3438,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "Under 240 GB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "Under 240 GB",
    id: 3439,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "240 GB - 480 GB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "240 GB - 480 GB",
    id: 3440,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "480 GB - 750 GB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "480 GB - 750 GB",
    id: 3441,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "750 GB - 999 GB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "750 GB - 999 GB",
    id: 3442,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "1 TB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "1 TB",
    id: 3443,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "2 TB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "2 TB",
    id: 3444,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "4 TB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "4 TB",
    id: 3445,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "M.2 NVMe SSD",
    name: "8 TB M.2 NVMe SSD",
    condition: "Brand New Sealed",
    capacity: "8 TB",
    id: 3446,
    master_id: 3420,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "Under 240 GB SATA SSD",
    condition: "Used",
    capacity: "Under 240 GB",
    id: 3447,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "240 GB - 480 GB SATA SSD",
    condition: "Used",
    capacity: "240 GB - 480 GB",
    id: 3448,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "480 GB - 750 GB SATA SSD",
    condition: "Used",
    capacity: "480 GB - 750 GB",
    id: 3449,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "750 GB - 999 GB SATA SSD",
    condition: "Used",
    capacity: "750 GB - 999 GB",
    id: 3450,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1 TB SATA SSD",
    condition: "Used",
    capacity: "1 TB",
    id: 3451,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1.2 TB SATA SSD",
    condition: "Used",
    capacity: "1.2 TB",
    id: 3452,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1.6 TB SATA SSD",
    condition: "Used",
    capacity: "1.6 TB",
    id: 3453,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "2 TB SATA SSD",
    condition: "Used",
    capacity: "2 TB",
    id: 3454,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "4 TB SATA SSD",
    condition: "Used",
    capacity: "4 TB",
    id: 3455,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "8 TB SATA SSD",
    condition: "Used",
    capacity: "8 TB",
    id: 3456,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "Under 240 GB SATA SSD",
    condition: "Used With Box",
    capacity: "Under 240 GB",
    id: 3457,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "240 GB - 480 GB SATA SSD",
    condition: "Used With Box",
    capacity: "240 GB - 480 GB",
    id: 3458,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "480 GB - 750 GB SATA SSD",
    condition: "Used With Box",
    capacity: "480 GB - 750 GB",
    id: 3459,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "750 GB - 999 GB SATA SSD",
    condition: "Used With Box",
    capacity: "750 GB - 999 GB",
    id: 3460,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1 TB SATA SSD",
    condition: "Used With Box",
    capacity: "1 TB",
    id: 3461,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1.2 TB SATA SSD",
    condition: "Used With Box",
    capacity: "1.2 TB",
    id: 3462,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1.6 TB SATA SSD",
    condition: "Used With Box",
    capacity: "1.6 TB",
    id: 3463,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "2 TB SATA SSD",
    condition: "Used With Box",
    capacity: "2 TB",
    id: 3464,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "4 TB SATA SSD",
    condition: "Used With Box",
    capacity: "4 TB",
    id: 3465,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "8 TB SATA SSD",
    condition: "Used With Box",
    capacity: "8 TB",
    id: 3466,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "Under 240 GB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "Under 240 GB",
    id: 3467,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "240 GB - 480 GB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "240 GB - 480 GB",
    id: 3468,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "480 GB - 750 GB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "480 GB - 750 GB",
    id: 3469,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "750 GB - 999 GB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "750 GB - 999 GB",
    id: 3470,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1 TB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "1 TB",
    id: 3471,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1.2 TB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "1.2 TB",
    id: 3472,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "1.6 TB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "1.6 TB",
    id: 3473,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "2 TB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "2 TB",
    id: 3474,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "4 TB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "4 TB",
    id: 3475,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "SATA SSD",
    name: "8 TB SATA SSD",
    condition: "Brand New Sealed",
    capacity: "8 TB",
    id: 3476,
    master_id: 3421,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "Under 240 GB HDD (Hard Drive)",
    condition: "Used",
    capacity: "Under 240 GB",
    id: 3477,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "240 GB - 480 GB HDD (Hard Drive)",
    condition: "Used",
    capacity: "240 GB - 480 GB",
    id: 3478,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "480 GB - 750 GB HDD (Hard Drive)",
    condition: "Used",
    capacity: "480 GB - 750 GB",
    id: 3479,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "750 GB - 999 GB HDD (Hard Drive)",
    condition: "Used",
    capacity: "750 GB - 999 GB",
    id: 3480,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "1TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "1TB",
    id: 3481,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "2 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "2 TB",
    id: 3482,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "3 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "3 TB",
    id: 3483,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "4 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "4 TB",
    id: 3484,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "8 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "8 TB",
    id: 3485,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "10 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "10 TB",
    id: 3486,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "12 TB  HDD (Hard Drive)",
    condition: "Used",
    capacity: "12 TB",
    id: 3487,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "14 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "14 TB",
    id: 3488,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "16 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "16 TB",
    id: 3489,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "18 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "18 TB",
    id: 3490,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "20 TB HDD (Hard Drive)",
    condition: "Used",
    capacity: "20 TB",
    id: 3491,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "Under 240 GB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "Under 240 GB",
    id: 3492,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "240 GB - 480 GB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "240 GB - 480 GB",
    id: 3493,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "480 GB - 750 GB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "480 GB - 750 GB",
    id: 3494,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "750 GB - 999 GB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "750 GB - 999 GB",
    id: 3495,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "1TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "1TB",
    id: 3496,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "2 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "2 TB",
    id: 3497,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "3 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "3 TB",
    id: 3498,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "4 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "4 TB",
    id: 3499,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "8 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "8 TB",
    id: 3500,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "10 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "10 TB",
    id: 3501,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "12 TB  HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "12 TB",
    id: 3502,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "",
    id: 3503,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "16 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "16 TB",
    id: 3504,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "18 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "18 TB",
    id: 3505,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "20 TB HDD (Hard Drive)",
    condition: "Used With Box",
    capacity: "20 TB",
    id: 3506,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "Under 240 GB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "Under 240 GB",
    id: 3507,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "240 GB - 480 GB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "240 GB - 480 GB",
    id: 3508,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "480 GB - 750 GB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "480 GB - 750 GB",
    id: 3509,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "750 GB - 999 GB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "750 GB - 999 GB",
    id: 3510,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "1TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "1TB",
    id: 3511,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "2 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "2 TB",
    id: 3512,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "3 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "3 TB",
    id: 3513,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "4 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "4 TB",
    id: 3514,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "8 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "8 TB",
    id: 3515,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "10 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "10 TB",
    id: 3516,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "12 TB  HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "12 TB",
    id: 3517,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "14 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "14 TB",
    id: 3518,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "16 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "16 TB",
    id: 3519,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "18 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "18 TB",
    id: 3520,
    master_id: 3422,
  },
  {
    type: "STORAGE",
    "storage type": "HDD (Hard Drive)",
    name: "20 TB HDD (Hard Drive)",
    condition: "Brand New Sealed",
    capacity: "20 TB",
    id: 3521,
    master_id: 3422,
  },
];

let motherboardArray = [
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4059,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4060,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4061,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4056,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4057,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4058,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4053,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4054,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4055,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4050,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4051,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X99",
    "name": "X99 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4052,
    "master_id": 3994,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4071,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4072,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4073,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4068,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4069,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4070,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4065,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4066,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4067,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4062,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4063,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "X299",
    "name": "X299 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4064,
    "master_id": 3995,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4083,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4084,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4085,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4080,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4081,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4082,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4077,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4078,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4079,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4074,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4075,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H110",
    "name": "H110 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4076,
    "master_id": 3996,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4095,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4096,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4097,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4092,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4093,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4094,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4089,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4090,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4091,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4086,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4087,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B150",
    "name": "B150 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4088,
    "master_id": 3997,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4107,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4108,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4109,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4104,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4105,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4106,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4101,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4102,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4103,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4098,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4099,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q150",
    "name": "Q150 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4100,
    "master_id": 3998,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4119,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4120,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4121,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4116,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4117,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4118,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4113,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4114,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4115,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4110,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4111,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H170",
    "name": "H170 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4112,
    "master_id": 3999,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4131,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4132,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4133,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4128,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4129,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4130,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4125,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4126,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4127,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4122,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4123,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q170",
    "name": "Q170 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4124,
    "master_id": 4000,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4143,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4144,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4145,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4140,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4141,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4142,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4137,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4138,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4139,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4134,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4135,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z170",
    "name": "Z170 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4136,
    "master_id": 4001,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4155,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4156,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4157,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4152,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4153,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4154,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4149,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4150,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4151,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4146,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4147,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B250",
    "name": "B250 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4148,
    "master_id": 4002,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4167,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4168,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4169,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4164,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4165,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4166,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4161,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4162,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4163,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4158,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4159,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q250",
    "name": "Q250 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4160,
    "master_id": 4003,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4179,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4180,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4181,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4176,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4177,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4178,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4173,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4174,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4175,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4170,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4171,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H270",
    "name": "H270 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4172,
    "master_id": 4004,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4191,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4192,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4193,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4188,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4189,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4190,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4185,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4186,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4187,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4182,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4183,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q270",
    "name": "Q270 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4184,
    "master_id": 4005,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4203,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4204,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4205,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4200,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4201,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4202,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4197,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4198,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4199,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4194,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4195,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z270",
    "name": "Z270 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4196,
    "master_id": 4006,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4215,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4216,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4217,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4212,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4213,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4214,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4209,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4210,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4211,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4206,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4207,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z370",
    "name": "Z370 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4208,
    "master_id": 4007,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4227,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4228,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4229,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4224,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4225,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4226,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4221,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4222,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4223,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4218,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4219,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H310",
    "name": "H310 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4220,
    "master_id": 4008,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4239,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4240,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4241,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4236,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4237,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4238,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4233,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4234,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4235,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4230,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4231,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B360",
    "name": "B360 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4232,
    "master_id": 4009,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4251,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4252,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4253,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4248,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4249,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4250,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4245,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4246,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4247,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4242,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4243,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B365",
    "name": "B365 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4244,
    "master_id": 4010,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4263,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4264,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4265,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4260,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4261,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4262,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4257,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4258,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4259,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4254,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4255,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H370",
    "name": "H370 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4256,
    "master_id": 4011,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4275,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4276,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4277,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4272,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4273,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4274,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4269,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4270,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4271,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4266,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4267,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q370",
    "name": "Q370 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4268,
    "master_id": 4012,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4287,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4288,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4289,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4284,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4285,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4286,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4281,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4282,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4283,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4278,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4279,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z390",
    "name": "Z390 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4280,
    "master_id": 4013,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4299,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4300,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4301,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4296,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4297,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4298,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4293,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4294,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4295,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4290,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4291,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H410",
    "name": "H410 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4292,
    "master_id": 4014,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4311,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4312,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4313,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4308,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4309,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4310,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4305,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4306,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4307,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4302,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4303,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B460",
    "name": "B460 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4304,
    "master_id": 4015,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4323,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4324,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4325,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4320,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4321,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4322,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4317,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4318,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4319,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4314,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4315,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H470",
    "name": "H470 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4316,
    "master_id": 4016,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4335,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4336,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4337,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4332,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4333,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4334,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4329,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4330,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4331,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4326,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4327,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q470",
    "name": "Q470 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4328,
    "master_id": 4017,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4347,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4348,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4349,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4344,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4345,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4346,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4341,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4342,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4343,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4338,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4339,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z490",
    "name": "Z490 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4340,
    "master_id": 4018,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4359,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4360,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4361,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4356,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4357,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4358,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4353,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4354,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4355,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4350,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4351,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H510",
    "name": "H510 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4352,
    "master_id": 4019,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4371,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4372,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4373,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4368,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4369,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4370,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4365,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4366,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4367,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4362,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4363,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B560",
    "name": "B560 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4364,
    "master_id": 4020,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4383,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4384,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4385,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4380,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4381,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4382,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4377,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4378,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4379,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4374,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4375,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H570",
    "name": "H570 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4376,
    "master_id": 4021,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4395,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4396,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4397,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4392,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4393,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4394,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4389,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4390,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4391,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4386,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4387,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z590",
    "name": "Z590 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4388,
    "master_id": 4022,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4407,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4408,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4409,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4404,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4405,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4406,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4401,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4402,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4403,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4398,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4399,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z690",
    "name": "Z690 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4400,
    "master_id": 4023,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4419,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4420,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4421,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4416,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4417,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4418,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4413,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4414,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4415,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4410,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4411,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Q670",
    "name": "Q670 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4412,
    "master_id": 4024,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4431,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4432,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4433,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4428,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4429,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4430,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4425,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4426,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4427,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4422,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4423,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H670",
    "name": "H670 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4424,
    "master_id": 4025,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4443,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4444,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4445,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4440,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4441,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4442,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4437,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4438,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4439,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4434,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4435,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B660",
    "name": "B660 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4436,
    "master_id": 4026,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4455,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4456,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4457,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4452,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4453,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4454,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4449,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4450,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4451,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4446,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4447,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H610",
    "name": "H610 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4448,
    "master_id": 4027,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4467,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4468,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4469,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4464,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4465,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4466,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4461,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4462,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4463,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4458,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4459,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "Z790",
    "name": "Z790 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4460,
    "master_id": 4028,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4479,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4480,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4481,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4476,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4477,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4478,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4473,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4474,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4475,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4470,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4471,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "H770",
    "name": "H770 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4472,
    "master_id": 4029,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4491,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4492,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4493,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4488,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4489,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4490,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4485,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4486,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4487,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4482,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4483,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "Intel",
    "chipset": "B760",
    "name": "B760 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4484,
    "master_id": 4030,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4503,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4504,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4505,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4500,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4501,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4502,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4497,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4498,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4499,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4494,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4495,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A320",
    "name": "A320 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4496,
    "master_id": 4031,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4515,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4516,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4517,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4512,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4513,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4514,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4509,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4510,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4511,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4506,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4507,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B350",
    "name": "B350 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4508,
    "master_id": 4032,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4527,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4528,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4529,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4524,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4525,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4526,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4521,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4522,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4523,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4518,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4519,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X370",
    "name": "X370 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4520,
    "master_id": 4033,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4539,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4540,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4541,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4536,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4537,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4538,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4533,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4534,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4535,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4530,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4531,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B450",
    "name": "B450 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4532,
    "master_id": 4034,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4551,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4552,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4553,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4548,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4549,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4550,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4545,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4546,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4547,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4542,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4543,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X470",
    "name": "X470 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4544,
    "master_id": 4035,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4563,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4564,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4565,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4560,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4561,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4562,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4557,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4558,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4559,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4554,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4555,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A520",
    "name": "A520 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4556,
    "master_id": 4036,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4575,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4576,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4577,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4572,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4573,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4574,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4569,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4570,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4571,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4566,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4567,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B550",
    "name": "B550 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4568,
    "master_id": 4037,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4587,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4588,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4589,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4584,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4585,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4586,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4581,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4582,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4583,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4578,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4579,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X570",
    "name": "X570 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4580,
    "master_id": 4038,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4599,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4600,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4601,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4596,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4597,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4598,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4593,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4594,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4595,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4590,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4591,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "A620",
    "name": "A620 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4592,
    "master_id": 4039,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4611,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4612,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4613,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4608,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4609,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4610,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4605,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4606,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4607,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4602,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4603,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650",
    "name": "B650 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4604,
    "master_id": 4040,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4623,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4624,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4625,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4620,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4621,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4622,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4617,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4618,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4619,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4614,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4615,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "B650E",
    "name": "B650E Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4616,
    "master_id": 4041,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4635,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4636,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4637,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4632,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4633,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4634,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4629,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4630,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4631,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4626,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4627,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670",
    "name": "X670 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4628,
    "master_id": 4042,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4647,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4648,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4649,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4644,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4645,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4646,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4641,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4642,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4643,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4638,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4639,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X670E",
    "name": "X670E Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4640,
    "master_id": 4043,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4659,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4660,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4661,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4656,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4657,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4658,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4653,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4654,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4655,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4650,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4651,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "X399",
    "name": "X399 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4652,
    "master_id": 4044,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4671,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4672,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4673,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4668,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4669,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4670,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4665,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4666,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4667,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4662,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4663,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX40",
    "name": "TRX40 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4664,
    "master_id": 4045,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4695,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4696,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4697,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4692,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4693,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4694,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4689,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4690,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4691,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4686,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4687,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX80",
    "name": "WRX80 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4688,
    "master_id": 4047,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4707,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4708,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4709,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4704,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4705,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4706,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4701,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4702,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4703,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4698,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4699,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "TRX50",
    "name": "TRX50 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4700,
    "master_id": 4048,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "ITX",
    "condition": "Used",
    "id": 4719,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "ITX",
    "condition": "Used With Box",
    "id": 4720,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "ITX",
    "condition": "Brand New Sealed",
    "id": 4721,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "mATX",
    "condition": "Used",
    "id": 4716,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "mATX",
    "condition": "Used With Box",
    "id": 4717,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "mATX",
    "condition": "Brand New Sealed",
    "id": 4718,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "ATX",
    "condition": "Used",
    "id": 4713,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "ATX",
    "condition": "Used With Box",
    "id": 4714,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "ATX",
    "condition": "Brand New Sealed",
    "id": 4715,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "EATX",
    "condition": "Used",
    "id": 4710,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "EATX",
    "condition": "Used With Box",
    "id": 4711,
    "master_id": 4049,
  },
  {
    "type": "Motherboard",
    "platform": "AMD",
    "chipset": "WRX90",
    "name": "WRX90 Motherboard",
    "form factor": "EATX",
    "condition": "Brand New Sealed",
    "id": 4712,
    "master_id": 4049,
  },
];

export const ramKits = {
  "8 GB": ["1x8 GB Stick", "2x4 GB Kit"],
  "16 GB": ["1x16 GB Stick", "2x8 GB Kit", "4x4 GB Kit"],
  "32 GB": ["1x32 GB Stick", "2x16 GB Kit", "4x8 GB Kit"],
  "64 GB": ["1x64 GB Stick", "2x32 GB Kit", "4x16 GB Kit", "8x8 GB Kit"],
  "128 GB": ["1x128 GB Stick", "2x64 GB Kit", "4x32 GB Kit", "8x16 GB Kit"],
};

function generateRamArray() {
  //should make 432 refs?
  let kitRamArray = [];

  for (let r = 0; r < ramArray.length; r++) {
    let capacity = ramArray[r]["capacity"]; //Maybe we make non-case sensitive?

    for (let i = 0; i < ramKits[capacity].length; i++) {
      let newRamItem = Object.assign({}, ramArray[r]);
      newRamItem["kit"] = ramKits[capacity][i];
      newRamItem[
        "name"
      ] = `${newRamItem["kit"]} Of ${newRamItem["generation"]} ${newRamItem["application"]} Ram`;
      kitRamArray.push(newRamItem);
    }
  }

  return kitRamArray;
}

function generateMotherboardArray() {
  let motherboardFormFactorArray = [];

  for (let i = 0; i < motherboardArray.length; i++) {
    let newMotherboardItem = Object.assign({}, motherboardArray[i]);
    newMotherboardItem[
      "name"
    ] = `${newMotherboardItem["form factor"]} ${newMotherboardItem["platform"]} ${newMotherboardItem["chipset"]} Motherboard`;
    motherboardFormFactorArray.push(newMotherboardItem);
  }
  return motherboardFormFactorArray;
}

let newMotherboardArray = generateMotherboardArray();
let newRamArray = generateRamArray();

export const itemArray = amdCpuArray.concat(
  intelCPUArray,
  nvidiaGPUArray,
  amdGPUArray,
  intelGPUArray,
  newRamArray,
  storageArray,
  newMotherboardArray
);
